import React from 'react'
import { Row, Col } from "reactstrap"
import { FiHeart } from "react-icons/fi"
import { BsArrowRight} from "react-icons/bs"

import comments from "../../../../assests/iCON/iCON/noun-comment-3266599.png"
import share from "../../../../assests/iCON/iCON/noun-share-5060531.png"
import flag from "../../../../assests/iCON/iCON/noun-flag-1017687.png"
import save from "../../../../assests/iCON/iCON/noun-bookmark-3025188.png"

const TodayTab = () => {
    return (
        <>
            <div className="row" >
                <div className="col-xl-1 col-lg-1 col-md-1 col-sm-1"></div>
                <div className="col-xl-10 col-lg-10 col-md-10 col-sm-10 mb-4 " >

                    <div className=" w-100 p-0 overflow-hidden mr-1  shadow-lg p-3 mb-5 quizs" style={{borderRadius:"20px"}}>
                        <div className=" position-relative d-block">
                            <div className="mb-4 ">Lorem Ipsum Is Simply Dummy Text Of The Printing And Typesetting Industry. Lorem Ipsum Has Been The Industry's Standard Dummy Text Ever Since The 1500S, When An Unknown Printer Took A Galley Of Type And Scrambled It To Make A Type Specimen Book.</div>
                        </div>

                        <div className='bg-darks'>
                            <div className='mt-2 pt-2 pl-2'>
                                <FiHeart fontSize="2em" className="mr-3" />
                                <img src={comments} width="25px" className="mr-3" />
                                <img src={share} width="25px" />
                                <BsArrowRight fontSize="1.50em" className='float-right mr-2'color='#B1F626' />
                                <img src={save} width="20px" className='float-right mr-3 ml-3' />
                                <img src={flag} width="20px" className='float-right' />
                                
                            </div>
                            <div className="card-body">
                                <button className="revealAnswere mt-4 mb-4" type="button">Reveal Answer</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default TodayTab