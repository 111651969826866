import React, { Component, Fragment } from 'react';
// import Pagenav from '../../components/Pagenav';
// import Header from '../../components/Header';
// import Upperheader from '../../components/Upperheader';
// import Footer from '../../components/Footer';
import Pagination from '../../components/Pagination';
import { BsPlayCircleFill, BsFillCalendar2WeekFill, BsSaveFill, BsFillFileEarmarkPersonFill, BsGlobe } from "react-icons/bs"
import { RiFlashlightLine, RiFilterFill } from "react-icons/ri"
import { HiDocumentText } from "react-icons/hi"
import { RiStarSLine } from "react-icons/ri"
import { AiFillHeart } from "react-icons/ai"
import { ImBlocked } from "react-icons/im"
import { Link } from 'react-router-dom';





const RecentList = [
  { 
    imageUrl: 'blog.png',
    title: 'You work your way to creative thinking',
    meta: 'Lifestyle',
    date: '24 May 2020',
  },
  {
    imageUrl: 'blog.png',
    title: 'If you do it right, it will last ever forever',
    meta: 'Food',
    date: '24 May 2020',
  },
  {
    imageUrl: 'blog.png',
    title: 'It’s no secret that the digital industry is booming',
    meta: 'Lifestyle',
    date: '24 May 2020',
  },
  {
    imageUrl: 'blog.png',
    title: 'Ways your mother lied to you about food stuffs',
    meta: 'Lifestyle',
    date: '24 May 2020',
  },
  {
    imageUrl: 'blog.png',
    title: 'Good design is obvious and transparent',
    meta: 'Tech',
    date: '24 May 2020',
  },
];

const blogList = [
  {
    imageUrl: 'blog.png',
    title: 'Aenean  Dieting Strategies That Almost Always Backfire',
    meta: 'Lifestyle',
    des: 'Human coronaviruses are common and are typically associated with mild illnesses, similar to the common cold. We are digital agency.',
  },

];

class BlogSidebar extends Component {
  render() {
    return (
      <Fragment>
        {/* <Upperheader />
        <Header />
        <Pagenav title="Blog Sidebar" subtitle="Blog" /> */}
    


        <div className="blog-page pt-lg--7 pb-lg--7 pt-5 pb-5 bg-white text-grey-900">
          <div className="container">
            <div className="row">
         

              <div className="col-lg-12">
                <div className="row">
                  {blogList.map((value, index) => (
                    // Start Single Demo

                    <div
                      key={index}
                      className="col-lg-12 col-md-12 col-sm-12 mb-4"
                    >
                      <article className="post-article p-0 border-0 shadow-xss rounded-lg overflow-hidden">
                        <div className="row">
                          <div className="col-6 col-xs-12">
                            <a href="/blog-single">
                              <img
                                src={`assets/images/${value.imageUrl}`}
                                alt="blog"
                                className="w-100"
                              />
                            </a>
                          </div>
                          <div className="col-6 col-xs-12 pl-md--0">
                            <div className="post-content p-4 pb-0">
                           
                              <h3 className=" float-left">
                               Jack Robin
                              </h3>
                              <div className="clearfix"></div>
                              <h2 className="post-title mt-0 mb-2 pr-3">
                                <a
                                  href="blog-single.html"
                                  className="lh-28 font-xss mont-font text-grey-800 fw-700"
                                >
                                  {value.title}
                                </a>
                              </h2>
                              <p className="font-xssss fw-400 text-grey-500 lh-24 mt-0 mb-0 pr-3">
                                {value.des}
                              </p>

                              <div className='mt-5'>
                                <button type='button' className='btn btn-info mr-5'>Create Account</button>
                                <button type='button' className='btn btn-light'>Demo</button>
                              </div>

                            </div>
                          </div>
                        </div>
                      </article>
                    </div>
                    // End Single Demo
                  ))}
                </div>
              </div>

       
            </div>
          </div>
        </div>

        {/* <Footer /> */}
      </Fragment>
    );
  }
}

export default BlogSidebar;
