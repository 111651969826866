import React,{useState} from 'react'
import like from "../../assests/iCON/iCON/noun-like-1122328.png"
import comments from "../../assests/iCON/iCON/noun-comment-3266599.png"
import share from "../../assests/iCON/iCON/noun-share-5060531.png"
import flag from "../../assests/iCON/iCON/noun-flag-1017687.png"
import save from "../../assests/iCON/iCON/noun-bookmark-3025188.png"
import user from "../../assests/iCON/iCON/noun-friend-2531936.png";
import bigSize from "../../assests/iCON/iCON/noun-full-screen-1701604.png";
import medical from "../../../assets/images/demo/medical.png"
import uplaod from "../../assests/iCON/iCON/noun-photo-video-695389.png"
import { Row, Col } from "reactstrap"
import { FiSend, FiHeart } from "react-icons/fi"
import { Link } from 'react-router-dom';
import dummy from "../../assests/dummy.png"
import video from "../../assests/video.mp4"
// import {SlSizeFullscreen} from "react-icons/sl"

const courseList = [
    {
      imageUrl: 'course.png',
      title: 'Complete Python Bootcamp From Zero to Hero in Python',
      price: '2400',
      tag: 'Python',
      lesson: '32 ',
      status: 'alert-warning text-warning',
    },
    {
      imageUrl: 'course.png',
      title: 'Complete Python Bootcamp From Zero to Hero in Python ',
      price: '40',
      tag: 'Desinger',
      lesson: '24 ',
      status: 'alert-danger text-danger',
    }
  
  ];
const VideoMaterials = () => {
    const [viewComments, setViewComments] = useState(false)

  return (
    <>
    
    <div className='mt-5'>
    <select className='top-rated-study'>
            <option value="SmartOrder">Top rated First</option>
            <option value="Bestrated">Best rated first</option>
            <option value="Newest">Newest first</option>
            <option value="Trending">Trending Now </option>
          </select>
    {courseList.map((value, index) => (
          // Strat Single Demo
          <Row >
            <Col md="11">
              <div className='social'>
                <div className=" w-100 border-0 rounded-lg overflow-hidden mr-1 text-grey-900 shadow-lg  mb-5 rounded">
                  <div className="card-image w-100 mb-3">
                    <Link to="/single-user-profile"><span><img src={dummy} alt="img" className='mt-2 ml-1' style={{ width: "50px", height: "50px" }} /> </span></Link>
                    <span>Amelia Jeannet <p className='user-des'>3Rd Y MS, University Of Geneva</p></span>
                    <img src={user} className="float-right mr-2 addUser" width="40px" />
                    <Link
                      to="#"
                      className="video-bttn position-relative d-block mt-2">
                      <video controls className="w-100" >
                        <source src={video} type="video/mp4" ></source>
                      </video>
                    </Link>
                  </div>
                  <div>
                    <div className='mt-1 pt-2 pl-2'>
                      <img src={like} width="25px" className="mr-3 ml-2" />
                      <img src={comments} width="25px" className="mr-3" />
                      <img src={share} width="25px" />
                      <img src={bigSize} className='float-right mr-3 '  width="25px"/>
                      <img src={save} width="23px" className='float-right mr-3 ml-3' />
                      <img src={flag} width="25px" className='float-right'
                    //    onClick={(event) => { createReport(event) }} 
                       />
                    </div><p className='des-time'>01 Hour Ago</p>
                    <p className='caption'><b>Amelia Jeannet Lorem</b> Ipsum Is Simply Dummy Text Of The Printing And Typesetting Industry.Lorem Ipsum. More</p>
                    <div className="card-body p-3">
                      <h4 className="font-xss mt-2 ml-0 lh-28 mt-0 pb-3 border-bottom text-white"
                        //  onClick={() => setViewComments(!viewComments)}
                        // onClick={(event) => { CommentModal(event) }}
                      >
                        View 08 Comments
                      </h4>
                      <span style={{ color: "#BCB2B2" }}>Add Comments</span>
                      <div className='float-right' style={{ color: "#E84545" }}> Send</div>
                    </div>
                    {viewComments &&
                      <div className="row">
                        <div className="col-2 text-left pl-4 pt-3 m-2">
                          <figure className="avatar float-left mb-0">
                            <img
                              src="https://statinfer.com/wp-content/uploads/dummy-user.png"
                              alt="banner"
                              className="float-right shadow-none w40 mr-2 rounded-circle"
                            />
                          </figure>
                        </div>
                        <div className="col-10 pt-2 comments">
                          <div className="content">
                            <b className="author-name font-xssss fw-600 mb-0 ">
                              Goria Coast
                            </b>
                            <p className="comment-text lh-24 fw-500 font-xssss text-grey-500 ">
                              Enjoyed this a lot and well done.
                              <FiHeart className="float-right" style={{ marginRight: "-23px" }} />
                            </p>
                          </div>
                        </div>
                      </div>
                    }
                  </div>
                </div>
              </div>
              <div
                className="col-xl-3 col-lg-6 col-md-6 col-sm-6 mb-4"
                key={index}
              ></div>
            </Col>
          </Row>
          // End Single Demo
        ))}
     </div>
     </>
  )
}

export default VideoMaterials
