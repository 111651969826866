import React from 'react'
import { Row, Col, FormGroup, Input, Label, Select } from "reactstrap"
import Header from '../../../components/Header'
import { AdminMenuBar } from '../SideBar'
import country from "../../../view/assests/iCON/noun-country-2450449.png"
const ContentCreater = () => {
    return (
        <>
            <Header />
            <div className='change-password pt-5' style={{ backgroundColor: "#181818", color: "white", height: "800px" }}>
                <Row>
                    <Col md="1"></Col>
                    <Col md="3">
                        <AdminMenuBar />
                    </Col>
                    <Col md="7">
                        <Row >
                            <Col md="4">
                                <FormGroup>
                                    <Label>Filter </Label>
                                    <select type='select' className="admin-setting-field">
                                         <option>Country</option>
                                    </select>
                                </FormGroup>
                            </Col>
                            <Col md="4">
                                <FormGroup>
                                    <Label> </Label>
                                    <select type='select' className="admin-setting-field">
                                        <option>University</option>
                                    </select>
                                </FormGroup>
                            </Col>
                            <Col md="4">
                                <FormGroup>
                                    <Label> </Label>
                                    <select type='select' className="admin-setting-field">
                                        <option>Year</option>
                                    </select>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row >
                            <Col md="4">
                                <FormGroup>
                                    <select type='select' className="admin-setting-field">
                                        <option>Branch</option>
                                    </select>
                                </FormGroup>
                            </Col>
                            <Col md="4">
                                <FormGroup>
                                    <select type='select' className="admin-setting-field">
                                        <option>Chapters</option>
                                    </select>
                                </FormGroup>
                            </Col>
                            <Col md="4">
                                <FormGroup>
                                    <select type='select' className="admin-setting-field">
                                        <option>Sub Chapters</option>
                                    </select>
                                </FormGroup>
                            </Col>

                        </Row>
                        <Row>
                            <table className='content-creator-table'>
                                <tr>
                                    <th>ID</th>
                                    <th>Content Type</th>
                                    <th>Link to</th>
                                    <th>Author</th>
                                    <th>Flag/Like ratio</th>
                                    <th>Total Flags</th>
                                    <th>Date Last Flags</th>
                                    <th>Total views</th>
                                    <th>Total likes</th>
                                    <th>like to viewed Ratio</th>
                                    <th>List of flags reason</th>
                                    <th>Actions</th>
                                    
                                </tr>
                                <tr>
                                    <td>Alfreds Futterkiste</td>
                                    <td>Maria Anders</td>
                                    <td>Germany</td>
                                    <td>Alfreds Futterkiste</td>
                                    <td>Maria Anders</td>
                                    <td>Germany</td>
                                    <td>Alfreds Futterkiste</td>
                                    <td>Maria Anders</td>
                                    <td>Germany</td>
                                    <td>Alfreds Futterkiste</td>
                                    <td>Maria Anders</td>
                                    <td>Germany</td>
                                </tr>
                                <tr>
                                    <td>Alfreds Futterkiste</td>
                                    <td>Maria Anders</td>
                                    <td>Germany</td>
                                    <td>Alfreds Futterkiste</td>
                                    <td>Maria Anders</td>
                                    <td>Germany</td>
                                    <td>Alfreds Futterkiste</td>
                                    <td>Maria Anders</td>
                                    <td>Germany</td>
                                    <td>Alfreds Futterkiste</td>
                                    <td>Maria Anders</td>
                                    <td>Germany</td>
                                </tr>

                            </table>
                        </Row>
                    </Col>


                </Row>

            </div>
        </>
    )
}

export default ContentCreater