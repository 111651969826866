import React, { useState } from 'react'
import { QuizFilter } from '../quiz/QuizFilters'
import { Row, Col } from "reactstrap"
import { Link } from 'react-router-dom'
import videoIcon from "../assests/iCON/noun-video.png";
import FlashcardIcon from "../assests/iCON/noun-flash-5136372.png";
import documentIcon from "../assests/iCON/noun-document-1212456.png";
import createIcon from "../assests/iCON/noun-create-1409661.png";
import VideoMaterials from './videos/VideoMaterials';
import Tabs, { Tab } from 'react-best-tabs';
import 'react-best-tabs/dist/index.css';
import FlashCardsMaterial from './videos/flashcard/FlashCardsMaterial';
import DocumentsMaterial from './documents/DocumentsMaterial';
import Header from '../../components/Header'
import { FiYoutube } from "react-icons/fi"
import { HiOutlineDocumentText } from "react-icons/hi"
import { TiFlashOutline } from "react-icons/ti"
import { useHistory } from "react-router-dom";

const Study = () => {



  const [video, setvideo] = useState(true)
  const videoBtn = async () => {

    setvideo(!video)
  }

  const [flashCards, setFlashCards] = useState(false)
  const flashcardBtn = async () => {
    debugger
    setFlashCards(!flashCards)
  }

  let history = useHistory();
  const chnageRoute=()=>{
    debugger
    if(allTab==null){
      history.push("/create-video")
    }
    if(allTab==1){
      history.push("/create-video")
    }
    if(allTab==2){
      history.push("/create-flashcard")
    }
    if(allTab==3){
      history.push("/create-document")
    }
  }
  const [allTab,setAllTabs]=useState()
  const chnageTabs=(e,tab)=>{
    setAllTabs(tab)
    debugger
  }




  return (
    <>
      <Header />
      <Row className="quiz">
   
        <QuizFilter />
        <div className="col-md-8 col-sm-12">
          <Tabs
           onClick={(event, tab) => chnageTabs(event,tab)}
            activeTab="1" className="mt-4" >
            <Tab title={<span style={{ fontWeight: "400" }}><FiYoutube className='mr-2' fontSize="1.8rem" />Videos</span>} className="mr-4">
              <VideoMaterials />
            </Tab>
            <Tab title={<span style={{ fontWeight: "400" }}><TiFlashOutline fontSize="1.8rem" />Flashcards</span>} className="mr-4">
              <FlashCardsMaterial />
            </Tab>
            <Tab title={<span style={{ fontWeight: "400", marginLeft: "-4px" }}><HiOutlineDocumentText fontSize="1.8rem" />Documents</span>} className="mr-4">
              <DocumentsMaterial />
            </Tab>
            <Tab className="ml-5" style={{backgroundColor:"blue"}} title={
              <button  style={{ margin: "-25px", marginLeft: "-27px" }} onClick={chnageRoute} className="study-material" ><img src={createIcon} />Add Study Material</button> 
              }>
              
            </Tab>
          </Tabs>
        </div>
      </Row>
    </>
  )
}

export default Study
