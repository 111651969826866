import React from 'react'
import { Row, Col, FormGroup, Input, Label, Select } from "reactstrap"
import dummy from "../../../assests/dummy.png"
import { AiOutlineLike } from "react-icons/ai"
const Vote = () => {
    return (
        <>
            <Row >
                <Col md="6">
                    <FormGroup>
                        <Label>Filter </Label>
                        <select type='select' className="admin-setting-field">
                            <option>Country</option>
                        </select>
                    </FormGroup>
                </Col>
                <Col md="6">
                    <FormGroup>
                        <Label> </Label>
                        <select type='select' className="admin-setting-field">
                            <option>University</option>
                        </select>
                    </FormGroup>
                </Col>

            </Row>

            <Row>
                <Col md="12">
                    <div className='quizs social-search'>
                        <ul className='social-search-list mr-3'>
                            <Input className='search-manage-inputField d-flex justify-content-center' placeholder='Search' />
                            <li> <img src={dummy} alt="img" className='m-2 mt-3' style={{ width: "50px", height: "50px" }} />Amelia Jeannet<p>3rd y MS, University of Geneva</p><button className='float-right'>Vote<AiOutlineLike className='m-1' /></button></li>
                            <li> <img src={dummy} alt="img" className='m-2 mt-3' style={{ width: "50px", height: "50px" }} />Amelia Jeannet<p>3rd y MS, University of Geneva</p><button className='float-right'>Vote<AiOutlineLike className='m-1' /></button></li>
                            <li> <img src={dummy} alt="img" className='m-2 mt-3' style={{ width: "50px", height: "50px" }} />Amelia Jeannet<p>3rd y MS, University of Geneva</p><button className='float-right'>Vote<AiOutlineLike className='m-1' /></button></li>
                            <li> <img src={dummy} alt="img" className='m-2 mt-3' style={{ width: "50px", height: "50px" }} />Amelia Jeannet<p>3rd y MS, University of Geneva</p><button className='float-right'>Vote<AiOutlineLike className='m-1' /></button></li>
                            <li> <img src={dummy} alt="img" className='m-2 mt-3' style={{ width: "50px", height: "50px" }} />Amelia Jeannet<p>3rd y MS, University of Geneva</p><button className='float-right'>Vote<AiOutlineLike className='m-1' /></button></li>
                            <li> <img src={dummy} alt="img" className='m-2 mt-3' style={{ width: "50px", height: "50px" }} />Amelia Jeannet<p>3rd y MS, University of Geneva</p><button className='float-right'>Vote<AiOutlineLike className='m-1' /></button></li>
                        </ul>
                    </div>
                </Col>
            </Row>
          
        </>
    )
}

export default Vote