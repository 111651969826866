import React from "react"
import TodayQuiz from "./quiz-tabs/Today-Quiz"
import { Row, Col } from "reactstrap"
import { BsEmojiSmile } from "react-icons/bs"
import { useState } from "react"
import Header from "../../components/Header"
const QuizResult = () => {
    const [button, setButton] = useState(false)
    

    return (
        <>
            <Header />
            <div className="quiz-result" >
                <div className="row pt-5">
                    <div className=" col-md-2"></div>
                    <div className="col-md-8 ">
                        <h2>
                            Quiz Result 
                        </h2>
                        <div className="Questions text-center mb-3">
                            No. Of Questions :90
                        </div>
                        <Row>
                            <Col md="6">
                                <div className="correct text-center">
                                    Correct Answer<br></br>60
                                </div>
                            </Col>
                            <Col md="6">
                                <div className="wrong text-center">
                                    Wrong Answer <br></br>30
                                </div>
                            </Col>
                        </Row>
                        <div className="total text-center mb-3 mt-3">
                            <BsEmojiSmile color="#72BB53" fontSize="5em" /><br></br> Total Score <br></br>50
                        </div>
                        <button className="rangeButton mt-5 d-flex justify-content-center mb-3" onClick={() => setButton(!button)}>Review Answere</button>
                    </div>
                    <div className=" col-md-2"></div>
                </div>

                <div className="row pt-5 ml-5">
                    <div className=" col-md-2"></div>
                    <div className="col-md-9">
                   <div>{button && <TodayQuiz/>}</div>
                   </div>
                    <div className=" col-md-1 mr-5"></div>
                </div>
            </div>

        </>
    )
}
export default QuizResult