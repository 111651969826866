import React from 'react'
import { Link } from 'react-router-dom';
import { Tab, Tabs } from 'react-bootstrap';
import { Row, Col } from "reactstrap"
import { FiHeart } from "react-icons/fi"
import comments from "../../assests/iCON/iCON/noun-comment-3266599.png"
import share from "../../assests/iCON/iCON/noun-share-5060531.png"
import flag from "../../assests/iCON/iCON/noun-flag-1017687.png"
import save from "../../assests/iCON/iCON/noun-bookmark-3025188.png"

const courseList = [
    {
        imageUrl: 'course.png',
        title: 'Complete Python Bootcamp From Zero to Hero in Python ',
        price: '2400',
        tag: 'Python',
        lesson: '32 ',
        status: 'alert-warning text-warning',
    },
    {
        imageUrl: 'course.png',
        title: 'Complete Python Bootcamp From Zero to Hero in Python ',
        price: '40',
        tag: 'Desinger',
        lesson: '24 ',
        status: 'alert-danger text-danger',
    },
    {
        imageUrl: 'course.png',
        title: 'Java Programming Masterclass for Developers',
        price: '60',
        tag: 'Bootstrap',
        lesson: '14 ',
        status: 'alert-success text-success',
    },
    {
        imageUrl: 'course.png',
        title: 'The Data Science Course Complete Data Science ',
        price: '370',
        tag: 'Develop',
        lesson: '23 ',
        status: 'alert-danger text-danger',
    },
    {
        imageUrl: 'course.png',
        title: 'Complete Python Bootcamp From Zero to Hero in Python ',
        price: '450',
        tag: 'Desinger',
        lesson: '24 ',
        status: 'alert-danger text-danger',
    },
    {
        imageUrl: 'course.png',
        title: 'Fundamentals for Scrum Master and Agile Projects ',
        price: '670',
        tag: 'Python',
        lesson: '32 ',
        status: 'alert-warning text-warning',
    },
    {
        imageUrl: 'course.png',
        title: 'Automate the Boring Stuff with Python Programming',
        price: '760',
        tag: 'Bootstrap',
        lesson: '14 ',
        status: 'alert-primary text-primary',
    },
    {
        imageUrl: 'course.png',
        title: 'The Data Science Course Complete Data Science ',
        price: '370',
        tag: 'Develop',
        lesson: '23 ',
        status: 'alert-danger text-danger',
    },
];
const Social = () => {
    return (
        <>
            <Tabs
                defaultActiveKey="Social"
                id="uncontrolled-tab-example"
                className="mb-3 mt-3 d-flex justify-content-center"
            >

                <Tab eventKey="Social" title="Social">
                    <div className="row d-block w-100 border-0 rounded-lg overflow-hidden" >
                        <div className=" mb-lg-3 pb-0">
                            <h2 className="fw-400 font-lg d-block">
                                <a
                                    href="/default-user-profile"
                                    className="float-right"
                                >
                                </a>
                            </h2>
                        </div>
                        <div className="card-body pb-0">
                            <div className="row">
                                {courseList.map((value, index) => (
                                    // Strat Single Demo
                                    <div
                                        className="col-xl-4 col-lg-6 col-md-6 col-sm-6 mb-4"
                                        key={index}
                                    >
                                        <div className="card w-100 p-0 shadow-xss border-0 rounded-lg overflow-hidden mr-1 course-card">
                                            <div className="card-image w-100 mb-3">

                                                <img
                                                    src={`assets/images/${value.imageUrl}`}
                                                    alt="course"
                                                    className="w-100"
                                                />
                                            </div>

                                        </div>
                                    </div>

                                    // End Single Demo
                                ))}
                            </div>
                        </div>
                    </div>
                </Tab>

                <Tab eventKey="Quiz" title="Quiz" >
                    <div className="row ">
                        <div className="col-md-5 rounded mt-5  mb-5  quizs">
                            <div className="mt-3 mb-4 ">Lorem ipsum dolor, sit amet consectetur adipisicing elit. Atque sit laboriosam sint sunt possimus aliquam at alias eius, eaque illo! Optio at accusamus laboriosam ipsum ab perspiciatis. Impedit, perspiciatis dolorum. </div>
                            <div>
                                <p>
                                    <input type="radio" />
                                    <label className="my-class" >Option 1<h1></h1></label>
                                </p>
                            </div>

                            <div>
                                <p>
                                    <input type="radio" />
                                    <label className="my-class" >Option 2<h1></h1></label>
                                </p>
                            </div>
                            <div>
                                <p>
                                    <input type="radio" />
                                    <label className="my-class" >Option 3<h1></h1></label>
                                </p>
                            </div>
<div className='bg-darks'>
                                    <div className='mt-2 pt-2 pl-2'>
                                        <FiHeart fontSize="2em" className="mr-3" />
                                        <img src={comments} width="25px" className="mr-3" />
                                        <img src={share} width="25px" />
                                        <img src={save} width="23px" className='float-right mr-3 ml-3' />
                                        <img src={flag} width="25px" className='float-right' />
                                    </div>

                                    <div className="card-body p-3">

                                        <h4 className="font-xss mt-2 ml-0 lh-28 mt-0 pb-3 border-bottom text-white" >
                                            View 08 Comments
                                        </h4>
                                        Add Comments
                                        <div className='float-right' style={{ color: "#E84545" }}> Send</div>
                                    </div>
                                   

                                </div>
                        </div>
                        <div className='col-md-1'></div>
                        <div className="col-md-5 rounded mt-5  mb-5  quizs">
                            <div className="mt-3 mb-4 ">Lorem ipsum dolor, sit amet consectetur adipisicing elit. Atque sit laboriosam sint sunt possimus aliquam at alias eius, eaque illo! Optio at accusamus laboriosam ipsum ab perspiciatis. Impedit, perspiciatis dolorum. </div>
                            <div>
                                <p>
                                    <input type="radio" />
                                    <label className="my-class" >Option 1<h1></h1></label>
                                </p>
                            </div>

                            <div>
                                <p>
                                    <input type="radio" />
                                    <label className="my-class" >Option 2<h1></h1></label>
                                </p>
                            </div>
                            <div>
                                <p>
                                    <input type="radio" />
                                    <label className="my-class" >Option 3<h1></h1></label>
                                </p>
                            </div>
<div className='bg-darks'>
                                    <div className='mt-2 pt-2 pl-2'>
                                        <FiHeart fontSize="2em" className="mr-3" />
                                        <img src={comments} width="25px" className="mr-3" />
                                        <img src={share} width="25px" />
                                        <img src={save} width="23px" className='float-right mr-3 ml-3' />
                                        <img src={flag} width="25px" className='float-right' />
                                    </div>

                                    <div className="card-body p-3">

                                        <h4 className="font-xss mt-2 ml-0 lh-28 mt-0 pb-3 border-bottom text-white" >
                                            View 08 Comments
                                        </h4>
                                        Add Comments
                                        <div className='float-right' style={{ color: "#E84545" }}> Send</div>
                                    </div>
                                   

                                </div>
                        </div>
                    </div>
                </Tab>
                <Tab eventKey="Study" title="Study" >
                    <div className=" d-block w-100 border-0 rounded-lg overflow-hidden">
                        <div className=" mb-lg-3 pb-0">
                            <h2 className="fw-400 font-lg d-block">
                                <a
                                    href="/default-user-profile"
                                    className="float-right"
                                >
                                </a>
                            </h2>
                        </div>
                        <div className="card-body pb-0">
                            <div className="row">
                                {courseList.map((value, index) => (
                                    // Strat Single Demo
                                    <div
                                        className="col-xl-4 col-lg-6 col-md-6 col-sm-6 mb-4"
                                        key={index}
                                    >
                                        <div className="card w-100 p-0 shadow-xss border-0 rounded-lg overflow-hidden mr-1 course-card">
                                            <div className="card-image w-100 mb-3">

                                                <img
                                                    src={`assets/images/${value.imageUrl}`}
                                                    alt="course"
                                                    className="w-100"
                                                />
                                            </div>

                                        </div>
                                    </div>

                                    // End Single Demo
                                ))}
                            </div>
                        </div>
                    </div>
                </Tab>
            </Tabs>

        </>
    )
}

export default Social