import React from 'react'
import { Row, Col, FormGroup, Input, Label, Select } from "reactstrap"

const Apply = () => {
    return (
        <>
            <Row >
                <Col md="4">
                    <FormGroup>
                        <Label>Filter </Label>
                        <select type='select' className="admin-setting-field">
                            <option>Country</option>
                        </select>
                    </FormGroup>
                </Col>
                <Col md="4">
                    <FormGroup>
                        <Label> </Label>
                        <select type='select' className="admin-setting-field">
                            <option>University</option>
                        </select>
                    </FormGroup>
                </Col>
                <Col md="4">
                    <FormGroup>
                        <Label> </Label>
                        <select type='select' className="admin-setting-field">
                            <option>Year</option>
                        </select>
                    </FormGroup>
                </Col>
            </Row>

            <Row>
                <label className='ml-3'>User Table</label>
                <table className='content-creator-table'>
                    <tr>
                        <th>ID</th>
                        <th>Content Type</th>
                        <th>Link to</th>
                        <th>Author</th>
                        <th>Flag/Like ratio</th>
                        <th>Total Flags</th>
                        <th>Date Last Flags</th>
                        <th>Total views</th>
                        <th>Total likes</th>
                        <th>like to viewed Ratio</th>
                        <th>List of flags reason</th>
                        <th>Actions</th>


                    </tr>
                    <tr>
                        <td>Alfreds Futterkiste</td>
                        <td>Maria Anders</td>
                        <td>Germany</td>
                        <td>Alfreds Futterkiste</td>
                        <td>Maria Anders</td>
                        <td>Germany</td>
                        <td>Alfreds Futterkiste</td>
                        <td>Maria Anders</td>
                        <td>Germany</td>
                        <td>Alfreds Futterkiste</td>
                        <td>Maria Anders</td>
                        <td>Germany</td>
                    </tr>
                    <tr>
                        <td>Alfreds Futterkiste</td>
                        <td>Maria Anders</td>
                        <td>Germany</td>
                        <td>Alfreds Futterkiste</td>
                        <td>Maria Anders</td>
                        <td>Germany</td>
                        <td>Alfreds Futterkiste</td>
                        <td>Maria Anders</td>
                        <td>Germany</td>
                        <td>Alfreds Futterkiste</td>
                        <td>Maria Anders</td>
                        <td>Germany</td>
                    </tr>

                </table>
            </Row>
            <Row className='mt-2'>
                <label className='ml-3'>Admin Table</label>
                <table className='content-creator-table'>
                    <tr>
                        <th>ID</th>
                        <th>Content Type</th>
                        <th>Link to</th>
                        <th>Author</th>
                        <th>Flag/Like ratio</th>
                        <th>Total Flags</th>
                        <th>Date Last Flags</th>
                        <th>Total views</th>
                        <th>Total likes</th>
                        <th>like to viewed Ratio</th>
                        <th>List of flags reason</th>
                        <th>Actions</th>


                    </tr>
                    <tr>
                        <td>Alfreds Futterkiste</td>
                        <td>Maria Anders</td>
                        <td>Germany</td>
                        <td>Alfreds Futterkiste</td>
                        <td>Maria Anders</td>
                        <td>Germany</td>
                        <td>Alfreds Futterkiste</td>
                        <td>Maria Anders</td>
                        <td>Germany</td>
                        <td>Alfreds Futterkiste</td>
                        <td>Maria Anders</td>
                        <td>Germany</td>
                    </tr>
                    <tr>
                        <td>Alfreds Futterkiste</td>
                        <td>Maria Anders</td>
                        <td>Germany</td>
                        <td>Alfreds Futterkiste</td>
                        <td>Maria Anders</td>
                        <td>Germany</td>
                        <td>Alfreds Futterkiste</td>
                        <td>Maria Anders</td>
                        <td>Germany</td>
                        <td>Alfreds Futterkiste</td>
                        <td>Maria Anders</td>
                        <td>Germany</td>
                    </tr>

                </table>
            </Row>
        </>
    )
}

export default Apply