import React, { useState } from "react";
import { Row, Col, Card } from "reactstrap"
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Link } from "react-router-dom";
import { BsFillPlusCircleFill, BsCardImage } from "react-icons/bs"
import { FiYoutube } from "react-icons/fi"
import { AiOutlineAudio } from "react-icons/ai"
import Switch from "react-switch";
import Header from "../../../components/Header"
import videoImg from "../../../view/assests/iCON/iCON/noun-photo-video-695389.png"
const CreateVideo = () => {
    const [premium, setPremium] = useState(false);
    const [worldwide, setWorlwide] = useState(false);
    const handlePremium = nextChecked => {
        setPremium(nextChecked);
    };
    const handleWorldwide = nextChecked => {
        setWorlwide(nextChecked);
    };
    return (
        <>
            <Header
                divclassName="shadow-none pos-fixed position-absolute"
                color="light"
            />
            <div className="sub-menu">
                <div className="ml-5 mr-5" >
                    <h1>  Create New Video</h1>
                    <span><img src="https://cdn.pixabay.com/photo/2014/04/02/17/07/user-307993__340.png" alt="img" className='mt-2 ml-1 rounded-circle border' width="30px" /> </span>
                    <span className="mt-5 ml-2" style={{ color: "#E84545" }}>Amelia Jeannet</span>
                    <Row className="mt-4">
                        <Col md="12">
                            <textarea placeholder='Write Description...' className='create-post-textarea'></textarea>
                        </Col>
                        <Col md="12" style={{borderBottom:'1px solid white',paddingBottom:"15px"}}>
                            <button className='document-hastags'>Add Hashtags</button>
                        </Col>
                    </Row>
                    <Row>
                        <h2>Target Audience</h2>
                        <Col md="12">
                            <select placeholder="Level" className="form-control" >
                                <option>Level </option>
                                <option>Level 1</option>
                                <option>Level 2</option>
                                <option>Level 3</option>
                                <option>Level 4</option>
                            </select>
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col md="4">
                            <select placeholder="Level" className="form-control" >
                                <option>Branch </option>
                                <option>Branch 1</option>
                                <option>Branch 2</option>
                                <option>Branch 3</option>
                                <option>Branch 4</option>
                            </select>
                        </Col>
                        <Col md="4">
                            <select placeholder="Chapter" className="form-control" >
                                <option>Chapter </option>
                                <option>Chapter 1</option>
                                <option>Chapter 2</option>
                                <option>Chapter 3</option>
                                <option>Chapter 4</option>
                            </select>
                        </Col>
                        <Col md="4">
                            <select placeholder="Level" className="form-control" >
                                <option>Sub-Chapter</option>
                                <option>Sub-Chapter 1</option>
                                <option>Sub-Chapter 2</option>
                                <option>Sub-Chapter 3</option>
                                <option>Sub-Chapter 4</option>
                            </select>
                        </Col>
                    </Row>

                    <div className="mt-3">
                        <label>Free </label>
                        <Switch

                            onChange={handlePremium}
                            checked={premium}
                            uncheckedIcon={false}
                            checkedIcon={false}
                            onColor="#E84545"
                            height={15}
                            width={30}
                            className="mt-3 ml-3 mr-3"
                        />

                        <label style={{ color: "#E84545" }}>Premium </label>
                    </div>

                    <label className="mt-1 my-class"> I understand that published content can only qualify to premium if it is exclusively my work and does not infringe copyright.
                    </label>
                    <input
                        type="checkbox"
                        className="float-left checkbox"
                        style={{ backgroundColor: "transparent", borderRadius: "3px", borderColor: "white", color: "#E84545", marginTop: "6px", marginRight: "15px" }}
                    />
                    <div>
                        <label>Local </label>
                        <Switch

                            onChange={handleWorldwide}
                            checked={worldwide}
                            uncheckedIcon={false}
                            checkedIcon={false}
                            onColor="#E84545"
                            height={15}
                            width={30}
                            className="mt-3 ml-3 mr-3"
                        />

                        <label style={{ color: "#E84545" }}>Worldwide </label>

                    </div>
                    <label className="mt-1 my-class"> I understand that published content can only qualify to premium if it is exclusively my work and does not infringe copyright.
                    </label>
                    <input
                        type="checkbox"
                        className="float-left checkbox"
                        style={{ backgroundColor: "transparent", borderRadius: "3px", borderColor: "white", color: "#E84545", marginTop: "6px", marginRight: "15px" }}
                    />

                    <div className="upload-video ">
                        <img src={videoImg} />
                        <h2>Drag video Here</h2>
                        <button type="upload" className="create-post-btn">Upload</button>
                    </div>
                    <h2 className="d-flex justify-content-center">Or</h2>
                    <Row >
                        <Col md="12">
                            <input className="form-control" placeholder="Post URL here" />
                        </Col>
                    </Row>
                </div>
                <Row>
                    <Col md="10"></Col>
                    <Col md="2">
                        <button className="create-post-btn mb-5 ">Post</button>
                    </Col>
                </Row>
            </div>
        </>
    )
}
export default CreateVideo