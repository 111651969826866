import React, { useState } from 'react';
import { BsFillTrophyFill } from "react-icons/bs"
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { Link } from 'react-router-dom';
import dummy from "../assests/dummy.png"
import { IoMdPersonAdd, IoMdAddCircleOutline } from "react-icons/io"
import trophy from "../assests/iCON/noun-trophy-5126269.png"
import { AiOutlineSearch } from "react-icons/ai"
import add from "../assests/iCON/noun-create-1409661.png"
import TodayQuiz from './quiz-tabs/Today-Quiz';
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  FormGroup,
  Input,
  Label,
  Collapse,
} from "reactstrap";
import QuizLeft from './quiz-tabs/Quiz-left';
import CustomizedQuiz from './quiz-tabs/Customized-quiz';
import { QuizFilter } from "./QuizFilters"
import Header from '../../components/Header';

const Quiz = () => {
  
  const courseList = [
    {
      title: 'View 08 Comments',
      titles: 'Add Comments',
      status: 'alert-warning text-warning',
    }]
  const FilterChange = (e) => {
    e.target.parentNode.childNodes.forEach(function (element) {
      if (e.target.checked == true) {
        element.classList.add("change-color")
      }
      else {
        element.classList.remove("change-color")
      }
    });
  }
  const createNewQuiz=()=>{
    window.location.reload();
  }

  return (
    <>
      <Header
        divclassName="shadow-none pos-fixed position-absolute"
        color="light"
      />
      
      <Row className="quiz">
        <QuizFilter />
        <div className="col-md-8 col-sm-12">
          <Row className='mt-2'>
            <Col md="7" sm="5">
              <div className="serch-input icon-input mb-3 d-flex justify-content-end">
                <i className="font-sm ti-search pr-0 " style={{ color: "#E84545" }}></i>
                <input
                  type="text"
                  className='form-control-search mt-3'
                  placeholder='Search'
                />
              </div>
            </Col>
            {/* <Col md="2" sm="4">
              <Link to="/create-quiz">
                <button className='create-quiz'><img src={add} className="w30 mr-3"/>Create Quiz</button>
              </Link>
            </Col> */}
            {/* <Col md='1'sm="2" style={{marginLeft:"11px"}}>
              <Link to="/TopUser">
                <img src={trophy} className="m-4" width="30px" />
              </Link>
            </Col> */}
          </Row>
          <button onClick={createNewQuiz} className="customize-btn m-3">Customize Quiz</button>
          <CustomizedQuiz />
          <Tabs
            defaultActiveKey="contact"
            id="uncontrolled-tab-example"
            className="mb-3 mt-3 "
          >

            {/* <Tab eventKey="profile" title="What's On today">
              <div className='ml-4'>
              <button className='create-posts '>Today</button>
              &nbsp; &nbsp;
              <button className='create-post'>This Week</button>
              &nbsp; &nbsp;
              <button className='create-posts'>Past 4 Weeks</button>
              </div>
              <Row className='mt-4'>
                <Col md="4"></Col>
                <Col md="4">
                  <h5 className='text-white'>No. of Question: 0/10</h5>
                </Col>
                <Col md="4" className='mb-1'>
                <select className='top-rated'>
                    <option value="SmartOrder">Smart Order</option>
                    <option value="Bestrated">Best rated first</option>
                    <option value="Newest">Newest first</option>
                    <option value="Trending">Trending Now </option>
                  </select>
                </Col>
                <Col md="4"></Col>
              </Row>
              <QuizLeft />
            </Tab> */}

            {/* <Tab eventKey="contact" title="Custom Quiz" >
              <CustomizedQuiz />
            </Tab> */}
            {/* <Tab eventKey="home" title="Start from where you Left" >
              <Row className='mt-4'>
                <Col md="4"></Col>
                <Col md="4">
                  <h5 className='text-white'>No. of Question: 0/10</h5>
                </Col>
                <Col md="4" className='mb-1'>
                <select className='top-rated'>
                    <option value="SmartOrder">Smart Order</option>
                    <option value="Bestrated">Best rated first</option>
                    <option value="Newest">Newest first</option>
                    <option value="Trending">Trending Now </option>
                  </select>
                </Col>
                <Col md="4"></Col>
              </Row>
              <TodayQuiz />
            </Tab> */}
          </Tabs>

        </div>

      </Row>
    </>
  );
}


export default Quiz;
