import React, { useState, useEffect } from 'react';
import { Link, NavLink } from 'react-router-dom';
// import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import { AiOutlineBell, AiFillCaretDown } from "react-icons/ai";
import { BiMessageAltDetail, BiUserCircle } from "react-icons/bi";
import logo from "../assets/images/logo (2).png"
import { useHistory } from 'react-router-dom';
import { icons } from 'react-icons/lib';
import bell from "../assets/iCON/noun-notification-5129395.png"
import msg from "../assets/iCON/noun-message-5124130.png"
import user from "../assets/iCON/NoPath.png"
import exeMed from "../assets/images/Color logo - no background.png"
import {
  Button,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Navbar,
  Nav,
  NavItem,
  Col,
  Row
} from "reactstrap";
import { useIdleTimer } from 'react-idle-timer'

const Header = () => {

  const [isOpen, setIsOpen] = useState(false);

  const toggle1 = () => {
    setIsOpen(!isOpen);
  };
  let localStorageObject = localStorage.getItem("Student_Info");
  var data = localStorageObject != null ? JSON.parse(localStorageObject) : null;
  console.log(data)

  function logout() {
    localStorage.removeItem('Student_Info');
  }
  const timeout = 1800000;
  const [remaining, setRemaining] = useState(timeout)
  const [elapsed, setElapsed] = useState(0)
  const [lastActive, setLastActive] = useState(+new Date())
  const [isIdle, setIsIdle] = useState(false)

  const handleOnActive = () => setIsIdle(false)
  function handleOnIdle() {
    setIsIdle(true);
    localStorage.removeItem('Student_Info');
    window.location.reload();
  }

  const {
    getRemainingTime,
    getLastActiveTime,
    getElapsedTime
  } = useIdleTimer({
    timeout,
    onActive: handleOnActive,
    onIdle: handleOnIdle
  })

  let history = useHistory();
  useEffect(() => {
    debugger
    if (data == null) {
      history.push("/login")
      return
    }
  }, [])

  return (

    <>
      <div style={{ width: "55px", margin: "0px auto" }}></div>
      <div className="" style={{ borderBottom: "1px solid #E84545" }}>
        <div className="container">
          <div className="row">
            <div className="col-lg-9 col-sm-6 navbar pt-0 pb-0">
              <Link to="/quiz">
                <img src={exeMed} width="150px" style={{ marginLeft: "-47px" }} />
              </Link>
              <div className="header">
                {/* <NavLink to="/social" activeStyle={{ color: "#E84545", backgroundColor: "transparent", borderTop: "2px solid #E84545", paddingTop: "2px", borderRadius: "0px" }}> Social</NavLink> */}
                <NavLink to="/quiz" activeStyle={{ color: "#E84545", backgroundColor: "transparent", borderTop: "2px solid #E84545", paddingTop: "2px", borderRadius: "0px" }}> Quiz</NavLink>
                {/* <NavLink to="/study" activeStyle={{ color: "#E84545", backgroundColor: "transparent", borderTop: "2px solid #E84545", paddingTop: "2px", borderRadius: "0px" }}> Study</NavLink> */}
              </div>

            </div>
            <div className="col-lg-3 col-sm-6"> 
              
              <div className='header-right-icons'>
              
            <Row style={{marginRight:"-153px",marginLeft:"188px"}}>
              {/* <Col md="2" style={{marginTop:"-22px"}}>
                <img src={bell} className="mr-4" width="25px" style={{marginTop:"28px"}} />
              </Col>
              <div style={{height:"30px", borderRight:"1px solid gray"}}></div>
              <Col md="2" style={{marginTop:"-22px"}}>
                <Link to="/admin-chat">  
                   <img src={msg} className="mr-4" width="25px" style={{marginTop:"28px"}} />
                  </Link>
              </Col>
              <div style={{height:"30px", borderRight:"1px solid gray"}}></div> */}
              <Col md="2">
              <UncontrolledDropdown nav inNavbar>
                  <DropdownToggle nav caret  className="pro-pic d-flex align-items-center"style={{marginTop:"-5px"}} >
                 
                    <img src={user} className="user w30" />
                    <span className="d-none d-md-block ml-2 font-medium" style={{ color: "black" }}>
                      {/* {data != null ? data.firstName : ""} */}
                      <AiFillCaretDown />
                    </span>
                  </DropdownToggle>
                  <DropdownMenu right className="user-dd">
                    <div className="d-flex no-block align-items-start border-bottom p-3 mb-2">
                      <div className="">
                        <img src={user} className="user w50" />
                      </div>
                      <div className="ml-2">
                        <h4 className="mb-0">{data != null ? (data.firstName + " " + data.lastName) : ""}</h4>
                        <p className="text-muted mb-0">{data != null ? data.email : ""}</p>
                        
                      </div>
                    </div>

                    <DropdownItem
                      onClick={logout}
                      href="/">
                      Logout
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </Col>
            </Row>
           
           
             
              </div>

            </div>
          </div>
        </div>
      </div>
    </>
  );
}



export default Header;
