import React, { useState } from "react"
import {QuizFilter} from "../QuizFilters"
import {
    Row,
    Col,
} from "reactstrap";
import { FcBookmark } from "react-icons/fc";
import { BsFillTrophyFill } from "react-icons/bs";
import Header from "../../../components/Header";

const RankPosition = () => {
   
    return (
        <>
        <Header/>
            <div className="quiz-result">
                <div className="row">
                    <QuizFilter />
                    <div className="col-8">
                        <Row>
                            <Col md="11">
                                <input placeholder='Search' className='form-control mt-3' />
                            </Col>
                        </Row>
                        <div className="top-btn">
                            <button className="today">Today</button>
                            <button className="Week">This Week</button>
                            <button className="today">This Year</button>
                            <h1 >Your ranking Position</h1>
                            <div className="mt-5">
                            <ul className="rank-position">
                                <li><a>No</a></li>
                                <li><a>User</a></li>
                                <li><a>aest</a></li>
                                <li><a>Total Points</a></li>

                            </ul>
                            <ul className="table-data" style={{backgroundColor:"#E84545"}}>
                                <li><b>1</b></li>
                                <li><b>Sherlock</b></li>
                                <li><b><BsFillTrophyFill color="red"/>263</b></li>
                                <li><b><FcBookmark fontSize="1.5em" />3238</b></li>

                            </ul>
                         
                        </div>
                        </div>
                        <h1>All User</h1>
                        <div className="top-btn">
                        
                            <div className="mt-5">
                            <ul>
                                <li><a>No</a></li>
                                <li><a>User</a></li>
                                <li><a>aest</a></li>
                                <li><a>Total Points</a></li>

                            </ul>
                            <ul className="table-data">
                                <li><b>1</b></li>
                                <li><b>Sherlock</b></li>
                                <li><b><BsFillTrophyFill color="red"/>263</b></li>
                                <li><b><FcBookmark fontSize="1.5em" />3238</b></li>

                            </ul>
                            <ul className="table-data">
                                <li><b>2</b></li>
                                <li><b>Sherlock</b></li>
                                <li><b><BsFillTrophyFill color="red"/>163</b></li>
                                <li><b><FcBookmark fontSize="1.5em" />4338</b></li>

                            </ul>
                        </div>
                        </div>
                       
                    </div>

                </div>
            </div>

        </>
    )
}

export default RankPosition