import React, { Component, Fragment } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  FormGroup,
  Input,
  Label,
  Collapse,
} from "reactstrap";
import singup from "../../../src/assets/images/SignUp.png";

class SignUpPage extends Component {
  render() {
    return (
      <Fragment>
        <div className="main-wrap">
          <div className="row">
            <div
              className="col-xl-5 d-none d-xl-block p-0 vh-100 bg-image-cover bg-no-repeat"

              // style={{
              //   backgroundImage:singup,
              // }}
            >
              <img
                src={singup}
                className=" w-50 float-right picture"
                style={{ marginRight: 80, marginTop: 15 }}
              />
            </div>
            <div className="col-xl-7 vh-100 align-items-center   overflow-hidden ">
              <div
                className="card shadow-none border-0 ml-auto mr-auto login-card color"
                style={{ maxWidth: "unset" }}
              >
                <div className="card-body rounded-0 text-left">
                  <h2 class="d-flex flex-row justify-content-center align-items-center mb-3 personal">
                    User Registration Details
                  </h2>

                  <h4 className=" pl-4 mb-2 personal">I am a</h4>
                  <Row>
                    <div class="col-6">
                      <div className="form-group mb-1 ">
                        <input
                          type="radio"
                          className="form-check-input mt-2 ml-5 mt-3"
                          id="exampleCheck1"
                        />
                        <a className="form-control text-center  text-white   border-0 p-0 mb-2 custom">
                          Medical Student
                        </a>
                      </div>
                    </div>

                    <div class="col-6">
                      <div className="form-group mb-1 ">
                        <input
                          type="radio"
                          className="form-check-input mt-2 ml-5 mt-3"
                          id="exampleCheck1"
                        />
                        <a className="form-control text-center  text-white   border-0 p-0 mb-2 signup">
                          Doctor
                        </a>
                      </div>
                    </div>
                  </Row>
                  <form>
                    <Card className="dark">
                      <CardBody>
                        <h5 className="personal">Student Level</h5>
                        <Row>
                          <div class="col-4">
                            <div className="form-check text-left mb-3">
                              <input
                                type="radio"
                                className="form-check-input mt-2 ml-3"
                                id="exampleCheck1"
                              />
                              <label
                                className="form-check-label font-xssss ml-5 personal"
                                htmlFor="exampleCheck1"
                              >
                                1st Year
                              </label>
                            </div>
                          </div>
                          <div class="col-4">
                            <div className="form-check text-left mb-3">
                              <input
                                type="radio"
                                className="form-check-input mt-2 ml-3"
                                id="exampleCheck1"
                              />
                              <label
                                className="form-check-label font-xssss ml-5 personal"
                                htmlFor="exampleCheck1"
                              >
                                2nd Year
                              </label>
                            </div>
                          </div>
                          <div class="col-4">
                            <div className="form-check text-left mb-3">
                              <input
                                type="radio"
                                className="form-check-input mt-2 ml-3"
                                id="exampleCheck1"
                              />
                              <label
                                className="form-check-label font-xssss ml-5 personal"
                                htmlFor="exampleCheck1"
                              >
                                3rd Year
                              </label>
                            </div>
                          </div>
                        </Row>
                        <Row>
                          <div class="col-4">
                            <div className="form-check text-left mb-3">
                              <input
                                type="radio"
                                className="form-check-input mt-2 ml-3"
                                id="exampleCheck1"
                              />
                              <label
                                className="form-check-label font-xssss ml-5 personal"
                                htmlFor="exampleCheck1"
                              >
                                4th Year
                              </label>
                            </div>
                          </div>
                          <div class="col-4">
                            <div className="form-check text-left mb-3">
                              <input
                                type="radio"
                                className="form-check-input mt-2 ml-3"
                                id="exampleCheck1"
                              />
                              <label
                                className="form-check-label font-xssss ml-5 personal"
                                htmlFor="exampleCheck1"
                              >
                                5th Year
                              </label>
                            </div>
                          </div>
                          <div class="col-4">
                            <div className="form-check text-left mb-3">
                              <input
                                type="radio"
                                className="form-check-input mt-2 ml-3"
                                id="exampleCheck1"
                              />
                              <label
                                className="form-check-label font-xssss ml-5 personal"
                                htmlFor="exampleCheck1"
                              >
                                6th Year
                              </label>
                            </div>
                          </div>
                        </Row>
                        <h5 className="personal">
                          My specialty of interest are:
                        </h5>
                        <Row>
                          <div class="col-4">
                            <div className="form-check text-left mb-3">
                              <input
                                type="checkbox"
                                className="form-check-input mt-2 ml-3"
                                id="exampleCheck1"
                              />
                              <label
                                className="form-check-label font-xssss ml-5 personal"
                                htmlFor="exampleCheck1"
                              >
                                Lorem Ipsum
                              </label>
                            </div>
                          </div>
                          <div class="col-4">
                            <div className="form-check text-left mb-3">
                              <input
                                type="checkbox"
                                className="form-check-input mt-2 ml-3"
                                id="exampleCheck1"
                              />
                              <label
                                className="form-check-label font-xssss ml-5 personal"
                                htmlFor="exampleCheck1"
                              >
                                Lorem Ipsum
                              </label>
                            </div>
                          </div>
                          <div class="col-4">
                            <div className="form-check text-left mb-3">
                              <input
                                type="checkbox"
                                className="form-check-input mt-2 ml-3"
                                id="exampleCheck1"
                              />
                              <label
                                className="form-check-label font-xssss ml-5 personal"
                                htmlFor="exampleCheck1"
                              >
                                Lorem Ipsum
                              </label>
                            </div>
                          </div>
                        </Row>
                        <Row>
                          <div class="col-4">
                            <div className="form-check text-left mb-3">
                              <input
                                type="checkbox"
                                className="form-check-input mt-2 ml-3"
                                id="exampleCheck1"
                              />
                              <label
                                className="form-check-label font-xssss ml-5 personal"
                                htmlFor="exampleCheck1"
                              >
                                Lorem Ipsum
                              </label>
                            </div>
                          </div>
                          <div class="col-4">
                            <div className="form-check text-left mb-3">
                              <input
                                type="checkbox"
                                className="form-check-input mt-2 ml-3"
                                id="exampleCheck1"
                              />
                              <label
                                className="form-check-label font-xssss ml-5 personal"
                                htmlFor="exampleCheck1"
                              >
                                Lorem Ipsum
                              </label>
                            </div>
                          </div>
                          <div class="col-4">
                            <div className="form-check text-left mb-3">
                              <input
                                type="checkbox"
                                className="form-check-input mt-2 ml-3"
                                id="exampleCheck1"
                              />
                              <label
                                className="form-check-label font-xssss ml-5 personal"
                                htmlFor="exampleCheck1"
                              >
                                Lorem Ipsum
                              </label>
                            </div>
                          </div>
                        </Row>
                      </CardBody>
                    </Card>
                    {/* <Row className="mt-2">
            <div class="col-4">

            </div>
            <div class="col-4">
<Card className="dark ">
    <CardBody>
        <div className="ti-layout-media-right-alt  form-check-label text-white  upload" ></div>
    </CardBody>
</Card>
</div>
<div class="col-4">

</div>
         </Row> */}
                  </form>
                  <Row>
                  <div class="col-2">

                  </div>
<div class="col-8 " >
                  <div  >
                    <div className="form-group ">
                      <a
                        href="/register"
                        className="form-control text-center text-white fw-600 mt-3 border-0 p-0 custom "
                      >
                        Sign Up
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-2">

                  </div>
                  </Row>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default SignUpPage;
