import React, { useState } from "react"
import {QuizFilter} from "./QuizFilters"
import {
    Row,
    Col,
} from "reactstrap";
import { BsFillTrophyFill, BsBookmarkFill } from "react-icons/bs";
import first from "../../assets/images/1st.png"
import second from "../../assets/images/2nd.png"
import third from "../../assets/images/3rd.png"
import Chart from "react-apexcharts"
import Header from "../../components/Header";
import ReactECharts from 'echarts-for-react';

const TopUser = () => {
    const option = {
        
        tooltip: {},
        legend: {
            data: ['Cardiovascular', 'Other Subject']
        },
        radar: {
            // shape: 'circle',
            indicator: [
                { name: 'sales', max: 6500 },
                { name: 'Administration', max: 16000 },
                { name: 'Information Techology', max: 30000 },
                { name: 'Customer Support', max: 38000 },
                { name: 'Development', max: 52000 },
                { name: 'Marketing', max: 25000 }
            ]
        },
        series: [{
            name: 'Budget vs spending',
            type: 'radar',
            // areaStyle: {normal: {}},
            data: [
                {
                    value: [4300, 10000, 28000, 35000, 50000, 19000],
                    name: 'Cardiovascular'
                },
                {
                    value: [5000, 14000, 28000, 31000, 42000, 21000],
                    name: 'Other Subject'
                }
            ]
        }]
    };
    let timer;
    const loadingOption = {
        text: 'Loading...',
        color: '#4413c2',
        textColor: '#270240',
        maskColor: 'rgba(194, 88, 86, 0.3)',
        zlevel: 0
    };

    function onChartReady(echarts) {
        timer = setTimeout(function () {
            echarts.hideLoading();
        }, 1000);
    }
    return (
        <>
            <Header />
            <div className="quiz-result">
                <div className="row">
                    <QuizFilter />
                    <div className="col-8">
                        <Row>
                            <Col md="11">
                                <div className="serch-input icon-input mb-3 d-flex justify-content-end mt-2">
                <i className="font-sm ti-search pr-0 mt-2" style={{ color: "#E84545" }}></i>
                <input
                  type="text"
                  className='form-control-search mt-3'
                  placeholder='Search'
                />
              </div>
                            </Col>
                        </Row>
                        <div className="top-btn">
                            <button className="today">Today</button>
                            <button className="Week">This Week</button>
                            <button className="today">This Year</button>
                            <h1 >Top Gainers</h1>
                            <Row>
                                <Col md="4">
                                    <img src={second} width="210px" />
                                </Col>
                                <Col md="4">
                                    <img src={first} width="236px" />
                                </Col>
                                <Col md="4">
                                    <img src={third} width="200px" />
                                </Col>
                            </Row>
                        </div>
                        <Row>
                            <Col md="6">
                                <h1>All Users</h1>
                            </Col>
                            <Col md="4"></Col>
                            <Col md="2" className="d-flex justify-content-end">
                                <h1 style={{ color: "#E84545" }}>See All</h1>
                            </Col>
                        </Row>

                        <div className="mt-5 ">
                            <ul>
                                <li><a>No</a></li>
                                <li><a>User</a></li>
                                <li><a>Best</a></li>
                                <li><a>Total Points</a></li>

                            </ul>
                            <ul className="table-data">
                                <li><b>1</b></li>
                                <li><b> Sherlock</b></li>
                                <li><b><BsFillTrophyFill color="#E84545" className="mr-1" />263</b></li>
                                <li><b><BsBookmarkFill fontSize="1.5em" color="#B1F626" className="mr-1" />3238</b></li>
                            </ul>
                            <ul className="table-data">
                                <li><b>2</b></li>
                                <li><b>Sherlock</b></li>
                                <li><b><BsFillTrophyFill color="#E84545" className="mr-1" />163</b></li>
                                <li><b><BsBookmarkFill fontSize="1.5em" color="#B1F626" className="mr-1" />4338</b></li>
                            </ul>
                        </div>

                        {/* <table className="table">
                            <tr>
                                <th>No.</th>
                                <th>User</th>
                                <th>Best</th>
                                <th>Total Point</th>
                            </tr>
                            <tr className="table-data" >
                                <td>1</td>
                                <td>Maria Anders</td>
                                <td>Germany</td>
                                <td>Germany</td>
                            </tr>
                            <tr className="table-data">
                                <td>1</td>
                                <td>Maria Anders</td>
                                <td>Germany</td>
                                <td>Germany</td>
                            </tr>
                        </table> */}
                        <h1>Students Radar Charts</h1>
                        <div className=" w-100 p-3 border-0 mt-4 rounded-10  shadow-xs overflow-hidden">

                            <ReactECharts
                                option={option}
                                style={{ height: 400 }}
                                onChartReady={onChartReady}
                                loadingOption={loadingOption}
                                showLoading={true}
                            />;
                        </div>
                    </div>

                </div>
            </div>

        </>
    )
}

export default TopUser