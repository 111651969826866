import React, { useState } from 'react';
import { Modal, ModalHeader, ModalFooter, Button, ModalBody, Input } from "reactstrap"
import { Link, useHistory } from 'react-router-dom';
// import Header from '../../components/Header';
// import Upperheader from '../../components/Upperheader';
// import Footertwo from '../../components/Footertwo';
import { RadioGroup, RadioButton } from 'react-radio-buttons';
import Slider from 'react-slick';
import dummy from "../assests/dummy.png"
import { FaRegComment } from "react-icons/fa"
import { MdReportProblem, MdOutlineClose } from "react-icons/md"
import { FiSend, FiHeart } from "react-icons/fi"
import { AiOutlineFlag, AiOutlineSearch } from "react-icons/ai"
import { BsFillFilePersonFill } from "react-icons/bs"
import { IoMdPersonAdd, IoMdAddCircleOutline } from "react-icons/io"
import { Row, Col } from "reactstrap"
import add from "../assests/iCON/noun-create-1409661.png"
import video from "../assests/video.mp4"
import { WithContext as ReactTags } from 'react-tag-input';
import like from "../assests/iCON/iCON/noun-like-1122328.png"
import comments from "../assests/iCON/iCON/noun-comment-3266599.png"
import share from "../assests/iCON/iCON/noun-share-5060531.png"
import flag from "../assests/iCON/iCON/noun-flag-1017687.png"
import save from "../assests/iCON/iCON/noun-bookmark-3025188.png"
import user from "../assests/iCON/iCON/noun-friend-2531936.png";
import medical from "../../assets/images/demo/medical.png"
import uplaod from "../assests/iCON/iCON/noun-photo-video-695389.png"
import reportIcon from "../assests/iCON/iCON/noun-alert-5119017.png"

import Header from '../../components/Header';

const courseList = [
  {
    imageUrl: 'course.png',
    title: 'Complete Python Bootcamp From Zero to Hero in Python',
    price: '2400',
    tag: 'Python',
    lesson: '32 ',
    status: 'alert-warning text-warning',
  },
  {
    imageUrl: 'course.png',
    title: 'Complete Python Bootcamp From Zero to Hero in Python ',
    price: '40',
    tag: 'Desinger',
    lesson: '24 ',
    status: 'alert-danger text-danger',
  }
];
const Social = () => {
  const [tags, setTags] = React.useState([
    { id: 'Thailand', text: 'Thailand' },
    { id: 'India', text: 'India' },
    { id: 'Vietnam', text: 'Vietnam' },
    { id: 'Turkey', text: 'Turkey' },
    { id: 'Turkey', text: 'Turkey' },
    { id: 'Turkey', text: 'Turkey' },
    { id: 'Turkey', text: 'Turkey' },
    { id: 'Turkey', text: 'Turkey' },
    { id: 'Vietnam', text: 'Vietnam' },

  ]);
  let history = useHistory();
  const backToRoute = () => {
    history.push("/search-socail");
  };

  const [selectedImage, setSelectedImage] = useState();
  const imageChange = (e) => {
    setPost(!post)
    let base64String = "";
    if (e.target.files && e.target.files.length > 0) {
      setSelectedImage(e.target.files[0])
      var file = document.querySelector(
        'input[type=file]')['files'][0];
      var reader = new FileReader();

      reader.onload = function () {
        base64String = reader.result.replace("data:", "")
          .replace(/^.+,/, "");
        // inputField.photoPath = base64String
        console.log(base64String);
      }
      reader.readAsDataURL(file);
    }
  };
  const [modal, setModal] = useState(false)
  const tagsModal = () => {
    setModal(!modal)
  }
  const [commentModal, setCommentModal] = useState(false)
  const CommentModal = () => {
    setCommentModal(!commentModal)
  }

  const [postModal, setPostModal] = useState(false)
  const createPostModal = () => {
    setPostModal(!postModal)

  }
  const [report, setReport] = useState(false)
  const createReport = () => {
    setReport(!report)
  }
  const [viewComments, setViewComments] = useState(false)

  const handleDelete = i => {
    setTags(tags.filter((tag, index) => index !== i));
  };

  const handleAddition = tag => {
    setTags([...tags, tag]);
  };

  const handleDrag = (tag, currPos, newPos) => {
    const newTags = tags.slice();

    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, tag);
    setTags(newTags);
  };

  const handleTagClick = index => {
    console.log('The tag at index ' + index + ' was clicked');
  };
  const KeyCodes = {
    comma: 188,
    enter: 13
  };
  const delimiters = [KeyCodes.comma, KeyCodes.enter];

  // CSS of priview Image
  const styles = {
    container: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      paddingTop: 50,
    },
    preview: {
      marginTop: 10,
      display: "flex",
      flexDirection: "column",
    },
    image: { maxWidth: "100%", maxHeight: 250 },
    select: {
      backgroundColor: "transparent",
      color: "white",
      borderRadius: "22px",
      fontWeight: 100,
      fontSize: "12px",
      marginLeft: "57px",
      width: "82px"
    },
  };
  const [post, setPost] = useState(false)


  return (
    <>
      <Header />
      <div className='quizs'>
        {/* Tags Modal */}
        <Modal
          isOpen={modal}
          toggle={tagsModal.bind(null)}
          className="modal-tags"
        >
          <div className='mb-0' style={{ margin: "20px" }}>
            <h3 style={{ color: "white" }}>Add Tags</h3>
            <MdOutlineClose className='tag-close-btn' color='white' onClick={() => setModal(false)} />
            <div className='add-border'></div>
          </div>
          {/* <ModalHeader
            toggle={tagsModal.bind(null)} >
              <h3 style={{ color: "white" }}>Add Tags</h3>
              </ModalHeader> */}
          <ModalBody>
            <Row>
              <Input className='modal-inputField' placeholder='Search Tags' />
            </Row>
            <div >
              <button className='all-tags'>Organ Systems</button>
              <button className='all-tags'>Organ Systems</button>
              <button className='all-tags'> Systems</button>
              <button className='all-tags'>Organ Systems</button>
              <button className='all-tags'>Organ Systems</button>
              <button className='all-tags'>Organ </button>
              <button className='all-tags'>Organ Systems</button>
              <button className='all-tags'>Organ Systems</button>
              <button className='all-tags'>Organ Systems</button>
            </div>
          </ModalBody>
          <button className='tags-btn '>Done</button>
        </Modal>
        {/* Report Modal */}
        <Modal
          isOpen={report}
          toggle={createReport.bind(null)}
          className="modal-tags"
          style={{ maxWidth: "350px" }}
        >
          <div style={{ margin: "20px" }}>
            <h3 style={{ color: "white" }}><img src={reportIcon} width="20px" className='mr-2' />Report</h3>
            <MdOutlineClose className='tag-close-btn' color='white' onClick={() => setReport(false)} />
            <div className='add-tag-border'></div>
          </div>

          <ModalBody>

            <RadioGroup
              // onChange={ this.onChange }
              vertical>
              <RadioButton pointColor="#E84545" iconSize={20} value="Inappropriate" style={{ color: "white"}}>
                Inappropriate
              </RadioButton>
              <RadioButton pointColor="#E84545" iconSize={20} value="Misleading">
                Misleading
              </RadioButton>
              <RadioButton pointColor="#E84545" iconSize={20} value="Incorrect">
                Incorrect Content
              </RadioButton>
            </RadioGroup>
          </ModalBody>
          <button className='report-btn'>Submit</button>

        </Modal>
        {/* Create Post Modal */}
        <Modal
          isOpen={postModal}
          toggle={createPostModal.bind(null)}
          className="modal-tags text-white"
        >
          <div style={{ margin: "20px" }}>
            <h3 style={{ color: "white" }}>Create New Post</h3>
            <MdOutlineClose className='tag-close-btn' color='white' onClick={() => setPostModal(false)} />
            <div className='add-border'></div>
          </div>

          <ModalBody>
            {post &&
              <div style={{marginTop:"-30px"}}>
                <Link to="/showUserProfile"><span><img src={dummy} alt="img" className='mt-2 ml-1' style={{ width: "50px", height: "50px" }} /> </span></Link>
                <span style={{ color: "#E84545" }}>Amelia Jeannet   <p style={{ marginTop: "-20px" }}><select style={styles.select}>
                  <option>Anyone</option>
                </select></p></span>
                <textarea placeholder='Write Description...' className='create-post-textarea'></textarea>
              </div>
            }
            {post ||
              <div>
                <img src={uplaod} width="140px" style={{ marginLeft: '150px' }} className="mb-5" />
                <label style={{ marginLeft: '120px' }} className="mb-4">Drag Photos And Videos Here</label><br />
                {/* <input type="file" style={{ marginLeft: '120px' }} onChange={imageChange} /> */}
                <input type="file" id="upload" onChange={imageChange} hidden />
                <label for="upload" style={{ marginLeft: '150px' }} className='create-post-label'>Select From Computer</label>
              </div>
            }

            {selectedImage && (
              <div style={styles.preview}>
                <img
                  src={URL.createObjectURL(selectedImage)}
                  style={styles.image}
                  alt="Thumb"
                />
              </div>
            )}

          </ModalBody>
          {post &&
            <div>
              <button className='add-hastags'>Add Hashtag</button>
              <button className='create-modal-post'>Post</button>
            </div>
          }
        </Modal>
        {/* Add/View Comment Modal */}
        <Modal
          style={{ maxWidth: "800px" }}
          isOpen={commentModal}
          toggle={CommentModal.bind(null)}
          className="modal-tags"
        >


          <ModalBody style={{ padding: "0px" }} >

            <Row>
              <Col md="6">
                <img src={medical} width="100%" />
              </Col>
              <Col md="6">
                <MdOutlineClose className='MdOutlineClose' color='white' onClick={() => setCommentModal(false)} />
                <Link to="/showUserProfile"><span><img src={dummy} alt="img" className='mt-2 ml-1' style={{ width: "50px", height: "50px" }} /> </span></Link>
                <span style={{ color: "#E84545" }}>Amelia Jeannet  <p className='des'>01 Hour Ago</p>
                  <p className='des mt-2'>Lorem Ipsum Is Simply Dummy Text Of The Printing And Typesetting Industry.Lorem Ipsum.Lorem Ipsum Is Simply Dummy Text Of The Printing And Typesetting Industry.Lorem Ipsum.</p>
                </span>

                <div className='scrollbar scrollbar-primary' style={{ height: "245px", backgroundColor: "#1D2226" }}>
                  <div className='force-overflow'>
                    <div className="row">
                      <div className="col-2 text-left pt-3 m-2">
                        <figure className="avatar float-left mb-0">
                          <img
                            src="https://statinfer.com/wp-content/uploads/dummy-user.png"
                            alt="banner"
                            className="float-right shadow-none w40 mr-2 rounded-circle"
                          />
                        </figure>
                      </div>
                      <div className="col-10 pt-2 modal-comments">
                        <div className="content mt-3">
                          <p className="author-name font-xssss" style={{ color: "white" }}>
                            Goria Coast
                          </p>
                          <p className="comment-text lh-24 fw-500 font-xssss text-grey-500" style={{ marginTop: "-20px" }}>
                            Enjoyed this a lot and well done.
                            <FiHeart className="float-right mr-3" />
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-2 text-left pt-3 m-2">
                        <figure className="avatar float-left mb-0">
                          <img
                            src="https://statinfer.com/wp-content/uploads/dummy-user.png"
                            alt="banner"
                            className="float-right shadow-none w40 mr-2 rounded-circle"
                          />
                        </figure>
                      </div>
                      <div className="col-10 pt-2 modal-comments">
                        <div className="content mt-2">
                          <p className="author-name font-xssss" style={{ color: "white" }}>
                            Goria Coast
                          </p>
                          <p className="comment-text lh-24 fw-500 font-xssss text-grey-500" style={{ marginTop: "-20px" }}>
                            Enjoyed this a lot and well done.
                            <FiHeart className="float-right mr-3" />
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-2 text-left pt-3 m-2">
                        <figure className="avatar float-left mb-0">
                          <img
                            src="https://statinfer.com/wp-content/uploads/dummy-user.png"
                            alt="banner"
                            className="float-right shadow-none w40 mr-2 rounded-circle"
                          />
                        </figure>
                      </div>
                      <div className="col-10 pt-2 modal-comments">
                        <div className="content mt-2">
                          <p className="author-name font-xssss" style={{ color: "white" }}>
                            Goria Coast
                          </p>
                          <p className="comment-text lh-24 fw-500 font-xssss text-grey-500" style={{ marginTop: "-20px" }}>
                            Enjoyed this a lot and well done.
                            <FiHeart className="float-right mr-3" />
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-2 text-left pt-3 m-2">
                        <figure className="avatar float-left mb-0">
                          <img
                            src="https://statinfer.com/wp-content/uploads/dummy-user.png"
                            alt="banner"
                            className="float-right shadow-none w40 mr-2 rounded-circle"
                          />
                        </figure>
                      </div>
                      <div className="col-10 pt-2 modal-comments">
                        <div className="content mt-2">
                          <p className="author-name font-xssss" style={{ color: "white" }}>
                            Goria Coast
                          </p>
                          <p className="comment-text lh-24 fw-500 font-xssss text-grey-500" style={{ marginTop: "-20px" }}>
                            Enjoyed this a lot and well done.
                            <FiHeart className="float-right mr-3" />
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-2 text-left pt-3 m-2">
                        <figure className="avatar float-left mb-0">
                          <img
                            src="https://statinfer.com/wp-content/uploads/dummy-user.png"
                            alt="banner"
                            className="float-right shadow-none w40 mr-2 rounded-circle"
                          />
                        </figure>
                      </div>
                      <div className="col-10 pt-2 modal-comments">
                        <div className="content mt-2">
                          <p className="author-name font-xssss" style={{ color: "white" }}>
                            Goria Coast
                          </p>
                          <p className="comment-text lh-24 fw-500 font-xssss text-grey-500" style={{ marginTop: "-20px" }}>
                            Enjoyed this a lot and well done.
                            <FiHeart className="float-right mr-3" />
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-2 text-left pt-3 m-2">
                        <figure className="avatar float-left mb-0">
                          <img
                            src="https://statinfer.com/wp-content/uploads/dummy-user.png"
                            alt="banner"
                            className="float-right shadow-none w40 mr-2 rounded-circle"
                          />
                        </figure>
                      </div>
                      <div className="col-10 pt-2 modal-comments">
                        <div className="content mt-2">
                          <p className="author-name font-xssss" style={{ color: "white" }}>
                            Goria Coast
                          </p>
                          <p className="comment-text lh-24 fw-500 font-xssss text-grey-500" style={{ marginTop: "-20px" }}>
                            Enjoyed this a lot and well done.
                            <FiHeart className="float-right mr-3" />
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-2 text-left pt-3 m-2">
                        <figure className="avatar float-left mb-0">
                          <img
                            src="https://statinfer.com/wp-content/uploads/dummy-user.png"
                            alt="banner"
                            className="float-right shadow-none w40 mr-2 rounded-circle"
                          />
                        </figure>
                      </div>
                      <div className="col-10 pt-2 modal-comments">
                        <div className="content mt-2">
                          <p className="author-name font-xssss" style={{ color: "white" }}>
                            Goria Coast
                          </p>
                          <p className="comment-text lh-24 fw-500 font-xssss text-grey-500" style={{ marginTop: "-20px" }}>
                            Enjoyed this a lot and well done.
                            <FiHeart className="float-right mr-3" />
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-2 text-left pt-3 m-2">
                        <figure className="avatar float-left mb-0">
                          <img
                            src="https://statinfer.com/wp-content/uploads/dummy-user.png"
                            alt="banner"
                            className="float-right shadow-none w40 mr-2 rounded-circle"
                          />
                        </figure>
                      </div>
                      <div className="col-10 pt-2 modal-comments">
                        <div className="content mt-2">
                          <p className="author-name font-xssss" style={{ color: "white" }}>
                            Goria Coast
                          </p>
                          <p className="comment-text lh-24 fw-500 font-xssss text-grey-500" style={{ marginTop: "-20px" }}>
                            Enjoyed this a lot and well done.
                            <FiHeart className="float-right mr-3" />
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-2 text-left pt-3 m-2">
                        <figure className="avatar float-left mb-0">
                          <img
                            src="https://statinfer.com/wp-content/uploads/dummy-user.png"
                            alt="banner"
                            className="float-right shadow-none w40 mr-2 rounded-circle"
                          />
                        </figure>
                      </div>
                      <div className="col-10 pt-2 modal-comments">
                        <div className="content mt-2">
                          <p className="author-name font-xssss" style={{ color: "white" }}>
                            Goria Coast
                          </p>
                          <p className="comment-text lh-24 fw-500 font-xssss text-grey-500" style={{ marginTop: "-20px" }}>
                            Enjoyed this a lot and well done.
                            <FiHeart className="float-right mr-3" />
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='' style={{ backgroundColor: "black", marginLeft: "-29px" }}>
                  <div className="card-body p-3" style={{ marginTop: "284px" }}>
                    <div className='mt-2 pt-2 pl-2'>
                      <img src={like} width="20px" className="mr-3" />
                      <img src={comments} width="20px" className="mr-3" />
                      <img src={share} width="20px" />
                      <img src={save} width="18px" className='float-right mr-3 ml-3' />
                      <img src={flag} width="18px" className='float-right' />
                    </div>
                    <h4 className="font-xss mt-2 ml-0 lh-28 mt-0 pb-3 border-bottom" style={{ color: "white" }}></h4>
                    <span style={{ color: "#BCB2B2" }}>Add Comments</span>
                    <div className='float-right' style={{ color: "#E84545" }}> Send</div>
                  </div>
                </div>
              </Col>
            </Row>
          </ModalBody>
        </Modal>


        <Row>
          <Col md="2"></Col>
          <Col md="6" sm="5">
            <div className="serch-input icon-input mb-3 d-flex justify-content-end">
              <i className="font-sm ti-search pr-0 " style={{ color: "#E84545", fontWeight: "700" }}></i>
              <input
                type="text"
                className='form-control-search mt-3'
                placeholder='Search'
                onClick={backToRoute}
              />
            </div>
          </Col>
          <Col md="2" sm="4">
            <button className='create-quiz w200'
              onClick={(event) => { createPostModal(event) }}>
              <img src={add}
                className="mr-3" width="25px" />Create Post</button>
          </Col>
          <Col md="3"></Col>

        </Row>
        <Row>
          <Col md="2"></Col>
          <Col md="8" className='m-2'>
            <div className="app" style={{ marginLeft: "-9px" }}>
              <h1 className='text-white'>Tags </h1>
              <div >
                <ReactTags
                  tags={tags}
                  // suggestions={suggestions}
                  delimiters={delimiters}
                  handleDelete={handleDelete}
                  handleAddition={handleAddition}
                  handleDrag={handleDrag}
                  handleTagClick={handleTagClick}
                  inputFieldPosition="none"
                  autocomplete
                />
                <button className='tag-btn' onClick={(event) => { tagsModal(event) }}>Add Tags</button>
              </div>
            </div>
          </Col>
          <Col md="2"></Col>
        </Row>

        {courseList.map((value, index) => (
          // Strat Single Demo
          <Row >
            <Col md="2"></Col>
            <Col md="8">
              <div className='social'>
                <div className=" w-100 border-0 rounded-lg overflow-hidden mr-1 text-grey-900 shadow-lg  mb-5 rounded">
                  <div className="card-image w-100 mb-3">
                    <Link to="/single-user-profile"><span><img src={dummy} alt="img" className='mt-2 ml-1' style={{ width: "50px", height: "50px" }} /> </span></Link>
                    <span>Amelia Jeannet   <p className='user-des'>3Rd Y MS, University Of Geneva</p></span>
                    <img src={user} className="float-right mr-2 addUser" width="40px" />
                    <Link
                      to="#"
                      className="video-bttn position-relative d-block mt-2">
                      <video controls className="w-100" >
                        <source src={video} type="video/mp4" ></source>
                      </video>
                    </Link>
                  </div>
                  <div>
                    <div className='mt-1 pt-2 pl-2'>
                      <img src={like} width="25px" className="mr-3 ml-2" />
                      <img src={comments} width="25px" className="mr-3" />
                      <img src={share} width="25px" />
                      <img src={save} width="23px" className='float-right mr-3 ml-3' />
                      <img src={flag} width="25px" className='float-right' onClick={(event) => { createReport(event) }} />
                    </div><p className='des-time'>01 Hour Ago</p>
                    <p className='caption'><b>Amelia Jeannet Lorem</b> Ipsum Is Simply Dummy Text Of The Printing And Typesetting Industry.Lorem Ipsum. More</p>
                    <div className="card-body p-3">
                      <h4 className="font-xss mt-2 ml-0 lh-28 mt-0 pb-3 border-bottom text-white"
                        //  onClick={() => setViewComments(!viewComments)}
                        onClick={(event) => { CommentModal(event) }}
                      >
                        View 08 Comments
                      </h4>
                      <span style={{ color: "#BCB2B2" }}>Add Comments</span>
                      <div className='float-right' style={{ color: "#E84545" }}> Send</div>
                    </div>
                    {viewComments &&
                      <div className="row">
                        <div className="col-2 text-left pl-4 pt-3 m-2">
                          <figure className="avatar float-left mb-0">
                            <img
                              src="https://statinfer.com/wp-content/uploads/dummy-user.png"
                              alt="banner"
                              className="float-right shadow-none w40 mr-2 rounded-circle"
                            />
                          </figure>
                        </div>
                        <div className="col-10 pt-2 comments">
                          <div className="content">
                            <b className="author-name font-xssss fw-600 mb-0 ">
                              Goria Coast
                            </b>
                            <p className="comment-text lh-24 fw-500 font-xssss text-grey-500 ">
                              Enjoyed this a lot and well done.
                              <FiHeart className="float-right" style={{ marginRight: "-23px" }} />
                            </p>
                          </div>
                        </div>
                      </div>
                    }
                  </div>
                </div>
              </div>
              <div
                className="col-xl-3 col-lg-6 col-md-6 col-sm-6 mb-4"
                key={index}
              ></div>
            </Col>
            <Col md="3"></Col>
          </Row>
          // End Single Demo
        ))}
      </div>

      {/* <Footertwo /> */}
    </>
  );
}

export default Social;
