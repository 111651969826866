import React, { Component, Fragment } from 'react';
import Appfooter from '../../components/Appfooter';
import Navheader from '../../components/Navheader';
import Appheader from '../../components/Appheader';
import Profile from '../../components/Profile';
import Darkmode from '../../components/Darkmode';
import Subscribe from '../../components/Subscribe';
import { Link } from 'react-router-dom';

class Accountinfo extends Component {
  render() {
    return (
      <Fragment>
        <div className="main-wrapper">
          {/* <Navheader /> */}

          <div className="main-content" style={{paddingLeft:"0px"}}>
            {/* <Appheader /> */}

            <div className="middle-sidebar-bottom bg-lightblue theme-dark-bg">
              <div className="middle-sidebar-left">
                <div className="middle-wrap">
                  <div className="card w-100 border-0 bg-white shadow-xs p-0 mb-4">
                    <div className="card-body p-4 w-100 bg-current border-0 d-flex rounded-lg">
                      <Link
                        to="/default-settings"
                        className="d-inline-block mt-2"
                      >
                      </Link>
                      <h4 className="font-xs text-white fw-600 ml-4 mb-0 mt-2">
                        User Registration Form
                      </h4>
                    </div>
                    
                    <div className="card-body p-lg-5 p-4 w-100 border-0 ">
                    <div className="row">
                          <div className="col-lg-6 mb-3">
                            <div className="form-group">
                              <label className="mont-font fw-600 font-xsss">
                                USERNAME
                              </label>
                              <input type="text" className="form-control" />
                            </div>
                          </div>

                          <div className="col-lg-6 mb-3">
                            <div className="form-group">
                              <label className="mont-font fw-600 font-xsss">
                                PASSWORD
                              </label>
                              <input type="text" className="form-control" />
                            </div>
                          </div>
                        </div>

                      <div className="row justify-content-center">
                        <div className="col-lg-4 text-center">
                          <figure className="avatar ml-auto mr-auto mb-0 mt-2 w100">
                            <img
                              src="https://via.placeholder.com/150x150.png"
                              alt="avater"
                              className="shadow-sm rounded-lg w-100"
                            />
                          </figure>
                          <h2 className="fw-700 font-sm text-grey-900 mt-3">
                           <input type="file"/>
                          </h2>
                        </div>
                      </div>

                      <form action="#">
                        <div className="row">
                          <div className="col-lg-6 mb-3">
                            <div className="form-group">
                              <label className="mont-font fw-600 font-xsss">
                               FIRST NAME
                              </label>
                              <input type="text" className="form-control" />
                            </div>
                          </div>

                          <div className="col-lg-6 mb-3">
                            <div className="form-group">
                              <label className="mont-font fw-600 font-xsss">
                                LAST NAME
                              </label>
                              <input type="text" className="form-control" />
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-lg-6 mb-3">
                            <div className="form-group">
                              <label className="mont-font fw-600 font-xsss">
                                PSEUDO
                              </label>
                              <input type="text" className="form-control" />
                            </div>
                          </div>

                          <div className="col-lg-6 mb-3">
                            <div className="form-group">
                              <label className="mont-font fw-600 font-xsss">
                                COUNTRY
                              </label>
                              <input type="text" className="form-control" />
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-lg-6 mb-3">
                            <div className="form-group">
                              <label className="mont-font fw-600 font-xsss">
                                LANGUAGE
                              </label>
                              <input type="text" className="form-control" />
                            </div>                            
                          </div>

                          <div className="col-lg-6 mb-3">
                            <div className="form-group">
                              <label className="mont-font fw-600 font-xsss">
                                TYPE
                              </label>
                              <select type="text" className="form-control" >
                                <option>Med Student</option>
                              </select>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-lg-6 mb-3">
                            <div className="form-group">
                              <label className="mont-font fw-600 font-xsss">
                                UNIVERSITY
                              </label>
                              <input type="text" className="form-control" />
                            </div>
                          </div>

                          <div className="col-lg-6 mb-3">
                            <div className="form-group">
                              <label className="mont-font fw-600 font-xsss">
                                STUDENT LEVEL
                              </label>
                              <select className='form-control'>
                                <option>1st Year</option>
                                <option>2nd Year</option>
                              </select>

                            </div>
                          </div>


                          <div className="col-lg-12 mb-3">
                            <label className="mont-font fw-600 font-xsss">
                              SPECIALITY OF INTERESTS
                            </label>
                            <textarea
                              className="form-control mb-0 p-3 bg-greylight lh-16"
                              rows="5"
                              placeholder="Write your message..."
                            ></textarea>
                          </div>

                          <div className="col-lg-12">
                            <Link
                              to="/account-information"
                              className="bg-current text-center text-white font-xsss fw-600 p-3 w175 rounded-lg d-inline-block"
                            >
                              SUBMIT
                            </Link>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              <div className="middle-sidebar-right scroll-bar">
                <div className="middle-sidebar-right-content">
                  <Profile />
                  <Darkmode />
                  <Subscribe />
                </div>
              </div>
            </div>
          </div>

          <Appfooter />
        </div>
      </Fragment>
    );
  }
}

export default Accountinfo;
