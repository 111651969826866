import React from 'react'
import { Row, Col, FormGroup, Input, Label, Select } from "reactstrap"
import Header from '../../../components/Header'
import { AdminMenuBar } from '../SideBar'
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import country from "../../../view/assests/iCON/noun-country-2450449.png"
import Apply from './chapter-admin-tabs/Apply';
import Manage from './chapter-admin-tabs/Manage';
import Vote from './chapter-admin-tabs/Vote';
const ChapterAdmin = () => {
    return (
        <>
            <Header />
            <div className='change-password pt-5' style={{ backgroundColor: "#181818", color: "white", height: "800px" }}>
                <Row>
                    <Col md="1"></Col>
                    <Col md="3">
                        <AdminMenuBar />
                    </Col>
                    <Col md="7">
                        <Tabs
                            defaultActiveKey="Manage"
                            id="uncontrolled-tab-example"
                            className="mb-3 mt-3 "
                        >
                            <Tab eventKey="Manage" title="Manage">
                                <Apply />
                            </Tab>

                            <Tab eventKey="Vote" title="Vote" >
                                <Manage />
                            </Tab>
                            <Tab eventKey="Apply" title="Apply" >
                            <Vote/>
                            </Tab>
                        </Tabs>
                    </Col>
                </Row>

            </div>
        </>
    )
}

export default ChapterAdmin