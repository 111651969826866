import React, { useState, useEffect } from "react"
import { Link } from 'react-router-dom';
import QuizServices from "../../services/QuizServices";
import heartIcon from "../../assests/iCON/noun-heart-3515260@2x.png"
import comments from "../../assests/iCON/iCON/noun-comment-3266599.png"
import share from "../../assests/iCON/iCON/noun-share-5060531.png"
import flag from "../../assests/iCON/iCON/noun-flag-1017687.png"
import save from "../../assests/iCON/iCON/noun-bookmark-3025188.png"
import { Row, Col } from "reactstrap"
import dimand from "../../assests/iCON/iCON/noun-diamond-1807567.png"
import samllImg from "../../assests/iCON/iCON/noun-image-5079181.png"
import video from "../../assests/iCON/iCON/noun-video-1841846.png"
import Swal from "sweetalert2"
import dummy from "../../assests/dummy.png"
import { FiHeart, FiBookmark } from "react-icons/fi"
import { AiOutlineYoutube } from "react-icons/ai"
import TodayQuiz from "./Today-Quiz";

const CustomizedQuiz = ({ handleClick }) => {

    const [quiz, setQuiz] = useState([])
    const [value, setValue] = useState(1)
    function handleChangeValue(e) {
        setValue(e.target.value)
    }

    const submit = async () => {
       
        // var response = await QuizServices.getQuizesByCount(value)
        // console.log(response.data.payload);
        // setQuiz(response.data.payload);
        // console.log(quiz)
        setViewComments(!viewComments)
    }

    const [viewComments, setViewComments] = useState(false)
    const [option, setOption] = useState([])
    const [selectOpt, setSelectOpt] = useState([])
    let localStorageObject = localStorage.getItem("Student_Info");
    var data = JSON.parse(localStorageObject);

    const FilterChange = (e) => {
        e.target.parentNode.childNodes.forEach(function (element) {
            if (e.target.checked == true) {
                element.classList.add("change-color")
            }
            else {
                element.classList.remove("change-color")
            }
        });
    }
    const ChangeQuestionOption = (e) => {
        e.target.parentNode.childNodes.forEach(function (element) {
            if (e.target.checked == true) {
                element.classList.add("change-color")
            }
            else {
                element.classList.remove("change-color")
            }
        });

        var qId = e.target.getAttribute('data-questionId')
        var questionOb = selectOpt.find(x => x.questionId == qId);
        if (questionOb == null) {
            var tempArr = selectOpt;
            tempArr.push({
                userId: data.id,
                questionId: qId,
                isCorrect: false,
                options: [e.target.value]
            })
            setSelectOpt(tempArr)
        }
        else {
            if (e.target.checked == true) {
            
                questionOb.options.push(e.target.value);
                var tempArr = selectOpt;
                tempArr.find(x => x.questionId == qId).options = questionOb.options;
                setSelectOpt(tempArr)
            }
            else {
                questionOb.options = questionOb.options.filter(function (obj) {
                    return obj !== e.target.value;
                });

                debugger
                if (questionOb.options.length == 0) {
                    var tempArr = selectOpt;
                    tempArr = tempArr.filter(function (obj) {
                        return obj.questionId !== qId;
                    });
                    setSelectOpt(tempArr)
                }
                else {
                    var tempArr = selectOpt;
                    tempArr.find(x => x.questionId == qId).options = questionOb.options;
                    setSelectOpt(tempArr)
                }
            }
        }
        console.log(selectOpt);
    }
    const submitButton = async () => {
        try {
            await Swal.fire({
                title: "Are you sure?",
                text: "You won't be able to revert this!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes",
            }).then(async (result) => {
                if (result.isConfirmed) {
                    var response = await QuizServices.AddQuizQuestion(selectOpt);
                    
                    if (response.data.status === true) {
                        Swal.fire("Added!", "" + "Quiz Submited Successfully", "success");
                        window.location.reload();
                        // backToRoute()
                    } else {
                        Swal.fire("Error!", response.data.message, "danger");
                    }
                }
            });
        }
        catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong!',
            })
        }
    };
    return (
        <>
            {viewComments ||
                <div style={{ height: "400px" }}>
                    <div className="mt-5">
                        <label className="rangeLabel">Select Number of Question</label>
                        <div class="slidecontainer" >
                            <input type="range"
                                min="1"
                                max="100"
                                className="range"
                                value={value}
                                onChange={handleChangeValue}
                            />
                            <div className="selectQuestion d-flex justify-content-center">{value} Question Selected</div>
                        </div>
                    </div>

                    <button className="rangeButton mt-5 d-flex justify-content-center" type="submit" onClick={submit}>Next</button>
                </div>
            }

            {viewComments &&
            <TodayQuiz count={value}/>

                // <div>
                //     <div className="row" >
                //         <div
                //             className="col-xl-9 col-lg-8 col-md-10 col-sm-10 mb-4 setMargin scrollbar scrollbar-primary"
                //         >
                //             {quiz.map((value, index) => (
                //                 <div className=" w-100 p-0  border-0 rounded-lg overflow-hidden mr-1  shadow-lg p-3 mb-5 rounded quizs force-overflow">
                //                     <Link to="/showUserProfile"><span><img src={dummy} alt="img" className='w30' /> </span></Link>
                //                     <span style={{ color: "#E84545" }}>Amelia Jeannet</span>
                //                     <div className='float-right'>Watch Explanation <img src={video} /> <img src={samllImg} /></div>
                //                     <div className=" position-relative d-block">
                //                         <div className="mt-2 mb-4">{value.title}</div>
                //                         {value.questionOptions.map((optn, index2) => (
                //                             <div>
                //                                 {value.questionType == 1 ? <p>
                //                                     <input
                //                                         type="radio"
                //                                         className=""
                //                                         id={"op" + optn.id}
                //                                         name={"radio-group" + value.id}
                //                                         onChange={ChangeQuestionOption}
                //                                         data-questionId={value.id}
                //                                         value={optn.id} />
                //                                     <label for={"op" + optn.id}>{optn.title}</label>
                //                                 </p> :
                //                                     <div>
                //                                         <label className="mt-1" >{optn.title}</label>
                //                                         <input
                //                                             type="checkbox"
                //                                             onChange={ChangeQuestionOption}
                //                                             data-questionId={value.id}
                //                                             value={optn.id}
                //                                             className="float-left checkbox"
                //                                             style={{ backgroundColor: "transparent", borderRadius: "3px", borderColor: "white", color: "#E84545", marginTop: "10px", marginRight: "7px" }}
                //                                         />
                //                                     </div>
                //                                 }
                //                             </div>
                //                         ))}
                //                         <div className="mt-3 mb-5">
                //                             <h5 className="text-dark text-grey-700 ">Submit Answer
                //                                 <img src={dimand} className="float-right" width="25px" />
                //                             </h5>
                //                         </div>
                //                     </div>


                //                     <div className='bg-darks'>
                //                         <div className='mt-2 pt-2 pl-2'>
                //                             <FiHeart fontSize="2em" className="mr-3" />
                //                             <img src={comments} width="25px" className="mr-3" />
                //                             <img src={share} width="25px" />
                //                             <img src={save} width="23px" className='float-right mr-3 ml-3' />
                //                             <img src={flag} width="25px" className='float-right' />
                //                         </div>

                //                         <div className="card-body p-3">

                //                             <h4 className="font-xss mt-2 ml-0 lh-28 mt-0 pb-3 border-bottom text-white" onClick={() => setViewComments(!viewComments)}>
                //                                 View 08 Comments
                //                             </h4>
                //                             Add Comments
                //                             <div className='float-right' style={{ color: "#E84545" }}> Send</div>
                //                         </div>
                //                         {viewComments &&
                //                             <div className="row">
                //                                 <div className="col-2 text-left pl-4 pt-3 m-2">
                //                                     <figure className="avatar float-left mb-0">
                //                                         <img
                //                                             src="https://statinfer.com/wp-content/uploads/dummy-user.png"
                //                                             alt="banner"
                //                                             className="float-right shadow-none w40 mr-2 rounded-circle"
                //                                         />
                //                                     </figure>
                //                                 </div>
                //                                 <div className="col-10 pt-2 comments">
                //                                     <div className="content">
                //                                         <b className="author-name font-xssss fw-600 mb-0 ">
                //                                             Goria Coast
                //                                         </b>
                //                                         <p className="comment-text lh-24 fw-500 font-xssss text-grey-500 ">
                //                                             Enjoyed this a lot and well done.
                //                                             <FiHeart className="user-like float-right" />
                //                                         </p>

                //                                     </div>
                //                                 </div>
                //                             </div>
                //                         }
                //                     </div>
                //                 </div>
                //             ))}
                //             <button className="submitBtn mt-4 mb-4" onClick={submitButton} type="button">Submit All Answer</button>
                //         </div>
                //     </div>

                // </div>
            }
        </>
    )
}

export default CustomizedQuiz