import React, { useState } from 'react';
import { BsFillTrophyFill } from "react-icons/bs"
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { Link } from 'react-router-dom';
import dummy from "../../../assests/dummy.png"
import { IoMdPersonAdd, IoMdAddCircleOutline } from "react-icons/io"
import trophy from "../../../assests/iCON/noun-trophy-5126269.png"
import { AiOutlineSearch } from "react-icons/ai"
import add from "../../../assests/iCON/noun-create-1409661.png"
import {
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    FormGroup,
    Input,
    Label,
    Collapse,
} from "reactstrap";
import { QuizFilter } from "./../../../quiz/QuizFilters"
import TodayTab from './tabs/TodayTab';
const FlashCardsMaterial = () => {

const [flashCard,setFlashCard]=useState(false)
    const FilterChange = (e) => {
        e.target.parentNode.childNodes.forEach(function (element) {
            if (e.target.checked == true) {
                element.classList.add("change-color")
            }
            else {
                element.classList.remove("change-color")
            }
        });
    }
    const createNewQuiz = () => {
        window.location.reload();
    }

    return (
        <>


            <Row className="quiz">
                <div className="col-md-10 col-sm-12">
                    
                    <Tabs
                        defaultActiveKey="today"
                        id="uncontrolled-tab-example"
                        className="mb-3 mt-3 "
                    >

                        <Tab eventKey="Left" title="Start From whereyou Left">
                            <div className='ml-4'>
                                <button className='create-posts '>Today</button>
                                &nbsp; &nbsp;
                                <button className='btn-red'>This Week</button>
                                &nbsp; &nbsp;
                                <button className='create-posts'>Past 4 Weeks</button>
                            </div>
                            <h5 className='text-ques mt-5 ml-5'>No. of Question: 0/10</h5>
                            <TodayTab />
                        </Tab>

                        <Tab eventKey="today" title="What's On today" >

                            <div className='ml-4'>
                                <button className='create-posts '>Today</button>
                                &nbsp; &nbsp;
                                <button className='btn-red'>This Week</button>
                                &nbsp; &nbsp;
                                <button className='create-posts'>Past 4 Weeks</button>
                            </div>
                            <h5 className='text-ques mt-5 ml-5'>No. of Question: 0/10</h5>
                            <TodayTab />
                        </Tab>
                        <Tab eventKey="Quiz" title="Custom Quiz" >
                            <div className='ml-4'>
                                <button className='create-posts '>Today</button>
                                &nbsp; &nbsp;
                                <button className='btn-red'>This Week</button>
                                &nbsp; &nbsp;
                                <button className='create-posts'>Past 4 Weeks</button>
                            </div>
                            <h5 className='text-ques mt-5 ml-5'>No. of Question: 0/10</h5>
                            <TodayTab />
                        </Tab>
                    </Tabs>

                </div>

            </Row>
        </>
    );
}


export default FlashCardsMaterial;
