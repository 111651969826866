import React from 'react'
const UserRegistration = () => {
  return (
   <>
   <div>
   <h3 className="mb-0 p-3 border-bottom bg-light fw-700 display1-size display2-md-size mb-3 ">User Register Details</h3>

   <h3 className='ml-5 mt-5'>PERSONAL INFORMATION</h3>
   <div className='row ml-4 mt-3'>
    <div className='col-md-4'>
      <input className='form-control bg-light'/>
    </div>
    <div className='col-md-2'></div>
    <div className='col-md-4'>
      <input className='form-control bg-light'/>
    </div>
    <div className='col-md-2'></div>
   </div>

   
   </div>
   </>
  )
}

export default UserRegistration