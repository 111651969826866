import React, { Component, Fragment } from 'react';
import Login from "../../../src/assets/images/login.png"
import {
  Row,
  Col,
  FormGroup,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button
} from "reactstrap";
import Logo from "../../../src/assets/images/Color logo_1.png";
import exeMed from "../../assets/images/Color logo with background.png"
import Swal from 'sweetalert2';
import facebookIcon from "../../../src/assets/iCON/facebook.png"
import googleIcon from "../../../src/assets/iCON/google.png"
import passwordIcon from "../../../src/assets/iCON/noun-view-password-4287676.png"
import { AuthenticationService } from '../services/AuthenticationServices';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { API_BASE_URL } from '../..';

const LoginForm = () => {
  const a = localStorage.getItem("Student_Info")
  const studentEmail = JSON.parse(a)
  const { register, handleSubmit, errors } = useForm();

  const [user, setUser] = useState()

  const [login, setLogin] = useState({
    email: "",
    password: ""
  })
  const [changePassword, setChangePassword] = useState({
    confirmPassword: "",
    newPassword: "",
  })
  const [modal1, setModal1] = useState(false);

  let history = useHistory();

  const backToRoute = () => {
    history.push("/quiz");
  };

  const loginSubmit = async () => {
    try {
      let response = await AuthenticationService.login(login.email, login.password)
      if (response.data.status == true) {
        localStorage.setItem("Student_Info", JSON.stringify(response.data.payload));
        if (response.data.payload.tempPassword != null) {
          setUser(response.data.payload);
          setModal1(!modal1);
        }
        else {
          backToRoute()
        }

      }
      else {
        Swal.fire({
          icon: 'error',
          title: 'Login Failed...',
          text: response.data.message
        })
      }
    }

    catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Login Failed...',
        text: "'Please fill the valid information'"
      })
    }
  }

  const changePasswordBtn = async () => {
    debugger
    try {
      if (changePassword.newPassword == changePassword.confirmPassword && changePassword.newPassword !="" && changePassword.confirmPassword!="" ) {
        let response = await AuthenticationService.changePassword(changePassword, user.email, user.tempPassword)
        if (response.data.status == true) {
          backToRoute()
        }
        else {
          Swal.fire({
            icon: 'error',
            title:"Failed",
            text: 'Change Passwrd Failed...',
            text: response.data.message
          })
        }
      }
      else {
        Swal.fire({
          icon: 'error',
          title:"Failed",
          text: 'Change Passwrd Failed...',
        })
      }
    }

    catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Failed...',
        text: "'Please fill the valid information'"
      })
    }
  }
  const CommingSoon = () => {
    Swal.fire('Coming Soon...')

  }
  const [passwordShown, setPasswordShown] = useState(false);
  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  return (
    <>

      <div className="main-wrap">
        <Modal
          isOpen={modal1}
          toggle={loginSubmit.bind(null)}
        >
          <ModalHeader toggle={loginSubmit.bind(null)}>Change Password</ModalHeader>
          <ModalBody>

            <Row>
              <Col md="4" className="mt-1">New Password</Col>
              <Col md="8">
                <FormGroup>
                  <input
                    type="password"
                    name="newPassword"
                    className='form-control-modal'
                    value={changePassword.newPassword}
                    onChange={(e) => { setChangePassword({ ...changePassword, newPassword: e.target.value }) }}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="4" className="mt-1">Confirm Password</Col>
              <Col md="8">
                <FormGroup>
                  <input
                    className='form-control-modal'
                    type="password"
                    name="confirmPassword"
                    value={changePassword.confirmPassword}
                    onChange={(e) => { setChangePassword({ ...changePassword, confirmPassword: e.target.value }) }}
                  />
                </FormGroup>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter className="d-flex justify-content-center">
            <Button color="danger" onClick={changePasswordBtn}>
              Submit
            </Button>
          </ModalFooter>

        </Modal>
        <div className="row">
          <div
            className="col-xl-6 col-sm-12 d-none d-xl-block p-0 vh-100 bg-image-cover bg-no-repeat"
          // style={{
          //   backgroundImage: `url("https://via.placeholder.com/800x950.png")`,
          // }}
          >
            <img src={Login}
              className=" w-50 float-right loginCard"
              style={{ marginRight: 80, marginTop: 15 }}
            />
          </div>

          <div className="col-xl-6 col-sm-12 vh-lg-100 align-items-center  bg-black rounded-lg overflow-hidden">
            <div className="card shadow-none border-0 ml-auto mr-auto login-card">
              <div class="container">
                <div class="row">
                  <div className="col-md-3"></div>
                  <div className="col-md-3 mt-5 mb-3">
                    <img
                      src={exeMed}
                      width="150px"
                    // style={{ marginRight: 80, marginTop: 15 }}
                    />
                    {/* <img 
                            src={BASE_URL + users.image}
                            alt='image'
                            width={90}
                          /> */}
                  </div>
                </div>
              </div>
              <div className="card-body rounded-0 text-left">
                <h4 className="display2-md-size mb-3 personal" >
                  Log in into
                  your account
                </h4>

                <form onSubmit={handleSubmit(loginSubmit)}>
                  <div className="form-group icon-input mb-3">
                    <i className="font-sm ti-email pr-0" style={{ color: "#E84545" }}></i>
                    <input
                      name='email'
                      value={login.email}
                      onChange={(e) => { setLogin({ ...login, email: e.target.value }) }}
                      type="text"
                      className="style2-input pl-5 form-control "
                      placeholder="Email Address"
                      required
                    />
                  </div>
                  <div className="form-group icon-input ">
                    <input
                      name='password'
                      value={login.password}
                      onChange={(e) => { setLogin({ ...login, password: e.target.value }) }}
                      type={passwordShown ? "text" : "password"}
                      className="style2-input pl-5 form-control font-xssss ls-3"
                      placeholder="Password"
                      required
                    />

                    <i className="font-sm ti-lock pr-0" style={{ color: "#E84545" }}></i>
                    {passwordShown ? <i className="password font-sm ti-eye " style={{ color: "#E84545" }} onClick={togglePassword}></i>
                      : <img src={passwordIcon} onClick={togglePassword} width="22px" style={{ marginTop: "-69px", marginLeft: "328px" }} />}
                    {passwordShown ? <a
                      className=" font-xssss float-right personal"
                      onClick={CommingSoon}>
                      Forgot Your Password
                    </a> :
                      <a
                        className=" font-xssss float-right personal"
                        onClick={CommingSoon}
                        style={{ marginTop: "-27px" }}>
                        Forgot Your Password
                      </a>
                    }

                  </div>
                  {/* <div className="form-check text-left mb-3">
                    <input
                      // type="checkbox"
                      className="form-check-input mt-2"
                      id="exampleCheck1"
                    />
                    <label
                      className="form-check-label font-xssss text-grey-500"
                      htmlFor="exampleCheck1"
                    >
                    Remember me
                    </label>

                  </div> */}
                  <div className="form-group mb-1 login">
                    <button
                      type="submit"
                      className="form-control text-center text-white fw-400  colors border-0 p-0 mt-3" >
                      Log in
                    </button>
                  </div>
                </form>
                <div className="col-sm-12 p-0 text-left">

                  <h6 className="text-grey-500 font-xssss fw-500 mt-0 mb-0 lh-32">
                    Don't have account?
                    <a className="fw-700 ml-1" onClick={CommingSoon} style={{ color: "#E84545" }}>
                      Register Now
                    </a>
                  </h6>
                </div>
                <div className="col-sm-12 p-0 text-center mt-2">
                  <h6 className="mb-0 personal fw-500 font-xssss  mb-3">
                    Or, Sign in with your social account
                  </h6>
                  <div className="form-group mb-1">
                    <a
                      onClick={CommingSoon}
                      className="form-control text-left style2-input text-white fw-400 bg-facebook border-0 p-0 mb-2 google"
                    >
                      <img
                        src={googleIcon}
                        alt="icon"
                        className="ml-2 w40 mb-1 mr-5"
                      />
                      Sign in with Google
                    </a>
                  </div>
                  <div className="form-group mb-1">
                    <a
                      onClick={CommingSoon}
                      className="form-control text-left style2-input text-white fw-400 bg-twiiter border-0 p-0 "
                    >
                      <img
                        src={facebookIcon}
                        alt="icon"
                        className="ml-2 w40 mb-1 mr-5"
                      />
                      Sign in with Facebook
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default LoginForm;
