
import axios from "axios";
import { API_BASE_URL } from '../../index';
import Swal from 'sweetalert2'


export const AuthenticationService = {
    login,
    changePassword
};
async function login(email, password) {
    return await axios.post(API_BASE_URL + "api/User/Login", {
        email: email,
        password: password,
    }) 
    
        
}

async function changePassword(object,email,oldPassword) {
    debugger
    return await axios.post(API_BASE_URL + "api/User/ChangeFirstTimeStudentPassword", {
        email: email,
        oldPassword:oldPassword,
        password: object.newPassword,
       
    })
}




