import axios from 'axios';
import { API_BASE_URL } from '../..';
class QuizServices {

  getAllQuizes(object) {
    let localStorageObject = localStorage.getItem("Student_Info");
    let Studentdata = JSON.parse(localStorageObject);
    return axios.post(API_BASE_URL + `api/Quiz/GetFilteredQuestions?SID=${Studentdata.id}&JWT=${Studentdata.jwt}`, object);
  }
  getQuizesByCount(count) {
    let localStorageObject = localStorage.getItem("Student_Info");
    let Studentdata = JSON.parse(localStorageObject);
    return axios.get(API_BASE_URL + `api/Quiz/GetQuestionsByCount?count=${count}`);
  }
  AddQuizQuestion(object) {
    let localStorageObject = localStorage.getItem("Student_Info");
    let Studentdata = JSON.parse(localStorageObject);
    return axios.post(API_BASE_URL + 'api/Quiz/AddQuizAnswers',object);
  }

 
}

export default new QuizServices();

