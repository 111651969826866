import React from 'react'
import { Row, Col, FormGroup, Input, Label } from "reactstrap"
import SideBar from '../SideBar'
import Header from '../../../components/Header'
const ChangePassword = () => {
    return (
        <>
            <Header />
            <div className='change-password pt-5' style={{ backgroundColor: "#181818", color: "white", height: "800px" }}>
                <Row>
                    <Col md="1"></Col>
                    <Col md="3">
                        <SideBar />
                    </Col>
                    <Col md="7">
                        <div className='quizs security'>
                            <Row >
                                <Col md="11">
                                    <FormGroup>
                                        <Label>Old Password </Label>
                                        <Input className='setting-inputField' type='password' />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label>New Password </Label>
                                        <Input className='setting-inputField' type='password' />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label>Confirm New Password </Label>
                                        <Input className='setting-inputField' type='password' />
                                        <p className='d-flex justify-content-end' style={{ color: "#72BB53", marginRight: "-33px" }}>Forget Password?</p>
                                    </FormGroup>
                                </Col>
                                <button className='change-password-btn'>Change Password</button>
                            </Row>
                        </div>
                    </Col>
                </Row>

            </div>
        </>
    )
}

export default ChangePassword