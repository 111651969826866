import React, { useState } from "react";
import { Row, Col, Card } from "reactstrap"
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Link } from "react-router-dom";
import { BsFillPlusCircleFill, BsCardImage } from "react-icons/bs"
import { FiYoutube } from "react-icons/fi"
import { AiOutlineAudio } from "react-icons/ai"
import Switch from "react-switch";
import Header from "../../components/Header";

const CreateQuiz = () => {
    const [premium, setPremium] = useState(false);
    const [worldwide, setWorlwide] = useState(false);
    const handlePremium = nextChecked => {
        setPremium(nextChecked);
    };
    const handleWorldwide = nextChecked => {
        setWorlwide(nextChecked);
    };
    return (
        <>
             <Header 
        divclassName="shadow-none pos-fixed position-absolute"
        color="light"
      />
            <div className="sub-menu">
                <div className="ml-5 mr-5" >
                    <h1>  Create New Quiz</h1>
                    <span><img src="https://cdn.pixabay.com/photo/2014/04/02/17/07/user-307993__340.png" alt="img" className='mt-2 ml-1 rounded-circle border' width="30px" /> </span>
                    <span className="mt-5 ml-2" style={{ color: "#E84545" }}>Amelia Jeannet</span>

                    <Row>
                        <h2>Target Audience</h2>
                        <Col md="12">
                            <select placeholder="Level" className="form-control" >
                                <option>Level </option>
                                <option>Level 1</option>
                                <option>Level 2</option>
                                <option>Level 3</option>
                                <option>Level 4</option>
                            </select>
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col md="4">
                            <select placeholder="Level" className="form-control" >
                                <option>Branch </option>
                                <option>Branch 1</option>
                                <option>Branch 2</option>
                                <option>Branch 3</option>
                                <option>Branch 4</option>
                            </select>
                        </Col>
                        <Col md="4">
                            <select placeholder="Chapter" className="form-control" >
                                <option>Chapter </option>
                                <option>Chapter 1</option>
                                <option>Chapter 2</option>
                                <option>Chapter 3</option>
                                <option>Chapter 4</option>
                            </select>
                        </Col>
                        <Col md="4">
                            <select placeholder="Level" className="form-control" >
                                <option>Sub-Chapter</option>
                                <option>Sub-Chapter 1</option>
                                <option>Sub-Chapter 2</option>
                                <option>Sub-Chapter 3</option>
                                <option>Sub-Chapter 4</option>
                            </select>
                        </Col>
                    </Row>

                    {/* <div className="mt-3">
                        <label>Free </label>
                        <Switch

                            onChange={handlePremium}
                            checked={premium}
                            uncheckedIcon={false}
                            checkedIcon={false}
                            onColor="#E84545"
                            height={15}
                            width={35}
                            className="mt-3 ml-3 mr-3"
                        />

                        <label style={{ color: "#E84545" }}>Premium </label>
                    </div> */}

                    <label class="options mb-3">
                        <input type="checkbox" />&nbsp;
                        I understand that published content can only qualify to premium if it is exclusively my work and does not infringe copyright. learn more on how to earn reward by publishing Premium content.
                    </label>
                    <div>
                        <label>Local </label>
                        <Switch

                            onChange={handleWorldwide}
                            checked={worldwide}
                            uncheckedIcon={false}
                            checkedIcon={false}
                            onColor="#E84545"
                            height={15}
                            width={35}
                            className="mt-3 ml-3 mr-3"
                        />

                        <label style={{ color: "#E84545" }}>Worldwide </label>

                    </div>
                    <label class="options mb-3">
                        <input type="checkbox" />&nbsp;
                        I understand that published content can only qualify to premium if it is exclusively my work and does not infringe copyright.
                    </label>
                    <form action="#">
                        <p>
                            <input type="radio" id="test1" name="radio-group" />
                            <label for="test1">Single Selection</label>
                        </p>

                        <p>
                            <input type="radio" id="test3" name="radio-group" />
                            <label for="test3">Multiple Selection</label>
                        </p>
                    </form>

                    <div className="ReactQuill">
                        <label>Add Question And Answer</label>
                        <button className="upload-btn float-right">Upload</button>
                        <ReactQuill theme="snow" placeholder="Add Question..." className="mb-2" />
                    </div>

                    <Row >
                        <label>Add Answer</label>
                        <input className="form-control" placeholder="Option A" />
                        <input
                            type="checkbox"
                            className="float-left checkbox"
                            style={{ backgroundColor: "transparent", borderRadius: "3px", borderColor: "white", color: "#E84545", marginTop: "7px", marginRight: "7px" }}
                        />
                        <label className="mt-1 " >This is correct Answer</label>
                       

                        <input className="form-control" placeholder="Option B" />
                        <input
                            type="checkbox"
                            className="float-left checkbox"
                            style={{ backgroundColor: "transparent", borderRadius: "3px", borderColor: "white", color: "#E84545", marginTop: "7px", marginRight: "7px" }}
                        />
                        <label className="mt-1 " >This is correct Answer</label>
                        <input className="form-control" placeholder="Option C" />
                        <input
                            type="checkbox"
                            className="float-left checkbox"
                            style={{ backgroundColor: "transparent", borderRadius: "3px", borderColor: "white", color: "#E84545", marginTop: "7px", marginRight: "7px" }}
                        />
                        <label className="mt-1 " >This is correct Answer</label>
                        <br></br>
                    </Row>
                    <button className="choice-btn ">Add Choice</button>
                    <div className="text-white mt-3 more-ques"><BsFillPlusCircleFill /> Add More Questions</div>
                    <div className="text-white mt-5 ">Watch Explanation<span className="text-muted">(optional)</span></div>
                    <Row>
                        <Col md="12">
                            <input className="form-control" placeholder="Type here" />
                        </Col>
                    </Row>
                    {premium &&
                        <Row className="mt-5 mb-3 d-flex justify-content-between">
                            <Col md="3" className="upload-quiz" >
                                <BsCardImage className="input-icons mb-2" fontSize="2.3em" color="white" />
                                <input type="file" accept="image/*" />
                            </Col>
                            <Col md="3" className="upload-quiz">
                                <FiYoutube className="input-icons mb-2" fontSize="2.3em" color="white" />
                                <input type="file" accept=" video/*" />
                            </Col>
                            <Col md="3" className="upload-quiz" >
                                <AiOutlineAudio className="input-icons mb-2" fontSize="2.3em" color="white" />
                                <input type="file" accept=" audio/*" />
                            </Col>
                        </Row>
                    }

                    <Row>
                        <Col md="10"></Col>
                        <Col md="2">
                            <button className="create-post-btn mb-5 ">Post</button>
                        </Col>
                    </Row>
                </div>

            </div>
        </>
    )
}
export default CreateQuiz