import React from 'react'
import VideoMaterials from '../videos/VideoMaterials'

const DocumentsMaterial = () => {
  return (
    <>
    <VideoMaterials/>
    </>
  )
}

export default DocumentsMaterial