import React from 'react'
import { Row, Col, FormGroup, Input, Label, Select } from "reactstrap"
import dummy from "../../../assests/dummy.png"
import { AiOutlineLike } from "react-icons/ai"
const Mange = () => {
    return (
        <>

<Col md="12">
                        <Row >
                            <Col md="4">
                                <FormGroup>
                                    <Label>Filter </Label>
                                    <select type='select' className="admin-setting-field">
                                         <option>Country</option>
                                    </select>
                                </FormGroup>
                            </Col>
                          
                        </Row>
                      
                        <Row>
                            <table className='content-creator-table' style={{width:"100%",fontSize:"15px"}}>
                                <tr>
                                    <th>User First Name</th>
                                    <th>User Name</th>
                                    <th>University</th>
                                    <th>Dismissed Prom Admin</th>
                                    <th>Action</th>
                                    
                                    
                                </tr>
                                <tr>
                                    <td>Alfreds Futterkiste</td>
                                    <td>Maria Anders</td>
                                    <td>Germany</td>
                                    <td>Alfreds Futterkiste</td>
                                    <td>Maria Anders</td>
                                </tr>
                             
                            </table>
                        </Row>
                    </Col>

           
        </>
    )
}

export default Mange