import React from 'react'
import { Row, Col } from "reactstrap"
import SideBar from './SideBar'
import Header from '../../components/Header'
import Security from './side-bar-menu/Security'
const ProfileSetting = () => {
    return (
        <>
            <Header />
            <div className='pt-5' style={{backgroundColor:"#181818", color:"white"}}>
                <Row>
                    <Col md="1"></Col>
                    <Col md="3">
                        <SideBar />
                    </Col>
                    <Col md="7">
                        <Security />
                    </Col>
                </Row>

            </div>
        </>
    )
}

export default ProfileSetting