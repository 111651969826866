import axios from 'axios';
import { API_BASE_URL } from '../..';

class AllFilterServices {

  getAllCountries() {
    let localStorageObject = localStorage.getItem("Student_Info");
    let Studentdata = JSON.parse(localStorageObject);
    return axios.get(API_BASE_URL + `api/Country/GetCountriesList?SID=${Studentdata.id}&JWT=${Studentdata.jwt}`);
  }
  getAllUniversities() {
    let localStorageObject = localStorage.getItem("Student_Info");
    let Studentdata = JSON.parse(localStorageObject);
    return axios.get(API_BASE_URL + `api/University/GetUniversities?SID=${Studentdata.id}&JWT=${Studentdata.jwt}`);
  }
  getAllUniversities() {
    let localStorageObject = localStorage.getItem("Student_Info");
    let Studentdata = JSON.parse(localStorageObject);
    return axios.get(API_BASE_URL + `api/University/GetUniversities?SID=${Studentdata.id}&JWT=${Studentdata.jwt}`);
  }
  getAllBranches() {
    let localStorageObject = localStorage.getItem("Student_Info");
    let Studentdata = JSON.parse(localStorageObject);
    return axios.get(API_BASE_URL + `api/Branch/GetAllBranches?SID=${Studentdata.id}&JWT=${Studentdata.jwt}`);
  }
  getAllChapters() {
    let localStorageObject = localStorage.getItem("Student_Info");
    let Studentdata = JSON.parse(localStorageObject);
    return axios.get(API_BASE_URL + `api/Chapater/GetAllChapters?SID=${Studentdata.id}&JWT=${Studentdata.jwt}`);
  }
  getAllSubchapter() {
    let localStorageObject = localStorage.getItem("Student_Info");
    let Studentdata = JSON.parse(localStorageObject);
    return axios.get(API_BASE_URL + `api/Chapater/GetAllSubChapters?SID=${Studentdata.id}&JWT=${Studentdata.jwt}`);
  }
  GetAllChaptersByBranchIds(object) {
    let localStorageObject = localStorage.getItem("Student_Info");
    let Studentdata = JSON.parse(localStorageObject);
    return axios.post(API_BASE_URL + `api/Chapater/GetAllChaptersByBranchIds?SID=${Studentdata.id}&JWT=${Studentdata.jwt}`, object)
  }
  GetAllSubChaptersByChapterIds(object) {
    let localStorageObject = localStorage.getItem("Student_Info");
    let Studentdata = JSON.parse(localStorageObject);
    return axios.post(API_BASE_URL + `api/Chapater/GetAllSubChaptersByChapterIds?SID=${Studentdata.id}&JWT=${Studentdata.jwt}`, object)
  }



}

export default new AllFilterServices();

