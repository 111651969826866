import React, { Component, Fragment } from 'react';
import Adminsidebar from '../components/Adminsidebar';
import AdminTopnav from '../components/AdminTopnav';
import Adminfooter from '../components/Adminfooter';
import Header from '../components/Header';
import sendIcon from "../view/assests/iCON/iCON/noun-send-4514372.png"
import smiley from "../view/assests/iCON/iCON/noun-smiley-2561875.png"
import { BsThreeDotsVertical } from "react-icons/bs"
import {
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  UncontrolledDropdown,
  ButtonDropdown,
} from 'reactstrap';
import { MdDelete, MdOutlineReportProblem } from "react-icons/md"
const chatList = [
  {
    imageUrl: 'user.png',
    name: 'Hurin Seary',
    email: 'support@gmail.com',
    status: '',
    des: 'Whats up how are you?',
    attach: '',
    time: '12:45 PM',
  },
  {
    imageUrl: 'user.png',
    name: 'Victor Exrixon',
    email: 'support@gmail.com',
    status: 'bg-success',
    des: 'Whats up how are you?',
    attach: '2',
    time: '1:13 PM',
  },
  {
    imageUrl: 'user.png',
    name: 'Surfiya Zakir',
    email: 'support@gmail.com',
    status: 'bg-success',
    des: 'Whats up how are you?',
    attach: '2',
    time: '2:45 PM',
  },
  {
    imageUrl: 'user.png',
    name: 'Goria Coast',
    email: 'support@gmail.com',
    status: 'bg-success',
    des: 'Whats up how are you?',
    attach: '2',
    time: '',
  },
  {
    imageUrl: 'user.png',
    name: 'Hurin Seary',
    email: 'support@gmail.com',
    status: '',
    des: 'Whats up how are you?',
    attach: '',
    time: '',
  },
  {
    imageUrl: 'user.png',
    name: 'Victor Exrixon',
    email: 'support@gmail.com',
    status: 'bg-success',
    des: 'Whats up how are you?',
    attach: '2',
    time: '1:13 PM',
  },
  {
    imageUrl: 'user.png',
    name: 'Surfiya Zakir',
    email: 'support@gmail.com',
    status: 'bg-success',
    des: 'Whats up how are you?',
    attach: '2',
    time: '2:45 PM',
  },
  {
    imageUrl: 'user.png',
    name: 'Goria Coast',
    email: 'support@gmail.com',
    status: 'bg-success',
    des: 'Whats up how are you?',
    attach: '2',
    time: '',
  },
  {
    imageUrl: 'user.png',
    name: 'Goria Coast',
    email: 'support@gmail.com',
    status: 'bg-success',
    des: 'Whats up how are you?',
    attach: '2',
    time: '',
  },
  {
    imageUrl: 'user.png',
    name: 'Hurin Seary',
    email: 'support@gmail.com',
    status: '',
    des: 'Whats up how are you?',
    attach: '',
    time: '',
  },
  {
    imageUrl: 'user.png',
    name: 'Goria Coast',
    email: 'support@gmail.com',
    status: 'bg-success',
    des: 'Whats up how are you?',
    attach: '2',
    time: '',
  },
  {
    imageUrl: 'user.png',
    name: 'Goria Coast',
    email: 'support@gmail.com',
    status: 'bg-success',
    des: 'Whats up how are you?',
    attach: '2',
    time: '',
  },
  {
    imageUrl: 'user.png',
    name: 'Hurin Seary',
    email: 'support@gmail.com',
    status: '',
    des: 'Whats up how are you?',
    attach: '',
    time: '',
  },
];

const Adminchat = () => {

  return (
    <>
      {/* <Adminsidebar /> */}

      {/* <div id="content-wrapper" className="d-flex flex-column">
            <div id="content"> */}
      {/* <AdminTopnav /> */}
      <Header />

      <div className="admin-chat">
        <div className="row ml-4 mr-4 pt-3">
          <div className='col-lg-6 col-xl-4 col-md-6  scrollbar scrollbar-primary'>
            <div className='force-overflow mr-3'>

              <div className="serch-input icon-input mb-3 d-flex justify-content-end" style={{ marginLeft: "13px", width: "377px" }}>
                <i className="font-sm ti-search  " style={{ color: "#E84545", fontWeight: "700" }}></i>
                <input
                  type="text"
                  className='form-control-search mt-3'
                  placeholder='Search'
                />
              </div>
              <div className="all-chat row">
                <div className="col-2 text-left pl-4  m-2">
                  <figure className="avatar float-left mb-0" style={{ marginLeft: "-18px" }}>
                    <img
                      src="https://statinfer.com/wp-content/uploads/dummy-user.png"
                      alt="banner"
                      className="float-right shadow-none w50 mr-2 rounded-circle"
                    />
                  </figure>
                </div>

                <div className="col-10 comments">
                  <div className="content">
                    <b className="author-name fw-600 mb-0 ">
                      Goria Coast
                    </b>
                    <p className="comment-text lh-24 fw-500 font-xssss text-grey-500 ">
                      Enjoyed this a lot and well done.
                    </p>
                  </div>
                </div>
                <UncontrolledDropdown className="ml-2" >
                  <DropdownToggle caret style={{ margin: "-15px", backgroundColor: "transparent", border: "none", marginTop: "-4px" }} >
                    <BsThreeDotsVertical fontSize="1.5rem" color="white" style={{ marginTop: "22px" }} />
                  </DropdownToggle>
                  <DropdownMenu style={{ top: "-9px", left: "40px", backgroundColor: "#181818", marginLeft: "200px" }}>
                    <DropdownItem style={{ color: "white", fontWeight: "200", margin: "-13px" }}><MdDelete fontSize="1.4rem" className='mt-1' />Delete<br /><div style={{ fontSize: "9px", marginLeft: "23px", marginTop: "-4px" }}>Delete this Chat?</div></DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem style={{ color: "white", fontWeight: "200", margin: "-13px" }}><MdOutlineReportProblem fontSize="1.4rem" className='mt-1' />Report<br /><div style={{ fontSize: "9px", marginLeft: "23px", marginTop: "-4px" }}>Are you sure  you wanted to report this?</div></DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>
              <div className="all-chat row">
                <div className="col-2 text-left pl-4  m-2">
                  <figure className="avatar float-left mb-0" style={{ marginLeft: "-18px" }}>
                    <img
                      src="https://statinfer.com/wp-content/uploads/dummy-user.png"
                      alt="banner"
                      className="float-right shadow-none w50 mr-2 rounded-circle"
                    />
                  </figure>
                </div>
                <div className="col-10 comments">
                  <div className="content">
                    <b className="author-name fw-600 mb-0 ">
                      Goria Coast
                    </b>
                    <p className="comment-text lh-24 fw-500 font-xssss text-grey-500 ">
                      Enjoyed this a lot and well done.
                    </p>
                  </div>
                </div>
                <UncontrolledDropdown className="ml-2" >
                  <DropdownToggle caret style={{ margin: "-15px", backgroundColor: "transparent", border: "none", marginTop: "-4px" }} >
                    <BsThreeDotsVertical fontSize="1.5rem" color="white" style={{ marginTop: "22px" }} />
                  </DropdownToggle>
                  <DropdownMenu style={{ top: "-9px", left: "40px", backgroundColor: "#181818", marginLeft: "200px" }}>
                    <DropdownItem style={{ color: "white", fontWeight: "200", margin: "-13px" }}><MdDelete fontSize="1.4rem" className='mt-1' />Delete<br /><div style={{ fontSize: "9px", marginLeft: "23px", marginTop: "-4px" }}>Delete this Chat?</div></DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem style={{ color: "white", fontWeight: "200", margin: "-13px" }}><MdOutlineReportProblem fontSize="1.4rem" className='mt-1' />Report<br /><div style={{ fontSize: "9px", marginLeft: "23px", marginTop: "-4px" }}>Are you sure  you wanted to report this?</div></DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
           </div>
              <div className="all-chat row">
                <div className="col-2 text-left pl-4  m-2">
                  <figure className="avatar float-left mb-0" style={{ marginLeft: "-18px" }}>
                    <img
                      src="https://statinfer.com/wp-content/uploads/dummy-user.png"
                      alt="banner"
                      className="float-right shadow-none w50 mr-2 rounded-circle"
                    />
                  </figure>
                </div>
                <div className="col-10 comments">
                  <div className="content">
                    <b className="author-name fw-600 mb-0 ">
                      Goria Coast
                    </b>
                    <p className="comment-text lh-24 fw-500 font-xssss text-grey-500 ">
                      Enjoyed this a lot and well done.
                    </p>
                  </div>
                </div>
                <UncontrolledDropdown className="ml-2" >
                  <DropdownToggle caret style={{ margin: "-15px", backgroundColor: "transparent", border: "none", marginTop: "-4px" }} >
                    <BsThreeDotsVertical fontSize="1.5rem" color="white" style={{ marginTop: "22px" }} />
                  </DropdownToggle>
                  <DropdownMenu style={{ top: "-9px", left: "40px", backgroundColor: "#181818", marginLeft: "200px" }}>
                    <DropdownItem style={{ color: "white", fontWeight: "200", margin: "-13px" }}><MdDelete fontSize="1.4rem" className='mt-1' />Delete<br /><div style={{ fontSize: "9px", marginLeft: "23px", marginTop: "-4px" }}>Delete this Chat?</div></DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem style={{ color: "white", fontWeight: "200", margin: "-13px" }}><MdOutlineReportProblem fontSize="1.4rem" className='mt-1' />Report<br /><div style={{ fontSize: "9px", marginLeft: "23px", marginTop: "-4px" }}>Are you sure  you wanted to report this?</div></DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>
              <div className="all-chat row">
                <div className="col-2 text-left pl-4  m-2">
                  <figure className="avatar float-left mb-0" style={{ marginLeft: "-18px" }}>
                    <img
                      src="https://statinfer.com/wp-content/uploads/dummy-user.png"
                      alt="banner"
                      className="float-right shadow-none w50 mr-2 rounded-circle"
                    />
                  </figure>
                </div>
                <div className="col-10 comments">
                  <div className="content">
                    <b className="author-name fw-600 mb-0 ">
                      Goria Coast
                    </b>
                    <p className="comment-text lh-24 fw-500 font-xssss text-grey-500 ">
                      Enjoyed this a lot and well done.
                    </p>
                  </div>
                </div>
 <UncontrolledDropdown className="ml-2" >
                  <DropdownToggle caret style={{ margin: "-15px", backgroundColor: "transparent", border: "none", marginTop: "-4px" }} >
                    <BsThreeDotsVertical fontSize="1.5rem" color="white" style={{ marginTop: "22px" }} />
                  </DropdownToggle>
                  <DropdownMenu style={{ top: "-9px", left: "40px", backgroundColor: "#181818", marginLeft: "200px" }}>
                    <DropdownItem style={{ color: "white", fontWeight: "200", margin: "-13px" }}><MdDelete fontSize="1.4rem" className='mt-1' />Delete<br /><div style={{ fontSize: "9px", marginLeft: "23px", marginTop: "-4px" }}>Delete this Chat?</div></DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem style={{ color: "white", fontWeight: "200", margin: "-13px" }}><MdOutlineReportProblem fontSize="1.4rem" className='mt-1' />Report<br /><div style={{ fontSize: "9px", marginLeft: "23px", marginTop: "-4px" }}>Are you sure  you wanted to report this?</div></DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>              </div>
              <div className="all-chat row">
                <div className="col-2 text-left pl-4  m-2">
                  <figure className="avatar float-left mb-0" style={{ marginLeft: "-18px" }}>
                    <img
                      src="https://statinfer.com/wp-content/uploads/dummy-user.png"
                      alt="banner"
                      className="float-right shadow-none w50 mr-2 rounded-circle"
                    />
                  </figure>
                </div>
                <div className="col-10 comments">
                  <div className="content">
                    <b className="author-name fw-600 mb-0 ">
                      Goria Coast
                    </b>
                    <p className="comment-text lh-24 fw-500 font-xssss text-grey-500 ">
                      Enjoyed this a lot and well done.
                    </p>
                  </div>
                </div>
 <UncontrolledDropdown className="ml-2" >
                  <DropdownToggle caret style={{ margin: "-15px", backgroundColor: "transparent", border: "none", marginTop: "-4px" }} >
                    <BsThreeDotsVertical fontSize="1.5rem" color="white" style={{ marginTop: "22px" }} />
                  </DropdownToggle>
                  <DropdownMenu style={{ top: "-9px", left: "40px", backgroundColor: "#181818", marginLeft: "200px" }}>
                    <DropdownItem style={{ color: "white", fontWeight: "200", margin: "-13px" }}><MdDelete fontSize="1.4rem" className='mt-1' />Delete<br /><div style={{ fontSize: "9px", marginLeft: "23px", marginTop: "-4px" }}>Delete this Chat?</div></DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem style={{ color: "white", fontWeight: "200", margin: "-13px" }}><MdOutlineReportProblem fontSize="1.4rem" className='mt-1' />Report<br /><div style={{ fontSize: "9px", marginLeft: "23px", marginTop: "-4px" }}>Are you sure  you wanted to report this?</div></DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>              </div>
              <div className="all-chat row">
                <div className="col-2 text-left pl-4  m-2">
                  <figure className="avatar float-left mb-0" style={{ marginLeft: "-18px" }}>
                    <img
                      src="https://statinfer.com/wp-content/uploads/dummy-user.png"
                      alt="banner"
                      className="float-right shadow-none w50 mr-2 rounded-circle"
                    />
                  </figure>
                </div>
                <div className="col-10 comments">
                  <div className="content">
                    <b className="author-name fw-600 mb-0 ">
                      Goria Coast
                    </b>
                    <p className="comment-text lh-24 fw-500 font-xssss text-grey-500 ">
                      Enjoyed this a lot and well done.
                    </p>
                  </div>
                </div>
 <UncontrolledDropdown className="ml-2" >
                  <DropdownToggle caret style={{ margin: "-15px", backgroundColor: "transparent", border: "none", marginTop: "-4px" }} >
                    <BsThreeDotsVertical fontSize="1.5rem" color="white" style={{ marginTop: "22px" }} />
                  </DropdownToggle>
                  <DropdownMenu style={{ top: "-9px", left: "40px", backgroundColor: "#181818", marginLeft: "200px" }}>
                    <DropdownItem style={{ color: "white", fontWeight: "200", margin: "-13px" }}><MdDelete fontSize="1.4rem" className='mt-1' />Delete<br /><div style={{ fontSize: "9px", marginLeft: "23px", marginTop: "-4px" }}>Delete this Chat?</div></DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem style={{ color: "white", fontWeight: "200", margin: "-13px" }}><MdOutlineReportProblem fontSize="1.4rem" className='mt-1' />Report<br /><div style={{ fontSize: "9px", marginLeft: "23px", marginTop: "-4px" }}>Are you sure  you wanted to report this?</div></DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>              </div>
              <div className="all-chat row">
                <div className="col-2 text-left pl-4  m-2">
                  <figure className="avatar float-left mb-0" style={{ marginLeft: "-18px" }}>
                    <img
                      src="https://statinfer.com/wp-content/uploads/dummy-user.png"
                      alt="banner"
                      className="float-right shadow-none w50 mr-2 rounded-circle"
                    />
                  </figure>
                </div>
                <div className="col-10 comments">
                  <div className="content">
                    <b className="author-name fw-600 mb-0 ">
                      Goria Coast
                    </b>
                    <p className="comment-text lh-24 fw-500 font-xssss text-grey-500 ">
                      Enjoyed this a lot and well done.
                    </p>
                  </div>
                </div>
 <UncontrolledDropdown className="ml-2" >
                  <DropdownToggle caret style={{ margin: "-15px", backgroundColor: "transparent", border: "none", marginTop: "-4px" }} >
                    <BsThreeDotsVertical fontSize="1.5rem" color="white" style={{ marginTop: "22px" }} />
                  </DropdownToggle>
                  <DropdownMenu style={{ top: "-9px", left: "40px", backgroundColor: "#181818", marginLeft: "200px" }}>
                    <DropdownItem style={{ color: "white", fontWeight: "200", margin: "-13px" }}><MdDelete fontSize="1.4rem" className='mt-1' />Delete<br /><div style={{ fontSize: "9px", marginLeft: "23px", marginTop: "-4px" }}>Delete this Chat?</div></DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem style={{ color: "white", fontWeight: "200", margin: "-13px" }}><MdOutlineReportProblem fontSize="1.4rem" className='mt-1' />Report<br /><div style={{ fontSize: "9px", marginLeft: "23px", marginTop: "-4px" }}>Are you sure  you wanted to report this?</div></DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>              </div>
              <div className="all-chat row">
                <div className="col-2 text-left pl-4  m-2">
                  <figure className="avatar float-left mb-0" style={{ marginLeft: "-18px" }}>
                    <img
                      src="https://statinfer.com/wp-content/uploads/dummy-user.png"
                      alt="banner"
                      className="float-right shadow-none w50 mr-2 rounded-circle"
                    />
                  </figure>
                </div>
                <div className="col-10 comments">
                  <div className="content">
                    <b className="author-name fw-600 mb-0 ">
                      Goria Coast
                    </b>
                    <p className="comment-text lh-24 fw-500 font-xssss text-grey-500 ">
                      Enjoyed this a lot and well done.
                    </p>
                  </div>
                </div>
 <UncontrolledDropdown className="ml-2" >
                  <DropdownToggle caret style={{ margin: "-15px", backgroundColor: "transparent", border: "none", marginTop: "-4px" }} >
                    <BsThreeDotsVertical fontSize="1.5rem" color="white" style={{ marginTop: "22px" }} />
                  </DropdownToggle>
                  <DropdownMenu style={{ top: "-9px", left: "40px", backgroundColor: "#181818", marginLeft: "200px" }}>
                    <DropdownItem style={{ color: "white", fontWeight: "200", margin: "-13px" }}><MdDelete fontSize="1.4rem" className='mt-1' />Delete<br /><div style={{ fontSize: "9px", marginLeft: "23px", marginTop: "-4px" }}>Delete this Chat?</div></DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem style={{ color: "white", fontWeight: "200", margin: "-13px" }}><MdOutlineReportProblem fontSize="1.4rem" className='mt-1' />Report<br /><div style={{ fontSize: "9px", marginLeft: "23px", marginTop: "-4px" }}>Are you sure  you wanted to report this?</div></DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>              </div>
            </div>

          </div>
          {/* <div className="col-lg-6 col-xl-4 col-md-6 chat-left scroll-bar border-right-light pl-4 pr-4 bg-white rounded-10 theme-dark-bg">
                    <form action="#" className="mt-0 pl-3 pt-3">
                      <div className="search-form">
                        <i className="ti-search font-xs"></i>
                        <input
                          type="text"
                          className="form-control text-grey-500 mb-0 bg-greylight border-0"
                          placeholder="Search here."
                        />
                      </div>
                    </form>
                    <div className="section full mt-2 mb-2 pl-3">
                      <ul className="list-group list-group-flush">
                        {chatList.map((value, index) => (
                          <li
                            key={index}
                            className="list-group-item no-icon pl-0 mt-4"
                          >
                            <figure className="avatar float-left mb-0 mr-3">
                              <img
                                src={`assets/images/${value.imageUrl}`}
                                alt="avater"
                                className="w45 rounded-circle"
                              />
                            </figure>
                            <h3 className="fw-600 mb-0 mt-1">
                              <a
                                className="font-xsss fw-700 text-grey-900 text-dark d-block"
                                href="/admin-chat"
                              >
                                {value.name}
                              </a>
                            </h3>
                            <span className="d-block fw-500 font-xssss">
                              {value.des}
                            </span>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div> */}
          <div className="col-lg-6 col-xl-8 col-md-6 pl-0 pr-0 d-none d-lg-block d-md-block rounded-10 " style={{ backgroundColor: "#181818" }}>
            <div className="chat-wrapper pt-0 w-100 position-relative scroll-bar theme-dark-bg" style={{ backgroundColor: "#181818" }}>
              <div className="chat-body pl-4">
                <div className="messages-content">
                  <div className="message-item">
                    <div className="message-user">
                      <figure className="avatar">
                        <img
                          src="assets/images/user.png"
                          alt="avater"
                        />
                      </figure>
                      <div>
                        <h5 className='text-white'>Byrom Guittet</h5>
                        <div className="time">01:35 PM</div>
                      </div>
                    </div>
                    <div className="message-wrap" style={{ backgroundColor: "#FFFFFF" }}>
                      I'm fine, how are you.
                    </div>
                  </div>

                  <div className="message-item outgoing-message">
                    <div className="message-user">
                      <figure className="avatar">
                        <img
                          src="assets/images/user.png"
                          alt="avater"
                        />
                      </figure>
                      <div>
                        <h5 className='text-white'>Byrom Guittet</h5>
                        <div className="time">
                          01:35 PM
                        </div>
                      </div>
                    </div>
                    <div className="message-wrap" style={{ backgroundColor: "#E84545" }}>
                      I want those files for you. I want you to send 1
                      PDF and 1 image file.
                    </div>
                  </div>

                  <div className="message-item">
                    <div className="message-user">
                      <figure className="avatar">
                        <img
                          src="assets/images/user.png"
                          alt="avater"
                        />
                      </figure>
                      <div>
                        <h5 className='text-white'>Byrom Guittet</h5>
                        <div className="time">01:35 PM</div>
                      </div>
                    </div>
                    <div className="message-wrap">
                      I've found some cool photos for our travel app.
                    </div>
                  </div>

                  <div className="message-item outgoing-message">
                    <div className="message-user">
                      <figure className="avatar">
                        <img
                          src="assets/images/user.png"
                          alt="avater"
                        />
                      </figure>
                      <div>
                        <h5 className='text-white'>Byrom Guittet</h5>
                        <div className="time">
                          01:35 PM
                        </div>
                      </div>
                    </div>
                    <div className="message-wrap" style={{ backgroundColor: "#E84545" }}>
                      Hey mate! How are things going ?
                    </div>
                  </div>

                  <div className="message-item">
                    <div className="message-user">
                      <figure className="avatar">
                        <img
                          src="assets/images/user.png"
                          alt="avater"
                        />
                      </figure>
                      <div>
                        <h5 className='text-white'>Byrom Guittet</h5>
                        <div className="time">01:35 PM</div>
                      </div>
                    </div>
                    <figure>
                      <img
                        src="https://via.placeholder.com/270x150.png"
                        className="w-100 img-fluid rounded-lg"
                        alt="avater"
                      />
                    </figure>
                  </div>

                  <div className="message-item outgoing-message">
                    <div className="message-user">
                      <figure className="avatar">
                        <img
                          src="assets/images/user.png"
                          alt="avater"
                        />
                      </figure>
                      <div>
                        <h5 className='text-white'>Byrom Guittet</h5>
                        <div className="time">
                          01:35 PM
                        </div>
                      </div>
                    </div>
                    <div
                      className="message-wrap"
                      style={{ marginBottom: '90px', backgroundColor: "#E84545" }}
                    >
                      Hey mate! How are things going ?
                    </div>
                  </div>
                  <div className="clearfix"></div>
                </div>
              </div>
            </div>
            <div
              className="chat-bottom dark-bg p-3 shadow-none theme-dark-bg"
              style={{ width: '98%', backgroundColor: "transparent" }}
            >
              <form className="chat-form" style={{ marginLeft: "20px" }}>
                <div className="float-left">
                  <img src={smiley} style={{ width: "30px", margin: "4px" }} />
                </div>
                <div className="form-group">
                  <input type="text" placeholder="Start typing.." />
                </div>
                <button className="sendBtn" style={{ width: "50px" }}>
                  <img src={sendIcon} width="30px" />
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>


    </>
  );

}

export default Adminchat;