import React, { Component, Fragment } from "react";
import singup from "../../../src/assets/images/SignUp.png";
class SignUpForm extends Component {
  render() {
    return (
      <Fragment>
        <div className="main-wrap">
          <div className="row">
            <div
              className="col-xl-5 d-none d-xl-block p-0 vh-100 bg-image-cover bg-no-repeat"

              // style={{
              //   backgroundImage:singup,
              // }}
            >
              <img
                src={singup}
                className=" w-50 float-right picture"
                style={{ marginRight: 80, marginTop: 15 }}
              />
            </div>
            <div className="col-xl-7 vh-100 align-items-center rounded-lg overflow-hidden ">
              <div
                className="card shadow-none border-0 ml-auto mr-auto login-card color"
                style={{ maxWidth: "unset" }}
              >
                <div className="card-body rounded-0 text-left">
                  <h2 class="d-flex flex-row justify-content-center align-items-center mb-3 personal">
                    User Registration Details
                  </h2>
                  <div class="container">
                    <div class="row">
                      <div class="d-flex flex-row justify-content-center align-items-center mb-3">
                        <div className="col-md-3 pic">
                          <img
                            src="https://www.pngitem.com/pimgs/m/581-5813504_avatar-dummy-png-transparent-png.png"
                            className="w-50 h-95 float-right"
                            style={{ marginRight: 80, marginTop: 15 }}
                          />
                          {/* <img 
                              src={BASE_URL + users.image}
                              alt='image'
                              width={90}
                            /> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <h4 className=" pl-4 mb-2 personal">Personal Information</h4>
                  <form>
                    <div class="container">
                      <div class="row">
                        <div class="col-6">
                          <div className="form-group icon-input mb-3">
                            <i className="font-sm ti-email text-danger  pr-0"></i>
                            <input
                              type="text"
                              className="style2-input pl-5 form-control"
                              // placeholder="Email Address"
                            />
                            {/* text-grey-900 font-xsss fw-600 */}
                          </div>
                        </div>
                        <div class="col-6 ">
                          <div className="form-group icon-input mb-3">
                            <i className="font-sm ti-lock text-danger pr-0"></i>
                            <input
                              type="text"
                              className="style2-input pl-5 form-control  "
                              // placeholder="Password"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="container">
                      <div class="row">
                        <div class="col-6">
                          <div className="form-group icon-input mb-3">
                            <i className="font-sm ti-user text-danger pr-0"></i>
                            <input
                              type="text"
                              className="style2-input pl-5 form-control "
                              // placeholder="First Name"
                            />
                          </div>
                        </div>
                        <div class="col-6">
                          <div className="form-group icon-input mb-3">
                            <i className="font-sm ti-user text-grey-500 pr-0"></i>
                            <input
                              type="text"
                              className="style2-input pl-5 form-control "
                              // placeholder="Last Name"
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="container">
                      <div class="row">
                        <div class="col-6">
                          <div className="form-group icon-input mb-3">
                            <i className="font-sm ti-css3 text-danger pr-0"></i>
                            <input
                              type="text"
                              className="style2-input pl-5 form-control "
                              // placeholder="Paseudo Name"
                            />
                          </div>
                        </div>
                        <div class="col-6">
                          <div className="form-group icon-input mb-3">
                            <i className="font-sm ti-world text-danger pr-0"></i>
                            <input
                              type="text"
                              className="style2-input pl-5 form-control "
                              // placeholder="Country"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="container">
                      <div class="row">
                        <div class="col-6">
                          <div className="form-group icon-input mb-3">
                            <i className="font-sm ti-basketball text-danger pr-0"></i>
                            <input
                              type="text"
                              className="style2-input pl-5 form-control "
                              // placeholder="Language"
                            />
                          </div>
                        </div>
                        <div class="col-6">
                          <div className="form-group icon-input mb-3">
                            <i className="font-sm ti-layout-tab-window text-danger pr-0"></i>
                            <input
                              type="text"
                              className="style2-input pl-5 form-control "
                              // placeholder="University/Hospital ID"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="form-check text-left mb-3">
                      <h6 className="text-grey-500 text-right font-xssss fw-500 mt-0 mb-0 lh-32">
                        Already have account{" "}
                        <a href="/login" className="fw-700 ml-1 text-danger">
                          Log in
                        </a>
                      </h6>
                    </div>
                  </form>

                  <div className="col-sm-12 p-0 text-center">
                    <div className="form-group mb-1 text-center">
                      <a
                        href="/register"
                        className="form-control text-center style2-input text-white fw-600 bg-dark border-0 p-0 layout "
                      >
                        Next
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default SignUpForm;
