import React from 'react'
import { Row, Col } from "reactstrap"
import dummy from "../assests/dummy.png"
import { Link } from 'react-router-dom'
import {AllSocialFilters} from "./socialFilers/AllSocialFilters"
import Header from '../../components/Header'
const SearchSocial = () => {
    return (
        <>
        <Header/>
            <Row className="quiz">
                <AllSocialFilters />
                <div className="col-md-8 col-sm-12 ">
                    <Row className='mt-2'>
                        <Col md="10" sm="5">
                            <div className="serch-input icon-input mb-3 d-flex justify-content-end">
                                <i className="font-sm ti-search pr-0 " style={{ color: "#E84545" }}></i>
                                <input
                                    type="text"
                                    className='form-control-search mt-3'
                                    placeholder='Search'
                                />
                            </div>
                            <div className='quizs mt-5 social-search scrollbar scrollbar-primary'>
                                <p className='react-seacrh-txt'>Recent Search</p>
                            <ul className='social-search-list force-overflow'>
                                <li> <img src={dummy} alt="img" className='m-2 mt-3' style={{ width: "50px", height: "50px" }} />Amelia Jeannet<p>Ameiii</p></li>
                                <li> <img src={dummy} alt="img" className='m-2 mt-3' style={{ width: "50px", height: "50px" }} />Amelia Jeannet<p>Ameiii</p></li>
                                <li> <img src={dummy} alt="img" className='m-2 mt-3' style={{ width: "50px", height: "50px" }} />Amelia Jeannet<p>Ameiii</p></li>
                                <li> <img src={dummy} alt="img" className='m-2 mt-3' style={{ width: "50px", height: "50px" }} />Amelia Jeannet<p>Ameiii</p></li>
                                <li> <img src={dummy} alt="img" className='m-2 mt-3' style={{ width: "50px", height: "50px" }} />Amelia Jeannet<p>Ameiii</p></li>
                                <li> <img src={dummy} alt="img" className='m-2 mt-3' style={{ width: "50px", height: "50px" }} />Amelia Jeannet<p>Ameiii</p></li>
                                <li> <img src={dummy} alt="img" className='m-2 mt-3' style={{ width: "50px", height: "50px" }} />Amelia Jeannet<p>Ameiii</p></li>
                                <li> <img src={dummy} alt="img" className='m-2 mt-3' style={{ width: "50px", height: "50px" }} />Amelia Jeannet<p>Ameiii</p></li>
                                <li> <img src={dummy} alt="img" className='m-2 mt-3' style={{ width: "50px", height: "50px" }} />Amelia Jeannet<p>Ameiii</p></li>
                                <li> <img src={dummy} alt="img" className='m-2 mt-3' style={{ width: "50px", height: "50px" }} />Amelia Jeannet<p>Ameiii</p></li>
                                <li> <img src={dummy} alt="img" className='m-2 mt-3' style={{ width: "50px", height: "50px" }} />Amelia Jeannet<p>Ameiii</p></li>
                                <li> <img src={dummy} alt="img" className='m-2 mt-3' style={{ width: "50px", height: "50px" }} />Amelia Jeannet<p>Ameiii</p></li>
                            
                            </ul>
                        </div>
                        </Col>
                       
                    </Row>
                </div>

            </Row>
        </>
    )
}

export default SearchSocial
