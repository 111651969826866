import React, { Component, Fragment } from 'react';

// import Adminsidebar from '../../components/Adminsidebar';
// import AdminTopnav from '../../components/AdminTopnav';
// import Adminfooter from '../../components/Adminfooter';

class Adminproductadd extends Component {
  render() {
    return (
      <Fragment>
        {/* <div id="wrapper"> */}
        {/* <Adminsidebar /> */}

        <div id="content-wrapper" className="d-flex flex-column" style={{ paddingLeft: "0px" }}>
          <div id="content">
            {/* <AdminTopnav /> */}

            <div className="container px-3 py-4">
              {/* <div className="row">
                  <div className="col-lg-12 d-flex mb-2">
                    <h2 className="text-grey-900 font-md fw-700 ml-4">Create Post</h2>
                  </div>
                </div> */}
              <div className="row">
                <div className="col-lg-12 ">
                  <div className="card border-0 px-4 pt-4 mt-2 rounded-lg admin-form">
                    <div className="card-body d-block">
                      <h4 className="font-xss text-grey-800 mb-4 mt-0 fw-700">
                        Upload video
                      </h4>
                      <input type="file" />
                      {/* <div className="form-group mb30">
                        <div
                          className="file-upload-wrapper"
                          data-text="Select your file!"
                        >
                          <input
                            name="file-upload-field"
                            type="file"
                            className="file-upload-field"
                          />
                          <label
                            htmlFor="file"
                            className="rounded-3 text-center bg-white btn-tertiary js-labelFile p-4 w-100 border-dashed theme-black-bg"
                          >
                            <i className="ti-cloud-down large-icon me-3 d-block"></i>
                            <span className="js-fileName">
                              Drag and drop or click to replace
                            </span>
                          </label>
                        </div>
                      </div> */}
                    </div>
                  </div>
                  <div className="card border-0 px-4 pt-4 mt-2 rounded-lg admin-form">
                    <div className="card-body d-block">

                      <form
                        className="contact_form"
                        name="contact_form"
                        action="#"
                        method="post"
                        noValidate=""
                      >
                        <div className="row">


                          <div className="col-sm-12">
                            <div className="form-group">
                              <label
                                htmlFor="product_sku"
                                className="form-label"
                              >
                                Description
                              </label>
                              <textarea
                                name="form_message"
                                className="form-control h150"
                                rows="6"
                                placeholder="Post Description *"
                              ></textarea>
                            </div>
                          </div>


                        </div>

                      </form>
                    </div>
                  </div>
                  <button className='btn btn-primary mb-5 d-flex justify-content-center add-post'>ADD POST</button>

                </div>
              </div>

            </div>

            {/* <Adminfooter /> */}
          </div>
        </div>
        {/* </div> */}
      </Fragment>
    );
  }
}

export default Adminproductadd;
