import React, { Component, Fragment } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  FormGroup,
  Input,
  Label,
  Collapse,
} from "reactstrap";
import singup from "../../../src/assets/images/SignUp.png";

class UsersRegistration extends Component {
  render() {
    return (
      <Fragment>
        <div className="main-wrap">
          <div className="row">
            <div
              className="col-xl-5 d-none d-xl-block p-0 vh-100 bg-image-cover bg-no-repeat"

              // style={{
              //   backgroundImage:singup,
              // }}
            >
              <img
                src={singup}
                className=" w-50 float-right picture"
                style={{ marginRight: 80, marginTop: 15 }}
              />
            </div>
            <div className="col-xl-7 vh-100 align-items-center  overflow-hidden ">
              <div
                className="card shadow-none border-0 ml-auto mr-auto login-card color"
                style={{ maxWidth: "unset" }}
              >
                <div className="card-body rounded-0 text-left">
                  <h2 class="d-flex flex-row justify-content-center align-items-center mb-3 personal">
                    User Registration Details
                  </h2>

                  <h4 className=" pl-4 mb-2 personal">IID Information</h4>
                  <Row>
                  <div class="container">
                      <div class="row">
                        <div class="col-6">
                          <div className="form-group icon-input mb-3">
                            <i className="font-sm ti-home text-danger  pr-0"></i>
                            <input
                              type="text"
                              className="style2-input pl-5 form-control"
                              // placeholder="Email Address"
                            />
                            {/* text-grey-900 font-xsss fw-600 */}
                          </div>
                        </div>
                        <div class="col-6 ">
                          <div className="form-group icon-input mb-3">
                            <i className="font-sm ti-home text-danger pr-0"></i>
                            <input
                              type="text"
                              className="style2-input pl-5 form-control  "
                              // placeholder="Password"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
         
                  </Row>
                  <form>
                 
                    <Row className="mt-2">
            <div class="col-2">

            </div>
            <div class="col-8">
<Card className="dark ">
    <CardBody>
        <div className="ti-id-badge form-check-label text-white  upload" ></div>
        <div class="mb-3 mt-5 ">
  <input class="form-control darks " type="file" id="formFile"/>
</div>
          
    </CardBody>
</Card>
</div>
<div class="col-2">

</div>

         </Row>
                  </form>
                  <Row>
                  <div class="col-2">

                  </div>
<div class="col-8 " >
                  <div  >
                    <div className="form-group ">
                      <a
                        href="/register"
                        className="form-control text-center text-white fw-600 mt-5 border-0 p-0 custom "
                      >
                        Start Now
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-2">

                  </div>
                  </Row>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default UsersRegistration;
