import React, { useState, useEffect } from 'react';
import { MdOutlineKeyboardArrowDown, MdOutlineKeyboardArrowUp } from "react-icons/md"
import {
  Row,
  Col,
  Card,
  CardBody,
} from "reactstrap";
import calenderImg from "../../../assets/iCON/noun-calendar-5143299.png"
import filter from "../../../assets/iCON/noun-filter-4025735.png"
import branch from "../../../assets/iCON/noun-hub-2586471.png"
import document from "../../../assets/iCON/noun-document-1842194.png"
import world from "../../../assets/iCON/noun-country-2450449.png"
import school from "../../../assets/iCON/noun-university-1340867.png"
import levels from "../../../assets/iCON/noun-typing-3165306.png"
import save from "../../../assets/iCON/noun-special-2355497@2x.png"
import never from "../../../assets/iCON/noun-name-2853813.png"
import wrong from "../../../assets/iCON/noun-mistake-4731601.png"
import languages from "../../../assets/iCON/language.png";
import AllFilterServices from '../../services/AllFilterServices';
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { BsFilterSquare } from 'react-icons/bs';
require('checkboxes');


export var selectedBranch = []
export var selectedChapter = []
export var selectedSubchapter = []

export const AllSocialFilters = () => {

  const [state,setState]=useState()  
  const [isActive, setIsActive] = useState(false);
  const [branchs, setBranch] = useState([]);

  useEffect(async () => {
    var response = await AllFilterServices.getAllBranches()
    setBranch(response.data.payload);
  }, [])

  const [chapter, setChapter] = useState(false);
  const [chapterList, setChapterList] = useState([]);
  useEffect(async () => {
    var response = await AllFilterServices.getAllChapters()
    setChapterList(response.data.payload);
  }, [])
  const [subChapter, setSubChapter] = useState(false);
  const [subChapterList, setSubchapterList] = useState([]);
  useEffect(async () => {
    var response = await AllFilterServices.getAllSubchapter()
    setSubchapterList(response.data.payload);
  }, [])

  const [country, setCountry] = useState(false);
  const [countryList, setCountryList] = useState([]);
  useEffect(async () => {
    var response = await AllFilterServices.getAllCountries()
    setCountryList(response.data.payload);
  }, [])

  const [university, setUniversity] = useState(false);
  const [universityList, setUniversityList] = useState([]);
  useEffect(async () => {
    var response = await AllFilterServices.getAllUniversities()
    setUniversityList(response.data.payload);
  }, [])
  const [level, setLevel] = useState(false);
  const [calendar, setCalander] = useState(false)
  const [bookmarked, setBookmarked] = useState(false)
  const [attempted, setAttempted] = useState(false)
  const [mistake, setMistakes] = useState(false)
  const [language, setLanguage] = useState(false)

  const [searchInput, setSearchInput] = useState("");
  const handleChange = (e) => {
    e.preventDefault();
    debugger
    setSearchInput(e.target.value);
  };

  if (searchInput.length > 0) {
    debugger
    countryList.filter((value) => {
      return value.name.match(searchInput);
    });
  }

  const [chnageChapter, setChnageChapter] = useState([])
  const BranchFilterChange = async (e) => {
    var Id = e.target.getAttribute('data-questionId')
    if (e.target.checked == false) {
      debugger
      selectedBranch = selectedBranch.filter(function (obj) {
        return obj != Id;
      });
    }
    else {
      selectedBranch.push(parseInt(Id))
    }
    console.log(selectedBranch)
    e.target.parentNode.childNodes.forEach(function (element) {
      if (e.target.checked == true) {
        element.classList.add("change-color")
        element.classList.add("checkbox-color")
      }
      else {
        element.classList.remove("change-color")
      }
    });

    var response = await AllFilterServices.GetAllChaptersByBranchIds(selectedBranch);
    setChnageChapter(response.data.payload)

    if (e.target.checked == false) {
      var tempSelectedChapters = selectedChapter;
      tempSelectedChapters.forEach(element => {
        
        if(!response.data.payload.some(x => x.id == element))
        {
          selectedChapter = selectedChapter.filter(function (obj) {
            return obj != element;
          });
        }
      });

      var response2 = await AllFilterServices.GetAllSubChaptersByChapterIds(selectedChapter);
      setChnageSubChapter(response2.data.payload)
      var tempSelectedSubChapters = selectedSubchapter;
      tempSelectedSubChapters.forEach(element => {
        debugger
        const index = response2.data.payload.some(x => x.id == element);
        if(!index)
        {
          selectedSubchapter = selectedSubchapter.filter(function (obj) {
            return obj != element;
          });
        }
      });

      console.log("Chapters ", selectedChapter)
      console.log("Sub-Chapters ",selectedSubchapter)
    }

  }

  const [chnageSubChapter, setChnageSubChapter] = useState([])
  const ChapterFilterChange = async (e) => {
    var Id = e.target.getAttribute('data-questionId')
    if (e.target.checked == false) {

      selectedChapter = selectedChapter.filter(function (obj) {
        return obj != Id;
      });
    }
    else {
      selectedChapter.push(parseInt(Id))
    }

    e.target.parentNode.childNodes.forEach(function (element) {
      if (e.target.checked == true) {
        element.classList.add("change-color")
        element.classList.add("checkbox-color")
      }
      else {
        element.classList.remove("change-color")
      }
    });
    var response = await AllFilterServices.GetAllSubChaptersByChapterIds(selectedChapter);
    setChnageSubChapter(response.data.payload)

  }


  const SubchapterFilterChange = (e) => {
    var Id = e.target.getAttribute('data-questionId')
    if (e.target.checked == false) {
      selectedSubchapter = selectedSubchapter.filter(function (obj) {
        return obj != Id;
      });
    }
    else {
      selectedSubchapter.push(Id)
    }
    //setAllFilters({allFilters,"branchIds":selectedBranch})
    e.target.parentNode.childNodes.forEach(function (element) {
      if (e.target.checked == true) {
        element.classList.add("change-color")
        element.classList.add("checkbox-color")
      }
      else {
        element.classList.remove("change-color")
      }
    });
  }



  // const [allFilters, setAllFilters] = useState({
  //   branchIds: selectedBranch,
  //   chapterIds: selectedChapter,
  //   subChapterIds: selectedSubchapter
  // })
  // console.log("hello", allFilters)
  return (
    <>
      <div class="col-md-4 col-sm-12 mt-4">
        <Card style={{ background: "black", marginLeft: "50px" }}>
          <div className=" filter-by w-100 " >
            <div className="form-control border-0 p-0">
              <img src={filter} className="float-left mt-2 mr-3 ml-2 w30" /> <h2 className='h2' >  Filter By</h2> <MdOutlineKeyboardArrowDown className="float-right filter-icon" color='#E84545' fontSize="2.3em" />
            </div>
          </div>
          <CardBody>

            <div className="form-group mb-1 filter-margin" onClick={() => setCalander(!calendar)}>
              {calendar ? <a className="form-control   text-white  colors border-0 p-0 mb-2 ">
                <img src={calenderImg} className="float-left mt-2 mr-2 ml-2 w30 p-1" /> <h3 className='text-white '>All</h3>
              </a> : <a className="form-control text-white quiz border-0 p-0 mb-2 ">
                <img src={calenderImg} className="float-left mt-2 mr-2 ml-2 w30 p-1" /> <h3 className='text-white '>All</h3>
              </a>}
            </div>
            

            {/* <div className="CursorPointer form-group mb-1 filter-margin" onClick={() => setIsActive(!isActive)}>
              {isActive ? <a className="form-control   text-white  colors border-0 p-0 mb-2 ">
                <img src={branch} className="float-left mt-2 mr-2 ml-2 w30 p-1" /> <h3 className='text-white '>Branch</h3>
                {isActive ? <MdOutlineKeyboardArrowUp className="float-right mt-2 branch-icon" fontSize="2.3em" /> : < MdOutlineKeyboardArrowDown className="float-right branch-icon" fontSize="2.3em" />}
              </a> : <a className="form-control text-white quiz border-0 p-0 mb-2 ">
                <img src={branch} className="float-left mt-2 mr-2 ml-2 w30 p-1" /> <h3 className='text-white '>Branch</h3>
                {isActive ? <MdOutlineKeyboardArrowUp className="float-right mt-2 branch-icon" fontSize="2.3em" /> : < MdOutlineKeyboardArrowDown className="float-right branch-icon" fontSize="2.3em" />}
              </a>}
            </div>
            {isActive && <div className="form-group mb-1 sub-menu-filter mb-3 mr-3 w-100">
              <input placeholder='Search Branch' className='form-control' />
              {branchs.map((value, index) => {
                return (
                  <div>
                    <label className='ml-2 CursorPointer'>{value.title}</label>
                    <input
                      type="checkbox"
                      data-questionId={value.id}
                      onChange={BranchFilterChange}
                      className="float-right checkbox"
                      style={{ backgroundColor: "transparent", borderRadius: "3px", borderColor: "white", color: "#E84545", marginTop: "10px", marginRight: "7px" }}
                    />
                  </div>
                )
              })}


            </div>}
            <div className="form-group mb-1 filter-margin" onClick={() => setLanguage(!language)}>
              {language ? <a className="form-control   text-white  colors border-0 p-0 mb-2 ">
                <img src={languages} className="float-left mt-2 mr-2 ml-2 w30 p-1 text-white" /> <h3 className='text-white '>Language</h3>
                {language ? <MdOutlineKeyboardArrowUp className="float-right mt-2 branch-icon" fontSize="2.3em" /> : < MdOutlineKeyboardArrowDown className="float-right branch-icon" fontSize="2.3em" />}
              </a> : <a className="form-control text-white quiz border-0 p-0 mb-2 ">
                <img src={languages} className="float-left mt-2 mr-2 ml-2 w30 p-1 text-white" /> <h3 className='text-white '>Language</h3>
                {language ? <MdOutlineKeyboardArrowUp className="float-right mt-2 branch-icon" fontSize="2.3em" /> : < MdOutlineKeyboardArrowDown className="float-right branch-icon" fontSize="2.3em" />}
              </a>}
            </div>
            {language && <div className="form-group mb-1 sub-menu-filter mb-3 mr-3 w-100">
              <input placeholder='Search Branch' className='form-control' />
            
                  <div>
                    <label className='ml-2'>English</label>
                    <input
                      type="checkbox"
                    //   onChange={FilterChange}
                      className="float-right checkbox"
                      style={{ backgroundColor: "transparent", borderRadius: "3px", borderColor: "white", color: "#E84545", marginTop: "10px", marginRight: "7px" }}
                    />
                  </div>
                  <div>
                    <label className='ml-2'>French</label>
                    <input
                      type="checkbox"
                    //   onChange={FilterChange}
                      className="float-right checkbox"
                      style={{ backgroundColor: "transparent", borderRadius: "3px", borderColor: "white", color: "#E84545", marginTop: "10px", marginRight: "7px" }}
                    />
                  </div>
                  <div>
                    <label className='ml-2'>German</label>
                    <input
                      type="checkbox"
                    //   onChange={FilterChange}
                      className="float-right checkbox"
                      style={{ backgroundColor: "transparent", borderRadius: "3px", borderColor: "white", color: "#E84545", marginTop: "10px", marginRight: "7px" }}
                    />
                  </div>
                  <div>
                    <label className='ml-2'>Italian</label>
                    <input
                      type="checkbox"
                    //   onChange={FilterChange}
                      className="float-right checkbox"
                      style={{ backgroundColor: "transparent", borderRadius: "3px", borderColor: "white", color: "#E84545", marginTop: "10px", marginRight: "7px" }}
                    />
                  </div>
                  <div>
                    <label className='ml-2'>Spanish</label>
                    <input
                      type="checkbox"
                    //   onChange={FilterChange}
                      className="float-right checkbox"
                      style={{ backgroundColor: "transparent", borderRadius: "3px", borderColor: "white", color: "#E84545", marginTop: "10px", marginRight: "7px" }}
                    />
                  </div>
                  <div>
                    <label className='ml-2'>Portugese</label>
                    <input
                      type="checkbox"
                    //   onChange={FilterChange}
                      className="float-right checkbox"
                      style={{ backgroundColor: "transparent", borderRadius: "3px", borderColor: "white", color: "#E84545", marginTop: "10px", marginRight: "7px" }}
                    />
                  </div>
            </div>}

            <div className=" CursorPointer form-group mb-1 filter-margin" onClick={() => setChapter(!chapter)}>
              {chapter ? <a className="form-control   text-white  colors border-0 p-0 mb-2 ">
                <img src={document} className="float-left mt-2 mr-2 ml-2 w30 p-1" /> <h3 className='text-white '>Chapters</h3>
                {chapter ? <MdOutlineKeyboardArrowUp className="float-right mt-2 branch-icon" fontSize="2.3em" /> : < MdOutlineKeyboardArrowDown className="float-right branch-icon" fontSize="2.3em" />}
              </a> : <a className="form-control text-white quiz border-0 p-0 mb-2 ">
                <img src={document} className="float-left mt-2 mr-2 ml-2 w30 p-1" /> <h3 className='text-white '>Chapters</h3>
                {chapter ? <MdOutlineKeyboardArrowUp className="float-right mt-2 branch-icon" fontSize="2.3em" /> : < MdOutlineKeyboardArrowDown className="float-right branch-icon" fontSize="2.3em" />}
              </a>}
            </div>
            {chapter && <div className="form-group mb-1 sub-menu-filter mb-3 mr-3 w-100">
              <input placeholder='Search Chapter' className='form-control' />
              {chnageChapter.map((value, index) => {
                return (
                  <div>
                    <label className='ml-2 CursorPointer'>{value.title}</label>
                    <input
                      type="checkbox"
                      data-questionId={value.id}
                      onChange={ChapterFilterChange}
                      className="float-right checkbox"
                      style={{ backgroundColor: "transparent", borderRadius: "3px", borderColor: "white", color: "#E84545", marginTop: "10px", marginRight: "7px" }}
                    />
                  </div>
                )
              })}

            </div>}

            <div className="CursorPointer form-group mb-1 filter-margin" onClick={() => setSubChapter(!subChapter)}>
              {subChapter ? <a className="form-control   text-white  colors border-0 p-0 mb-2 ">
                <img src={document} className="float-left mt-2 mr-2 ml-2 w30 p-1" /> <h3 className='text-white '>Sub Chapter</h3>
                {subChapter ? <MdOutlineKeyboardArrowUp className="float-right mt-2 branch-icon" fontSize="2.3em" /> : < MdOutlineKeyboardArrowDown className="float-right branch-icon" fontSize="2.3em" />}
              </a> : <a className=" form-control text-white quiz border-0 p-0 mb-2 ">
                <img src={document} className="float-left mt-2 mr-2 ml-2 w30 p-1" /> <h3 className='text-white '>Sub Chapter</h3>
                {subChapter ? <MdOutlineKeyboardArrowUp className="float-right mt-2 branch-icon" fontSize="2.3em" /> : < MdOutlineKeyboardArrowDown className="float-right branch-icon" fontSize="2.3em" />}
              </a>}
            </div>

            {subChapter && <div className="form-group mb-1 sub-menu-filter mb-3 mr-3 w-100">
              <input placeholder='Search Sub-Chapter' className='form-control' />

              {chnageSubChapter.map((value, index) => {
                return (
                  <div>
                    <label className='ml-2 CursorPointer'>{value.title}</label>
                    <input
                      data-questionId={value.id}
                      type="checkbox"
                      onChange={SubchapterFilterChange}
                      className="float-right checkbox"
                      style={{ backgroundColor: "transparent", borderRadius: "3px", borderColor: "white", color: "#E84545", marginTop: "10px", marginRight: "7px" }}
                    />
                  </div>
                )
              })}
            </div>} */}

            <div className="form-group mb-1 filter-margin" onClick={() => setCountry(!country)}>
              {country ? <a className="form-control   text-white  colors border-0 p-0 mb-2 ">
                <img src={world} className="float-left mt-2 mr-2 ml-2 w30 p-1" /> <h3 className='text-white '>Country</h3>
              </a> : <a className="form-control text-white quiz border-0 p-0 mb-2 ">
                <img src={world} className="float-left mt-2 mr-2 ml-2 w30 p-1" /> <h3 className='text-white '>Country</h3>
              </a>}
            </div>
           

            <div className="form-group mb-1 filter-margin" onClick={() => setUniversity(!university)}>
              {university ? <a className="form-control   text-white  colors border-0 p-0 mb-2 ">
                <img src={school} className="float-left mt-2 mr-2 ml-2 w30 p-1" /> <h3 className='text-white '>University</h3>
              </a> : <a className="form-control text-white quiz border-0 p-0 mb-2 ">
                <img src={school} className="float-left mt-2 mr-2 ml-2 w30 p-1" /> <h3 className='text-white '>University</h3>
              </a>}
            </div>
            <div className="form-group mb-1 filter-margin" onClick={() => setLevel(!level)}>
              {level ? <a className="form-control   text-white  colors border-0 p-0 mb-2 ">
                <img src={levels} className="float-left mt-2 mr-2 ml-2 w30 p-1" /> <h3 className='text-white '>Year</h3>
              </a> : <a className="form-control text-white quiz border-0 p-0 mb-2 ">
                <img src={levels} className="float-left mt-2 mr-2 ml-2 w30 p-1" /> <h3 className='text-white '>Year</h3>
              </a>}
            </div>
           


            {bookmarked ? <div className="form-group mb-1 filter-margin " >
              <a className="form-control text-white colors border-0 p-0 mb-2 ">
                <img src={save} className="float-left mt-2 mr-2 ml-2 p-1" width="25px" /> <h3 className='text-white '>Speciality Of Intrest</h3>
              </a>

            </div> : <div className="form-group mb-1 filter-margin" >
              <a className="form-control text-white quiz border-0 p-0 mb-2 ">
                <img src={save} className="float-left mt-2 mr-2 ml-2 p-1" width="25px" /> <h3 className='text-white '>Speciality Of Intrest</h3>
              </a>
            </div>}

            {attempted ? <div className="form-group mb-1 filter-margin" onClick={() => setAttempted(!attempted)}>
              <a className="form-control text-white colors border-0 p-0 mb-2 ">
                <img src={never} className="float-left attempt mr-2 ml-2 w30 p-1" /> <h3 className='text-white '>First Name</h3>

                
              </a>
            </div> : <div className="form-group mb-1 filter-margin" onClick={() => setAttempted(!attempted)}>
              <a className="form-control text-white quiz border-0 p-0 mb-2 ">
                <img src={never} className="float-left attempt mr-2 ml-2 w30 p-1" /> <h3 className='text-white '>First Name</h3>
              </a>
            </div>}

            {mistake ? <div className="form-group mb-1 filter-margin" onClick={() => setMistakes(!mistake)}>
              <a className="form-control text-white colors border-0 p-0 mb-2 ">
                <img src={never} className="float-left mistake mr-2 ml-2 w30 p-1" /> <h3 className='text-white '>Name</h3>
              </a>
            </div> : <div className="form-group mb-1 filter-margin" onClick={() => setMistakes(!mistake)}>
              <a className="form-control text-white quiz border-0 p-0 mb-2 ">
                <img src={never} className="float-left mistake mr-2 ml-2 w30 p-1" /> <h3 className='text-white '>Name</h3>
              </a>
            </div>}

          </CardBody>
        </Card>
      </div>
    </>
  )
}

// export default QuizFilter