import { selectedBranch, selectedChapter, selectedSubchapter } from "../QuizFilters"
import React, { useState, useRef } from "react"
import { Link } from 'react-router-dom';
import dummy from "../../assests/dummy.png"
import { FiHeart } from "react-icons/fi"
import comments from "../../assests/iCON/iCON/noun-comment-3266599.png"
import share from "../../assests/iCON/iCON/noun-share-5060531.png"
import flag from "../../assests/iCON/iCON/noun-flag-1017687.png"
import videogreen from "../../assests/iCON/noun-video-1841846@2x.png"
import Imggreen from "../../assests/iCON/noun-image-5079181@2x.png"
import microGreen from "../../assests/iCON/noun-microphone-982542@3x.png"
import textGreen from "../../assests/iCON/noun-text-3548498@2x.png"
import save from "../../assests/iCON/iCON/noun-bookmark-3025188.png"
import dimand from "../../assests/iCON/iCON/noun-diamond-1807567.png"
import video from "../../assests/iCON/iCON/noun-video-1841846.png"
import hand from "../../assests/iCON/iCON/noun-heart-3515260.png"
import videogray from "../../../assets/iCON/noun-video-1841846@2x.png"
import imggray from "../../../assets/iCON/noun-image-5079181@2x.png"
import microgray from "../../../assets/iCON/noun-microphone-982542@2x.png"
import textgray from "../../../assets/iCON/noun-text-3548498@2x.png"
import { SessionLogoutMsg } from "../../..";
import QuizServises from "../../services/QuizServices"
import { useEffect } from "react";
import Swal from "sweetalert2"
import { useHistory } from 'react-router-dom';
import Spinner from "../../spinner/Spinner"
import {
    Row, Col, Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from "reactstrap"
import { Watermark } from '@hirohe/react-watermark';
// import Watermark from 'react-awesome-watermark';
import styled from 'styled-components';
// const StyledWatermark = styled(Watermark)``;
require('checkboxes');
const TodayQuiz = (props) => {


    console.log("selectedBranch", selectedBranch)
    console.log("selectedChapter", selectedChapter)
    console.log("selectedSubchapter", selectedSubchapter)

    console.log(props.count)
    const [ApplyFilter, setapplyFilter] = useState({
        questionCount: parseInt(props.count),
        branchId: selectedBranch,
        chapterId: selectedChapter,
        subChapterId: selectedSubchapter,
    })


    // video modal
    const [modal, setModal] = useState(false);
    const [videoPath, setVideoPath] = useState("");
    const videoModalOpen = (event, value) => {
        document.getElementById('videoModal').setAttribute("src", videoPath);
    }

    const [QuesimgPath, setQuesImgPath] = useState("");
    const QuesImgModalOpen = (event, value) => {
        document.getElementById('QuesImgModal').setAttribute("src", QuesimgPath);
    }

    const [imgPath, setImgPath] = useState("");
    const ImgModalOpen = (event, value) => {
        document.getElementById('ImgModal').setAttribute("src", imgPath);
    }
    const [desModal, setDesModal] = useState("");
    const DesModalOpen = (event, value) => {
        debugger
        // document.getElementById('desModal').setAttribute("value", desModal);
        document.getElementById("desModal").innerHTML = desModal

    }



    const toggle = (event, value) => {
        if (value != null) {
            if (document.getElementById('watchExpl-' + value.id).classList.contains("green")) {
                setVideoPath(value.videoUrl);
                setModal(!modal);
            }
            else {
                alert("Please Submit Your Answere First")
            }
        }
        else setModal(!modal);
    }
    // audio modal
    const [modal4, setModal4] = useState(false);
    const toggle4 = (event, value) => {
        debugger
        if (value != null) {
            if (document.getElementById('watchExpl-' + value.id).classList.contains("green")) {
                setModal4(!modal4);
            }
            else {
                alert("Please Submit Your Answere First")
            }
        }
        else setModal4(!modal4);
    }
    // Image modal
    const [modal1, setModal1] = useState(false);
    const toggle1 = (event, value) => {
        debugger
        if (value != null) {
            if (document.getElementById('watchExpl-' + value.id).classList.contains("green")) {
                setQuesImgPath(value.imageUrl);
                setModal1(!modal1);
            }
            else {
                alert("Please Submit Your Answere First")
            }
        }
        else setModal1(!modal1);
    }
    // Desc modal
    const [modal3, setModal3] = useState(false);
    const toggle3 = (event, value) => {
        debugger
        if (value != null) {
            if (document.getElementById('watchExpl-' + value.id).classList.contains("green")) {
                setDesModal(value.questionDetails);
                setModal3(!modal3);
            }
            else {
                alert("Please Submit Your Answere First")
            }
        }
        else setModal3(!modal3);
    }


    const [imgUrl, setImgUrl] = useState(false);
    const ImgUrlToggle = (event, value) => {
        setImgUrl(!imgUrl);
        if (imgUrl == true) {
            console.log("null")
        }
        else {
            setImgPath(value.questionImageUrl)
        }
    }

    const [loading, setLoading] = useState(false)
    const [viewComments, setViewComments] = useState(false)
    const [quiz, setQuiz] = useState([])
    const [selectOpt, setSelectOpt] = useState([])
    // get user from localstorage
    let localStorageObject = localStorage.getItem("Student_Info");
    var data = JSON.parse(localStorageObject);

    // get all Quizes
    useEffect(async (arr, val) => {
        setLoading(true)
        var response = await QuizServises.getAllQuizes(ApplyFilter)
        debugger
        if (response.data.message == SessionLogoutMsg) {
            history.push("/login")
            localStorage.removeItem('Student_Info');
        }
        else {
            setQuiz(response.data.payload);
            var mc = response.data.payload.filter(x => x.questionType == 2);
            var tempArr = [];
            mc.forEach(function (item) {
                tempArr.push({
                    userId: data.id,
                    questionId: item.id,
                    isCorrect: IsCorrectAnswer(response.data.payload, item.id, []),
                    options: [],
                    questionType: item.questionType,
                })
            })
            setSelectOpt(tempArr)
            setLoading(false)
        }
    }, [])

    // submit indivual Ans 
    const submitAns = (value, event) => {
        if (selectOpt.find(x => x.questionId == value.id) != null) {
            document.getElementById('watchExpl-' + value.id).classList.add("green");
            value.questionOptions.forEach(function (correct) {
                if (correct.isCorrect == true) {
                    document.getElementById('label-' + correct.id).classList.add("green");
                }
                else {
                    document.getElementById('label-' + correct.id).classList.add("change-color");
                }
                document.getElementById('op' + correct.id).disabled = true;
            })
        }
    }

    const ChangeQuestionOption = (e) => {

        // changing color 
        var qId = e.target.getAttribute('data-questionId')
        var mainQuestion = quiz.find(x => x.id == qId);
        e.target.parentNode.childNodes.forEach(function (element) {
            if (mainQuestion.questionType == 1) {
                mainQuestion.questionOptions.forEach(function (element) {
                    document.getElementById('label-' + element.id).classList.remove("change-color");
                });
            }
            if (e.target.checked == true) {
                element.classList.add("change-color")
            }
            else {
                element.classList.remove("change-color")
            }
        });
        var questionOb = selectOpt.find(x => x.questionId == qId);

        // push data into an array
        debugger
        if (questionOb == null) {
            var isCorrect = IsCorrectAnswer(quiz, qId, [e.target.value]);
            var tempArr = selectOpt;
            tempArr.push({
                userId: data.id,
                questionId: qId,
                isCorrect: isCorrect,
                options: [e.target.value],
                questionType: mainQuestion.questionType
            })
            setSelectOpt(tempArr)
            document.getElementById('submitbtn-' + qId).classList.add("colorRed");
        }
        else {
            if (e.target.checked == true) {

                questionOb.options.push(e.target.value);
                var tempArr = selectOpt;
                tempArr.find(x => x.questionId == qId).options = questionOb.options;
                var isCorrect = IsCorrectAnswer(quiz, qId, questionOb.options);
                tempArr.find(x => x.questionId == qId).isCorrect = isCorrect;
                setSelectOpt(tempArr)
                document.getElementById('submitbtn-' + qId).classList.add("colorRed");
            }
            else {
                questionOb.options = questionOb.options.filter(function (obj) {
                    return obj !== e.target.value;
                });
                // if (questionOb.options.length == 0) {
                //     var tempArr = selectOpt;
                //     tempArr = tempArr.filter(function (obj) {
                //         return obj.questionId !== qId;
                //     });
                //     setSelectOpt(tempArr)
                // }
                // else {
                var tempArr = selectOpt;
                tempArr.find(x => x.questionId == qId).options = questionOb.options;
                var isCorrect = IsCorrectAnswer(quiz, qId, questionOb.options);
                tempArr.find(x => x.questionId == qId).isCorrect = isCorrect;
                setSelectOpt(tempArr)
                // }
                // document.getElementById('submitbtn-' + qId).classList.add("colorGray");
            }
        }
    }

    function IsCorrectAnswer(tempQuiz, qId, questionObOptions) {
        var mainQuestion = tempQuiz.find(x => x.id == qId);
        if (mainQuestion && mainQuestion.questionOptions != null) {
            var correctoptions = mainQuestion.questionOptions.filter(function (obj) {
                return obj.isCorrect == true;
            });
            var correctIds = correctoptions.map((obj) => obj.id + "");
            var isEqual = (JSON.stringify(correctIds.sort()) === JSON.stringify(questionObOptions.sort()));
            return isEqual;
        }
        return false;
    }

    let history = useHistory();

    const backToRoute = () => {
        history.push("/quizResult");
    };


    // submit all quiz ans
    const submitButton = async () => {

        modelTitle = "Are You Sure you wanted to Submit!";
        modelText = "You won't be able to revert this!";
        if (selectOpt.filter(x => x.questionType == 1).length != quiz.filter(x => x.questionType == 1).length) {
            var modelTitle = "You left some question unanswered, are you sure you want to proceed?";
            var modelText = "You won't be able to revert this!";
        }
        try {
            await Swal.fire({
                title: modelTitle,
                text: modelText,
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes",
            }).then(async (result) => {
                if (result.isConfirmed) {
                    selectOpt.forEach((value) => {
                        var qOptions = quiz.find(x => x.id == value.questionId).questionOptions;
                        qOptions.forEach(function (correct) {
                            if (correct.isCorrect == true) {
                                document.getElementById('label-' + correct.id).classList.add("green");
                            }
                            else {
                                document.getElementById('label-' + correct.id).classList.add("change-color");
                            }
                            document.getElementById('op' + correct.id).disabled = true;
                        })
                    })
                    // setLoading(true)
                    var response = await QuizServises.AddQuizQuestion(selectOpt);
                    // setLoading(false)
                    if (response.data.status === true) {
                        Swal.fire("Added!", "" + "Quiz Submited Successfully", "success");
                        // window.location.reload();
                        // backToRoute()

                    } else {
                        Swal.fire("Error!", response.data.message, "danger");
                    }
                }
            });
        }
        catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong!',
            })
        }
    };


    const a = localStorage.getItem("Student_Info")
    const studentName = JSON.parse(a)
    return (
        <>{loading ? <Spinner /> :

            <div className="row">
                <div className="col-xl-10 col-lg-10 col-md-10 col-sm-10 mb-4 se tMargin scrollbar scrollbar-primary copy-txt" style={{ userSelect: "none" }}>
                    {quiz.map((value, index) => (
                        // <StyledWatermark
                        //     key={index}
                        //     text={studentName.firstName}
                        //     style={{
                        //         width: "auto",
                        //         height: "auto",
                        //         color: "#707172"
                        //     }}
                        //     multiple
                        // >
                        <Watermark
                            textSize="15"
                            lineHeight='1.6'
                            multiline="false"
                            textColor="#707172"
                            text={studentName.firstName + " " + studentName.lastName}>


                            <Modal
                                isOpen={modal4}
                                toggle={toggle4.bind(null)}
                            >
                                <ModalHeader toggle={toggle4.bind(null)}>See Audio</ModalHeader>
                                <ModalBody>
                                    <audio controls="controls" src={value.audioUrl} preload="auto" ></audio>
                                </ModalBody>
                                <ModalFooter>
                                </ModalFooter>
                            </Modal>


                            <div className=" p-0  border-0 rounded-lg overflow-hidden mr-1  shadow-lg p-3 mb-5 rounded quizs force-overflow " style={{ width: "auto", height: "auto" }}>
                                {/* <Link to="/showUserProfile">
                                    <span>
                                    <img src={dummy} alt="img" className='w30' />
                                         </span>
                                    </Link> */}
                                {/* <span style={{ color: "#E84545" }}>{studentName.firstName+" "+studentName.lastName}</span> */}
                                {/* {value.isPremium == true ? */}
                                <div className='float-right' id={"watchExpl-" + value.id} ><span style={{ fontSize: "20px" }}> Watch Explanation</span>
                                    {value.videoUrl == "" ? <img src={videogray} width="27px" className="ml-2" /> : <img src={videogreen} width="27px" onClick={(event) => { toggle(event, value) }} className="ml-2 CursorPointer" />}
                                    {value.imageUrl == "" ? <img src={imggray} width="27px" className="ml-3 mr-2" /> : <img src={Imggreen} width="27px" onClick={(event) => { toggle1(event, value) }} className="ml-3 mr-2 CursorPointer" />}
                                    {value.audioUrl == "" ? <img src={microgray} width="20px" className=" ml-2 mr-2" /> : <img src={microGreen} width="20px" onClick={(event) => { toggle4(event, value) }} className="ml-2 mr-2 CursorPointer" />}
                                    {value.questionDetails == "" ? <img src={textgray} width="22px" className="ml-1" /> : <img src={textGreen} width="22px" onClick={(event) => { toggle3(event, value) }} className="ml-1 CursorPointer" />}
                                </div>
                                {/* // :
                                     <div className='float-right' id={"watchExpl-" + value.id} >Watch Explanation
                                    <img src={videogreen} onClick={(event) => { toggle(event, value) }}  className="ml-2"/>
                                    <img src={Imggreen} onClick={(event) => { toggle1(event, value) }} className="ml-1 mr-1" />
                                    <img src={microGreen} onClick={(event) => { toggle4(event, value) }} className="w10 ml-1 mr-1" />
                                    <img src={textGreen} onClick={(event) => { toggle3(event, value) }} /></div>
                                    } */}

                                <div className=" position-relative d-block mt-5 ">
                                    <div className="mt-3 mb-4 ">{value.title} {value.questionImageUrl == "" ? <a href="#" style={{ color: "#898989" }}>(Preview)</a> : <a href="#" onClick={(event) => { ImgUrlToggle(event, value) }}>(Preview)</a>}</div>
                                    {value.questionOptions.map((optn, index2) => (
                                        <div
                                            key={index2}
                                        >
                                            {value.questionType == 1 ?
                                                <div>
                                                    <input
                                                        type="radio"
                                                        id={"op" + optn.id}
                                                        name={"radio-group" + value.id}
                                                        onChange={ChangeQuestionOption}
                                                        data-questionId={value.id}
                                                        value={optn.id} />
                                                    <label className="my-class" for={"op" + optn.id} id={"label-" + optn.id}>{optn.title} <h1></h1></label>
                                                </div> :
                                                <div>
                                                    <input
                                                        type="checkbox"
                                                        id={"op" + optn.id}
                                                        onChange={ChangeQuestionOption}
                                                        data-questionId={value.id}
                                                        value={optn.id}
                                                        className="float-left checkbox"
                                                        style={{ backgroundColor: "transparent", borderRadius: "3px", borderColor: "white", color: "#E84545", marginTop: "10px", marginRight: "7px" }}
                                                    />
                                                    <label className="mt-1 my-class" id={"label-" + optn.id} >{optn.title}</label>

                                                </div>
                                            }
                                        </div>
                                    ))}
                                    <div className="mt-3 mb-5">
                                        <h5 className="text-dark text-grey-700 ">
                                            <button className="colorGray" id={"submitbtn-" + value.id} type="button" onClick={(event) => { submitAns(value, event) }}>Submit Answer</button>
                                            {/* {value.isPremium == true ? <img src={dimand} className="float-right" width="25px" /> : <img src={hand} className="float-right" width="25px" />} */}
                                        </h5>
                                    </div>
                                </div>


                                {/* <div className='bg-darks'>
                                    <div className='mt-2 pt-2 pl-2'>
                                        <FiHeart fontSize="2em" className="mr-3" />
                                        <img src={comments} width="25px" className="mr-3" />
                                        <img src={share} width="25px" />
                                        <img src={save} width="23px" className='float-right mr-3 ml-3' />
                                        <img src={flag} width="25px" className='float-right' />
                                    </div>

                                    <div className="card-body p-3">

                                        <h4 className="font-xss mt-2 ml-0 lh-28 mt-0 pb-3 border-bottom text-white" onClick={() => setViewComments(!viewComments)}>
                                            View 08 Comments
                                        </h4>
                                        Add Comments
                                        <div className='float-right' style={{ color: "#E84545" }}> Send</div>
                                    </div>
                                    {viewComments &&
                                        <div className="row">
                                            <div className="col-2 text-left pl-4 pt-3 m-2">
                                                <figure className="avatar float-left mb-0">
                                                    <img
                                                        src="https://statinfer.com/wp-content/uploads/dummy-user.png"
                                                        alt="banner"
                                                        className="float-right shadow-none w40 mr-2 rounded-circle"
                                                    />
                                                </figure>
                                            </div>
                                            <div className="col-10 pt-2 comments">
                                                <div className="content">
                                                    <b className="author-name font-xssss fw-600 mb-0 ">
                                                        Goria Coast
                                                    </b>
                                                    <p className="comment-text lh-24 fw-500 font-xssss text-grey-500 ">
                                                        Enjoyed this a lot and well done.
                                                        <FiHeart className="float-right" style={{ marginRight: "-23px" }} />
                                                    </p>

                                                </div>
                                            </div>

                                        </div>
                                    }

                                </div> */}
                            </div>
                        </Watermark>
                    ))}
                    {/* Video Explanation Modal */}
                    <Modal onOpened={videoModalOpen} isOpen={modal} toggle={toggle.bind(null)} className="modal-content-video" style={{ maxWidth: "1000px" }}>
                        <ModalHeader toggle={toggle.bind(null)}  > Watch Video Explanation</ModalHeader>
                        <ModalBody className="modal-content-video">
                            <Watermark
                                textSize="15"
                                lineHeight='1.6'
                                multiline="false"
                                textColor="#707172"
                                text={studentName.firstName + " " + studentName.lastName}>
                                {videoPath.startsWith("https://www.youtube.com") ?
                                    <iframe width="970px" height="440" id="videoModal" src="" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; no-download  " controls controlsList="nodownload"> </iframe> :
                                    <video controls
                                        autoplay="autoplay" id="videoModal" width="970px" height="440" allow="accelerometer; autoplay; clipboard-write;"  controlsList="nodownload">
                                        <source src="" type="video/mp4" />
                                    </video>}

                                {/* <iframe width="970px" height="440" id="videoModal" src="" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; no-download  " controls controlsList="nodownload"> </iframe> */}
                            </Watermark>
                        </ModalBody>
                    </Modal>
                    {/* Image Explanation Modal */}
                    <Modal onOpened={QuesImgModalOpen} isOpen={modal1} toggle={toggle1.bind(null)} className="modal-content-video" >
                        <ModalHeader toggle={toggle1.bind(null)}  > Watch Video Explanation</ModalHeader>
                        <ModalBody className="modal-content-video">
                            <Watermark
                                textSize="15"
                                lineHeight='1.6'
                                multiline="false"
                                textColor="#707172"
                                text={studentName.firstName + " " + studentName.lastName}>
                                <img src="" id="QuesImgModal" width="465px" height="240" />
                            </Watermark>
                        </ModalBody>
                    </Modal>
                    {/* Des Modal */}
                    <Modal
                        onOpened={DesModalOpen}
                        isOpen={modal3}
                        toggle={toggle3.bind(null)}
                    >
                        <ModalHeader toggle={toggle3.bind(null)}>See Description</ModalHeader>
                        <ModalBody>
                            <Watermark
                                textSize="15"
                                lineHeight='1.6'
                                multiline="false"
                                textColor="#707172"
                                text={studentName.firstName + " " + studentName.lastName}>
                                <p id="desModal"></p>
                            </Watermark>
                        </ModalBody>
                        <ModalFooter>
                        </ModalFooter>
                    </Modal>

                    <Modal
                        onOpened={ImgModalOpen}
                        isOpen={imgUrl}
                        toggle={ImgUrlToggle.bind(null)}
                    >
                        <ModalHeader toggle={ImgUrlToggle.bind(null)}>Question Image</ModalHeader>
                        <ModalBody>
                            <Watermark
                                textSize="15"
                                lineHeight='1.6'
                                multiline="false"
                                textColor="#707172"
                                text={studentName.firstName + " " + studentName.lastName}>
                                <img src="" id="ImgModal" width="465px" height="240" />
                            </Watermark>
                        </ModalBody>
                        <ModalFooter>
                        </ModalFooter>
                    </Modal>

                </div>
                <button className="submitBtn mt-4 mb-4"
                    // onClick={(event) => { submitAns(value, event)}}
                    onClick={submitButton}
                    type="button">Submit All Answer</button>
            </div>}

        </>
    )
}

export default TodayQuiz