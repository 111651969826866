import React, { useState } from "react"
import { Link } from 'react-router-dom';
import TodayQuiz from "../quiz-tabs/Today-Quiz"

require('checkboxes');

const QuizLeft = () => {
    const [viewComments, setViewComments] = useState(false)
    const FilterChange = (e) => {
        e.target.parentNode.childNodes.forEach(function (element) {
            if (e.target.checked == true) {
                element.classList.add("change-color")
            }
            else {
                element.classList.remove("change-color")
            }
        });
    }
  
    return (
        <>

            <TodayQuiz />

            {/* {courseList.map((value, index) => (
                // Strat Single Demo
                <div className="row">

                    <div
                        className="col-xl-9 col-lg-8 col-md-10 col-sm-10 mb-4 setMargin"
                        key={index}
                    >
                        <div className=" w-100 p-0  border-0 rounded-lg overflow-hidden mr-1  shadow-lg p-3 mb-5  rounded quizs ">
                        <Link to="/showUserProfile"><span><img src={dummy} alt="img" className='w30' /> </span></Link>
                            <span style={{color:"#E84545"}}>Amelia Jeannet</span>
                            <div className='float-right' style={{color:"#72BB53"}}>Watch Explanation <AiOutlineYoutube /></div>
                            <div
                                className=" position-relative d-block"
                            >
                                <div>Lorem Ipsum Is Simply Dummy Text Of The Printing And Typesetting Industry. Lorem Ipsum Has Been The Industry's Standard Dummy Text Ever Since The 1500S, When An Unknown Printer Took A Galley Of Type And Scrambled It To Make A Type Specimen Book.</div>
                                <div>
                                <label className="mt-1" >Brain Metastasis</label>
                                    <input
                                        type="checkbox"
                                          onChange={FilterChange}
                                        className="float-left checkbox"
                                        style={{ backgroundColor: "transparent", borderRadius: "25px", borderColor: "white", color: "#E84545", marginTop: "10px", marginRight: "7px" }}
                                    />
                                </div>
                                <div>
                                    <label className="mt-1" > Nonprofit Owner or Employee</label>
                                    <input
                                        type="checkbox"
                                          onChange={FilterChange}
                                        className="float-left checkbox"
                                        style={{ backgroundColor: "transparent", borderRadius: "25px", borderColor: "white", color: "#E84545", marginTop: "10px", marginRight: "7px" }}
                                    />
                                </div>
                                <div>
                                    <label className="mt-1">Journalist or Activist</label>
                                    <input
                                        type="checkbox"
                                          onChange={FilterChange}
                                        className="float-left checkbox"
                                        style={{ backgroundColor: "transparent", borderRadius: "25px", borderColor: "white", color: "#E84545", marginTop: "10px", marginRight: "7px" }}
                                    />
                                </div>
                                <div>
                                    <label className="mt-1">Subarachnoid Hemorrhage</label>
                                    <input
                                        type="checkbox"
                                          onChange={FilterChange}
                                        className="float-left checkbox"
                                        style={{ backgroundColor: "transparent", borderRadius: "25px", borderColor: "white", color: "#E84545", marginTop: "10px", marginRight: "7px" }}
                                    />
                                </div>
                                <div className="mt-3 mb-5">
                                    <h5 className="text-dark text-grey-700 ">Submit Answer 
                                    <img src={dimand} className="float-right" width="25px"/>
                                    </h5>
                                </div>        
                               </div>


                            <div className='bg-darks'>
                            <div className='mt-2 pt-2 pl-2'>
                            <FiHeart fontSize="2em" className="mr-3" />
                            <img src={comments} width="25px"className="mr-3" />
                            <img src={share} width="25px"/>
                            <img src={save} width="23px" className='float-right mr-3 ml-3' />
                            <img src={flag} width="25px" className='float-right' />
                                </div>

                                <div className="card-body p-3">

                                    <h4 className="font-xss mt-2 ml-0 lh-28 mt-0 pb-3 border-bottom text-white" onClick={() => setViewComments(!viewComments)}>
                                        View 08 Comments
                                    </h4>
                                    Add Comments
                                    <div className='float-right' style={{ color: "#E84545" }}> Send</div>
                                </div>
                                {viewComments &&
                                    <div className="row">
                                        <div className="col-2 text-left pl-4 pt-3 m-2">
                                            <figure className="avatar float-left mb-0">
                                                <img
                                                    src="https://statinfer.com/wp-content/uploads/dummy-user.png"
                                                    alt="banner"
                                                    className="float-right shadow-none w40 mr-2 rounded-circle"
                                                />
                                            </figure>
                                        </div>
                                        <div className="col-10 pt-2 comments">
                                            <div className="content">
                                                <b className="author-name font-xssss fw-600 mb-0 ">
                                                    Goria Coast
                                                </b>
                                                <p className="comment-text lh-24 fw-500 font-xssss text-grey-500 ">
                                                    Enjoyed this a lot and well done.
                                                    <FiHeart className="mr-2 float-right" />
                                                </p>

                                            </div>
                                        </div>

                                    </div>
                                    
                                    }

                            </div>
                        </div>
                    </div>

                </div>

                // End Single Demo

            ))}

{courseList.map((value, index) => (
                // Strat Single Demo
                <div className="row">

                    <div
                        className="col-xl-9 col-lg-8 col-md-10 col-sm-10 mb-4 setMargin"
                        key={index}
                    >
                        <div className=" w-100 p-0  border-0 rounded-lg overflow-hidden mr-1  shadow-lg p-3 mb-5  rounded quizs ">
                            <Link to="/showUserProfile"><span><img src={dummy} alt="img" className='w30' /> </span></Link>                            <span style={{ color: "#E84545" }}>Amelia Jeannet </span>
                            <div className='float-right'>Watch Explanation <AiOutlineYoutube /></div>
                            <div
                                className=" position-relative d-block"
                            >
                                <div>Lorem Ipsum Is Simply Dummy Text Of The Printing And Typesetting Industry. Lorem Ipsum Has Been The Industry's Standard Dummy Text Ever Since The 1500S, When An Unknown Printer Took A Galley Of Type And Scrambled It To Make A Type Specimen Book.</div>
                                <div>
                                <label className="mt-1" >Brain Metastasis</label>
                                    <input
                                        type="checkbox"
                                          onChange={FilterChange}
                                        className="float-left checkbox"
                                        style={{ backgroundColor: "transparent", borderRadius: "3px", borderColor: "white", color: "#E84545", marginTop: "10px", marginRight: "7px" }}
                                    />
                                </div>
                                <div>
                                <label className="mt-1" style={{color:"#72BB53"}} >Brain Metastasis</label>
                                    <input
                                        type="checkbox"
                                          onChange={FilterChange}
                                        className="float-left checkbox"
                                        checked
                                        style={{ backgroundColor: "transparent", borderRadius: "3px", borderColor: "white", color: "#E84545", marginTop: "10px", marginRight: "7px" }}
                                    />
                                </div>
                                <div>
                                <label className="mt-1" >Brain Metastasis</label>
                                    <input
                                        type="checkbox"
                                          onChange={FilterChange}
                                          checked
                                        className="float-left checkbox"
                                        style={{ backgroundColor: "transparent", borderRadius: "3px", borderColor: "white", color: "#E84545", marginTop: "10px", marginRight: "7px" }}
                                    />
                                </div>
                                <div>
                                <label className="mt-1" >Brain Metastasis</label>
                                    <input
                                        type="checkbox"
                                          onChange={FilterChange}
                                        className="float-left checkbox"
                                        style={{ backgroundColor: "transparent", borderRadius: "3px", borderColor: "white", color: "#E84545", marginTop: "10px", marginRight: "7px" }}
                                    />
                                </div>
                                <div className="mt-3 mb-5">
                                    <h5 className="mb-4" style={{ color: "#E84545" }}>Submit Answer
                                        <img src={heartIcon} className="float-right " width="20px" /> 
                                        </h5>
                                </div>
                            </div>


                            <div className='bg-darks'>
                                <div className='mt-2 pt-2 pl-2'>
                                    <FiHeart fontSize="2em" className="mr-3" />
                                    <img src={comments} width="25px" className="mr-3" />
                                    <img src={share} width="25px" />
                                    <img src={save} width="23px" className='float-right mr-3 ml-3' />
                                    <img src={flag} width="25px" className='float-right' />
                                </div>

                                <div className="card-body p-3">

                                    <h4 className="font-xss mt-2 ml-0 lh-28 mt-0 pb-3 border-bottom text-white" onClick={() => setViewComments(!viewComments)}>
                                        View 08 Comments
                                    </h4>
                                    Add Comments
                                    <div className='float-right' style={{ color: "#E84545" }}> Send</div>
                                </div>
                                    <div className="row">
                                        <div className="col-2 text-left pl-4 pt-3 m-2">
                                            <figure className="avatar float-left mb-0">
                                                <img
                                                    src="https://statinfer.com/wp-content/uploads/dummy-user.png"
                                                    alt="banner"
                                                    className="float-right shadow-none w40 mr-2 rounded-circle"
                                                />
                                            </figure>
                                        </div>
                                        <div className="col-10 pt-2 comments">
                                            <div className="content">
                                                <b className="author-name font-xssss fw-600 mb-0 ">
                                                    Goria Coast
                                                </b>
                                                <p className="comment-text lh-24 fw-500 font-xssss text-grey-500 ">
                                                    Enjoyed this a lot and well done.
                                                    <FiHeart className="float-right" style={{marginRight:"-24px"}} />
                                                </p>

                                            </div>
                                        </div>

                                    </div>
                                    <div className="row">
                                        <div className="col-2 text-left pl-4 pt-3 m-2">
                                            <figure className="avatar float-left mb-0">
                                                <img
                                                    src="https://statinfer.com/wp-content/uploads/dummy-user.png"
                                                    alt="banner"
                                                    className="float-right shadow-none w40 mr-2 rounded-circle"
                                                />
                                            </figure>
                                        </div>
                                        <div className="col-10 pt-2 comments">
                                            <div className="content">
                                                <b className="author-name font-xssss fw-600 mb-0 ">
                                                    Goria Coast
                                                </b>
                                                <p className="comment-text lh-24 fw-500 font-xssss text-grey-500 ">
                                                    Enjoyed this a lot and well done.
                                                    <FiHeart className="float-right" style={{marginRight:"-24px"}} />
                                                </p>

                                            </div>
                                        </div>

                                    </div>
                                    <div className="row">
                                        <div className="col-2 text-left pl-4 pt-3 m-2">
                                            <figure className="avatar float-left mb-0">
                                                <img
                                                    src="https://statinfer.com/wp-content/uploads/dummy-user.png"
                                                    alt="banner"
                                                    className="float-right shadow-none w40 mr-2 rounded-circle"
                                                />
                                            </figure>
                                        </div>
                                        <div className="col-10 pt-2 comments">
                                            <div className="content">
                                                <b className="author-name font-xssss fw-600 mb-0 ">
                                                    Goria Coast
                                                </b>
                                                <p className="comment-text lh-24 fw-500 font-xssss text-grey-500 ">
                                                    Enjoyed this a lot and well done.
                                                    <FiHeart className="float-right" style={{marginRight:"-24px"}} />
                                                </p>

                                            </div>
                                        </div>

                                    </div>

                            </div>

                        </div>
                        <Link to="/quizResult">
                            <button className="submit-btn">Submit All Answer</button>
                        </Link>
                    </div>
                </div>

                // End Single Demo
            ))} */}
        </>
    )
}

export default QuizLeft