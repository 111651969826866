import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import Header from '../../components/Header';
import { Tab, Tabs } from 'react-bootstrap';
import Social from './public/Social';
import hat from "../assests/iCON/iCON/noun-student-cap-1441568.png"
import pencil from "../assests/iCON/iCON/noun-edit-684936.png"
import trophy from "../assests/iCON/iCON/noun-trophy-5126269.png"
import dummy from "../../view/assests/dummy.png"
import { Input } from 'reactstrap';
const memberList = [
  {
    imageUrl: 'user.png',
    name: 'Aliqa Macale ',
    email: 'support@gmail.com',
    bgimage: 'course.png',
  },
  {
    imageUrl: 'user.png',
    name: 'John Steere ',
    email: 'support@gmail.com',
    bgimage: 'course.png',
  },
  {
    imageUrl: 'user.png',
    name: 'Mohannad Zitoun ',
    email: 'support@gmail.com',
    bgimage: 'course.png',
  },
  {
    imageUrl: 'user.png',
    name: 'Aliqa Macale ',
    email: 'support@gmail.com',
    bgimage: 'course.png',
  },
  {
    imageUrl: 'user.png',
    name: 'Hendrix Stamp ',
    email: 'support@gmail.com',
    bgimage: 'course.png',
  },
  {
    imageUrl: 'user.png',
    name: 'Mohannad Zitoun ',
    email: 'support@gmail.com',
    bgimage: 'course.png',
  },
  {
    imageUrl: 'user.png',
    name: 'John Steere ',
    email: 'support@gmail.com',
    bgimage: 'course.png',
  },
];
const liveList = [
  {
    imageUrl: 'user.png',
    name: 'Aliqa Macale ',
    email: 'support@gmail.com',
    status: 'LIVE',
    statusColor: 'bg-danger',
    bgimage: 'course.png',
  },
  {
    imageUrl: 'user.png',
    name: 'John Steere ',
    email: 'support@gmail.com',
    status: 'OFFLINE',
    statusColor: 'bg-dark',
    bgimage: 'course.png',
  },
  {
    imageUrl: 'user.png',
    name: 'Mohannad Zitoun ',
    email: 'support@gmail.com',
    status: 'LIVE',
    statusColor: 'bg-danger',
    bgimage: 'course.png',
  },
  {
    imageUrl: 'user.png',
    name: 'Aliqa Macale ',
    email: 'support@gmail.com',
    status: 'OFFLINE',
    statusColor: 'bg-dark',
    bgimage: 'course.png',
  },
  {
    imageUrl: 'user.png',
    name: 'Hendrix Stamp ',
    email: 'support@gmail.com',
    status: 'LIVE',
    statusColor: 'bg-danger',
    bgimage: 'course.png',
  },
  {
    imageUrl: 'user.png',
    name: 'Mohannad Zitoun ',
    email: 'support@gmail.com',
    status: 'LIVE',
    statusColor: 'bg-danger',
    bgimage: 'course.png',
  },
  {
    imageUrl: 'user.png',
    name: 'John Steere ',
    email: 'support@gmail.com',
    status: 'LIVE',
    statusColor: 'bg-danger',
    bgimage: 'course.png',
  },
];

const channelList = [
  {
    imageUrl: 'user.png',
    title: 'Mobile Product Design',
    des: 'Learn new secrets to creating awesome Microsoft Access databases and VBA coding not covered in any of my other courses!',
    tag1: 'FULL TIME',
    tag2: 'DESINER',
    tag3: '30 MIN',
  },
  {
    imageUrl: 'user.png',
    title: 'HTML Developer',
    des: 'Learn new secrets to creating awesome Microsoft Access databases and VBA coding not covered in any of my other courses!',
    tag1: '',
    tag2: 'DESINER',
    tag3: '30 MIN',
  },
  {
    imageUrl: 'user.png',
    title: 'Advanced CSS and Sass',
    des: 'Learn new secrets to creating awesome Microsoft Access databases and VBA coding not covered in any of my other courses!',
    tag1: 'FULL TIME',
    tag2: 'DEVELOPER',
    tag3: '21 HOUR',
  },
  {
    imageUrl: 'user.png',
    title: 'Modern React with Redux',
    des: 'Learn new secrets to creating awesome Microsoft Access databases and VBA coding not covered in any of my other courses!',
    tag1: 'HALF TIME',
    tag2: 'DESINER',
    tag3: '5 HOUNRS',
  },
  {
    imageUrl: 'user.png',
    title: 'Node JS',
    des: 'Learn new secrets to creating awesome Microsoft Access databases and VBA coding not covered in any of my other courses!',
    tag1: 'HALF TIME',
    tag2: 'CODER',
    tag3: '45 MIN',
  },
  {
    imageUrl: 'user.png',
    title: 'Mobile Product Design',
    des: 'Learn new secrets to creating awesome Microsoft Access databases and VBA coding not covered in any of my other courses!',
    tag1: 'FULL TIME',
    tag2: 'DESINER',
    tag3: '30 MIN',
  },
];
const badgeList = [
  {
    imageUrl: 'user.png',
    title: 'Bronze User',
    des: 'Learn new secrets to creating awesome Microsoft Access databases',
    tag: 'UNLOCK',
    per: '100',
  },
  {
    imageUrl: 'user.png',
    title: 'Platinum  User',
    des: 'Learn new secrets to creating awesome Microsoft Access databases',
    tag: '95 / 100',
    per: '95',
  },
  {
    imageUrl: 'user.png',
    title: 'Ultra Powered',
    des: 'Learn new secrets to creating awesome Microsoft Access databases',
    tag: '90 / 100',
    per: '90',
  },
  {
    imageUrl: 'user.png',
    title: 'Bronze User',
    des: 'Learn new secrets to creating awesome Microsoft Access databases',
    tag: '85 / 100',
    per: '85',
  },
];

const courseList = [
  {
    imageUrl: 'course.png',
    title: 'Complete Python Bootcamp From Zero to Hero in Python ',
    price: '2400',
    tag: 'Python',
    lesson: '32 ',
    status: 'alert-warning text-warning',
  },
  {
    imageUrl: 'course.png',
    title: 'Complete Python Bootcamp From Zero to Hero in Python ',
    price: '40',
    tag: 'Desinger',
    lesson: '24 ',
    status: 'alert-danger text-danger',
  },
  {
    imageUrl: 'course.png',
    title: 'Java Programming Masterclass for Developers',
    price: '60',
    tag: 'Bootstrap',
    lesson: '14 ',
    status: 'alert-success text-success',
  },
  {
    imageUrl: 'course.png',
    title: 'The Data Science Course Complete Data Science ',
    price: '370',
    tag: 'Develop',
    lesson: '23 ',
    status: 'alert-danger text-danger',
  },
  {
    imageUrl: 'course.png',
    title: 'Complete Python Bootcamp From Zero to Hero in Python ',
    price: '450',
    tag: 'Desinger',
    lesson: '24 ',
    status: 'alert-danger text-danger',
  },
  {
    imageUrl: 'course.png',
    title: 'Fundamentals for Scrum Master and Agile Projects ',
    price: '670',
    tag: 'Python',
    lesson: '32 ',
    status: 'alert-warning text-warning',
  },
  {
    imageUrl: 'course.png',
    title: 'Automate the Boring Stuff with Python Programming',
    price: '760',
    tag: 'Bootstrap',
    lesson: '14 ',
    status: 'alert-primary text-primary',
  },
  {
    imageUrl: 'course.png',
    title: 'The Data Science Course Complete Data Science ',
    price: '370',
    tag: 'Develop',
    lesson: '23 ',
    status: 'alert-danger text-danger',
  },
];

const UserProfile = () => {
  return (
    <>
      <Header />

      <div className="course-details pb-lg--7 pt-4 pb-5 single-user-profile">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="middle-sidebar-left">
                <div className="card d-block w-100 border-0 rounded-lg overflow-hidden mb-3">
                  <div
                    className="card-body position-relative h200 bg-gradiant-bottom bg-image-cover bg-image-center"
                    style={{
                      backgroundImage: `url("https://via.placeholder.com/1200x500.png")`,
                    }}
                  ></div>
                  <div className="card-body d-block w-100 pl-lg-5 pl-4 pr-4 user-profile-color">
                    <figure className="avatar ml-0 mb-0 mt--6 position-relative w90 z-index-1">
                      <img
                        src="assets/images/user.png"
                        alt="avater"
                        className="float-right p-1 bg-white rounded-circle w-100"
                      />
                    </figure>
                    <span className='float-right'><img src={pencil} width="15px" className='ml-3' /></span>
                    <span className='float-right'><img src={trophy} width="15px" /></span>
                    <div className="clearfix"></div>
                    <div className="row">
                      <div className="col-xl-6 md-mb-2">
                        <h4 className="mt-3 mb-1 text-white">
                          Aliqa Macale
                          <img src={hat} width="25px" className='ml-2' />
                        </h4>
                        <span className="font-xssss d-inline-block ml-0 text-success" >
                          3rd Year
                        </span>

                      </div>

                      <div className="col-11">

                        <p className="font-xssss lh-28 text-grey-500 mb-0 pl-0 mt-4">
                          I have a Business Management degree from Bangalore
                          University and a long time Excel expert. I create
                          professional Excel reports/dashboards for clients
                          and conduct Excel workshops for business
                          professionals. Currently am a freelance motion
                          graphics artist and a Music producer. I like to be
                          productive and creative at work. I like to
                          continuously increase my skills and stay in tuned
                          with the technology industry.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <Tabs
                  defaultActiveKey="publications"
                  id="uncontrolled-tab-example"
                  className="mb-3 mt-5 ml-3 mr-3  justify-content-between border-bottom-0 rounded-lg"
                  style={{ backgroundColor: "#E84545" }}
                >
                  <Tab eventKey="publications" title="Publications">
                    {/* <div className="card d-block w-100 border-0 shadow-xss rounded-lg overflow-hidden p-lg-4 p-2">
                          <div className="card-body mb-lg-3 pb-0">
                            <h2 className="fw-400 font-lg d-block">
                              My <b>Courses</b>
                              <a
                                href="/default-user-profile"
                                className="float-right"
                              >
                                <i className="feather-edit text-grey-500 font-xs"></i>
                              </a>
                            </h2>
                          </div>
                          <div className="card-body pb-0">
                            <div className="row">
                              {courseList.map((value, index) => (
                                // Strat Single Demo
                                <div
                                  className="col-xl-4 col-lg-6 col-md-6 col-sm-6 mb-4"
                                  key={index}
                                >
                                  <div className="card w-100 p-0 shadow-xss border-0 rounded-lg overflow-hidden mr-1 course-card">
                                    <div className="card-image w-100 mb-3">
                                      <Link
                                        to="/course-details"
                                        className="video-bttn position-relative d-block"
                                      >
                                        <img
                                          src={`assets/images/${value.imageUrl}`}
                                          alt="course"
                                          className="w-100"
                                        />
                                      </Link>
                                    </div>
                                    <div className="card-body pt-0">
                                      <span
                                        className={`font-xsssss fw-700 pl-3 pr-3 lh-32 text-uppercase rounded-lg ls-2 d-inline-block mr-1 ${value.status}`}
                                      >
                                        {value.tag}
                                      </span>
                                      <span className="font-xss fw-700 pl-3 pr-3 ls-2 lh-32 d-inline-block text-success float-right">
                                        <span className="font-xsssss">$</span>
                                        {value.price}
                                      </span>
                                      <h4 className="fw-700 font-xss mt-3 lh-28 mt-0">
                                        <Link
                                          to="/course-details"
                                          className="text-dark text-grey-900"
                                        >
                                          {value.title}
                                        </Link>
                                      </h4>
                                      <h6 className="font-xssss text-grey-500 fw-600 ml-0 mt-2">
                                        {value.lesson} Lesson
                                      </h6>
                                      <ul className="memberlist mt-3 mb-2 ml-0 d-block">
                                        <li>
                                          <a href="/">
                                            <img
                                              src="assets/images/user.png"
                                              alt="avater"
                                              className="w30 d-inline-block"
                                            />
                                          </a>
                                        </li>
                                        <li>
                                          <a href="/">
                                            <img
                                              src="assets/images/user.png"
                                              alt="avater"
                                              className="w30 d-inline-block"
                                            />
                                          </a>
                                        </li>
                                        <li>
                                          <a href="/">
                                            <img
                                              src="assets/images/user.png"
                                              alt="avater"
                                              className="w30 d-inline-block"
                                            />
                                          </a>
                                        </li>
                                        <li>
                                          <a href="/">
                                            <img
                                              src="assets/images/user.png"
                                              alt="avater"
                                              className="w30 d-inline-block"
                                            />
                                          </a>
                                        </li>
                                        <li className="last-member">
                                          <a
                                            href="/"
                                            className="bg-greylight fw-600 text-grey-500 font-xssss ls-3 text-center"
                                          >
                                            +2
                                          </a>
                                        </li>
                                        <li className="pl-4 w-auto">
                                          <a
                                            href="/"
                                            className="fw-500 text-grey-500 font-xssss"
                                          >
                                            Student apply
                                          </a>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
  
                                // End Single Demo
                              ))}
                            </div>
                          </div>
                        </div> */}
                    <Social />
                  </Tab>
                  <Tab eventKey="trophies" title="Trophies">
                    <div className=" d-block w-100 border-0 rounded-lg overflow-hidden ">
                      <div className="card-body mb-lg-3 pb-0">
                        <h2 className="fw-400 font-lg d-block text-white">
                          Achievement Trophies
                          <a
                            href="/default-user-profile"
                            className="float-right"
                          >
                          </a>
                        </h2>
                      </div>
                      <div className="card-body pb-0">
                        <div className="row">
                          {badgeList.map((value, index) => (
                            // Strat Single Demo
                            <div
                              className="col-xl-3 col-lg-3 col-md-3"
                              key={index}
                            >
                              <div className=" mb-4 d-block w-100  rounded-lg p-xxl-5 p-4 text-center" style={{ backgroundColor: "#000000", border: "1px solid white" }}>

                                <a
                                  href="/default-user-profile"
                                  className="btn-round-xxxl rounded-lg ml-auto mr-auto"
                                >
                                  <img
                                    src={`assets/images/${value.imageUrl}`}
                                    alt="badge"
                                    className="w-100"
                                  />
                                </a>
                                <h4 className="fw-700 font-xsss mt-4">
                                  {value.title}
                                </h4>
                                <p className="fw-500 font-xssss text-grey-500 mt-3">
                                  {value.des}
                                </p>

                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </Tab>
                  <Tab eventKey="followers" title="Followers">
                    <Input className='search-follower-inputField d-flex justify-content-center' placeholder='Search' />
                    <div className='mt-5 social-search'>
                      <ul className='social-search-list '>
                        <li> <img src={dummy} alt="img" className='m-2 mt-3' style={{ width: "50px", height: "50px" }} />Amelia Jeannet<p>3rd y MS, University of Geneva</p><button className='float-right follow'>Follow Back</button><button className='float-right remove'>Remove</button></li>
                        <li> <img src={dummy} alt="img" className='m-2 mt-3' style={{ width: "50px", height: "50px" }} />Amelia Jeannet<p>3rd y MS, University of Geneva</p><button className='float-right follow'>Follow Back</button><button className='float-right remove'>Remove</button></li>
                        <li> <img src={dummy} alt="img" className='m-2 mt-3' style={{ width: "50px", height: "50px" }} />Amelia Jeannet<p>3rd y MS, University of Geneva</p><button className='float-right follow'>Follow Back</button><button className='float-right remove'>Remove</button></li>
                        <li> <img src={dummy} alt="img" className='m-2 mt-3' style={{ width: "50px", height: "50px" }} />Amelia Jeannet<p>3rd y MS, University of Geneva</p><button className='float-right follow'>Follow Back</button><button className='float-right remove'>Remove</button></li>
                        <li> <img src={dummy} alt="img" className='m-2 mt-3' style={{ width: "50px", height: "50px" }} />Amelia Jeannet<p>3rd y MS, University of Geneva</p><button className='float-right follow'>Follow Back</button><button className='float-right remove'>Remove</button></li>
                        <li> <img src={dummy} alt="img" className='m-2 mt-3' style={{ width: "50px", height: "50px" }} />Amelia Jeannet<p>3rd y MS, University of Geneva</p><button className='float-right follow'>Follow Back</button><button className='float-right remove'>Remove</button></li>
                        <li> <img src={dummy} alt="img" className='m-2 mt-3' style={{ width: "50px", height: "50px" }} />Amelia Jeannet<p>3rd y MS, University of Geneva</p><button className='float-right follow'>Follow Back</button><button className='float-right remove'>Remove</button></li>
                        <li> <img src={dummy} alt="img" className='m-2 mt-3' style={{ width: "50px", height: "50px" }} />Amelia Jeannet<p>3rd y MS, University of Geneva</p><button className='float-right follow'>Follow Back</button><button className='float-right remove'>Remove</button></li>
                        <li> <img src={dummy} alt="img" className='m-2 mt-3' style={{ width: "50px", height: "50px" }} />Amelia Jeannet<p>3rd y MS, University of Geneva</p><button className='float-right follow'>Follow Back</button><button className='float-right remove'>Remove</button></li>
                        <li> <img src={dummy} alt="img" className='m-2 mt-3' style={{ width: "50px", height: "50px" }} />Amelia Jeannet<p>3rd y MS, University of Geneva</p><button className='float-right follow'>Follow Back</button><button className='float-right remove'>Remove</button></li>
                        <li> <img src={dummy} alt="img" className='m-2 mt-3' style={{ width: "50px", height: "50px" }} />Amelia Jeannet<p>3rd y MS, University of Geneva</p><button className='float-right follow'>Follow Back</button><button className='float-right remove'>Remove</button></li>
                        <li> <img src={dummy} alt="img" className='m-2 mt-3' style={{ width: "50px", height: "50px" }} />Amelia Jeannet<p>3rd y MS, University of Geneva</p><button className='float-right follow'>Follow Back</button><button className='float-right remove'>Remove</button></li>

                      </ul>
                    </div>
                  </Tab>
                  <Tab eventKey="following" title="Following">
                    <Input className='search-follower-inputField d-flex justify-content-center' placeholder='Search' />
                    <div className='mt-5 social-search'>
                      <ul className='social-search-list '>
                        <li> <img src={dummy} alt="img" className='m-2 mt-3' style={{ width: "50px", height: "50px" }} />Amelia Jeannet<p>3rd y MS, University of Geneva</p><button className='float-right remove'>Unfollow</button></li>
                        <li> <img src={dummy} alt="img" className='m-2 mt-3' style={{ width: "50px", height: "50px" }} />Amelia Jeannet<p>3rd y MS, University of Geneva</p><button className='float-right remove'>Unfollow</button></li>
                        <li> <img src={dummy} alt="img" className='m-2 mt-3' style={{ width: "50px", height: "50px" }} />Amelia Jeannet<p>3rd y MS, University of Geneva</p><button className='float-right remove'>Unfollow</button></li>
                        <li> <img src={dummy} alt="img" className='m-2 mt-3' style={{ width: "50px", height: "50px" }} />Amelia Jeannet<p>3rd y MS, University of Geneva</p><button className='float-right remove'>Unfollow</button></li>
                        <li> <img src={dummy} alt="img" className='m-2 mt-3' style={{ width: "50px", height: "50px" }} />Amelia Jeannet<p>3rd y MS, University of Geneva</p><button className='float-right remove'>Unfollow</button></li>
                        <li> <img src={dummy} alt="img" className='m-2 mt-3' style={{ width: "50px", height: "50px" }} />Amelia Jeannet<p>3rd y MS, University of Geneva</p><button className='float-right remove'>Unfollow</button></li>
                        <li> <img src={dummy} alt="img" className='m-2 mt-3' style={{ width: "50px", height: "50px" }} />Amelia Jeannet<p>3rd y MS, University of Geneva</p><button className='float-right remove'>Unfollow</button></li>
                        <li> <img src={dummy} alt="img" className='m-2 mt-3' style={{ width: "50px", height: "50px" }} />Amelia Jeannet<p>3rd y MS, University of Geneva</p><button className='float-right remove'>Unfollow</button></li>
                        <li> <img src={dummy} alt="img" className='m-2 mt-3' style={{ width: "50px", height: "50px" }} />Amelia Jeannet<p>3rd y MS, University of Geneva</p><button className='float-right remove'>Unfollow</button></li>
                        <li> <img src={dummy} alt="img" className='m-2 mt-3' style={{ width: "50px", height: "50px" }} />Amelia Jeannet<p>3rd y MS, University of Geneva</p><button className='float-right remove'>Unfollow</button></li>
                        <li> <img src={dummy} alt="img" className='m-2 mt-3' style={{ width: "50px", height: "50px" }} />Amelia Jeannet<p>3rd y MS, University of Geneva</p><button className='float-right remove'>Unfollow</button></li>
                        <li> <img src={dummy} alt="img" className='m-2 mt-3' style={{ width: "50px", height: "50px" }} />Amelia Jeannet<p>3rd y MS, University of Geneva</p><button className='float-right remove'>Unfollow</button></li>

                      </ul>
                    </div>
                  </Tab>
                  <Tab eventKey="bookmarked" title="Bookmarked">
                    <Social />
                  </Tab>

                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  );
}

export default UserProfile