import React, { useState } from 'react'
import { FormGroup, Label, Row, Input, Col } from 'reactstrap'
import cardImg from "../../../assets/images/demo/id.jpg"
import { IoMdAddCircle } from "react-icons/io"
import Switch from "react-switch";


const Security = () => {
    const [premium, setPremium] = useState(false);
    const handlePremium = nextChecked => {
        setPremium(nextChecked);
    };
    return (
        <>
            <div >
                <label>Active Status </label>
                <Switch

                    onChange={handlePremium}
                    checked={premium}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    onColor="#72BB53"
                    height={15}
                    width={30}
                    className="mt-3 ml-3 mr-3"
                />

            </div>

            <div className='quizs security'>
                <Row >
                    <Col md="11" sm="6">
                        <FormGroup>
                            <Label>First Name </Label>
                            <Input className='setting-inputField' type='text' placeholder='First Name ' />
                        </FormGroup>
                        <FormGroup>
                            <Label>Last Name </Label>
                            <Input className='setting-inputField' type='text' placeholder='Last Name' />
                        </FormGroup>
                        <FormGroup>
                            <Label>Email </Label>
                            <Input className='setting-inputField' type='email' placeholder='Last Name' />
                        </FormGroup>
                        <FormGroup>
                            <Label>Country </Label>
                            <Input className='setting-inputField' type='text' placeholder='Country' />
                        </FormGroup>
                        <FormGroup>
                            <Label>Language </Label>
                            <Input className='setting-inputField' type='text' placeholder='Language' />
                        </FormGroup>
                        <FormGroup>
                            <Label>University </Label>
                            <Input className='setting-inputField' type='text' placeholder='University' />
                        </FormGroup>
                        <p className='float-right'><IoMdAddCircle fontSize="1rem" className="ml-5" />Add New</p>
                        <img src={cardImg} className="setting-inputField" width="700px" />
                        <FormGroup>
                            <Label>University ID </Label>
                            <Input className='setting-inputField' type='text' placeholder='University ID' />
                        </FormGroup>

                        <FormGroup>
                            <Label>Speciality Of Interest Year </Label>
                            <Input className='setting-inputField' type='text' placeholder='Speciality Of Interest' />
                        </FormGroup>
                        <FormGroup>
                            <Label>Speciality Of Interest</Label>
                            <Input className='setting-inputField' type='text' placeholder='Speciality Of Interest' />
                        </FormGroup>


                    </Col>
                </Row>
            </div>
        </>
    )
}

export default Security